import React from "react";
import { useTranslation } from "react-i18next";

// reactstrap components
import { NavItem, NavLink, Nav, Progress } from "reactstrap";

function WizardHead(props) {
  const { t } = useTranslation();
  const { step, setStep } = props;
  return (
    <>
      <Nav
        className=" nav-fill"
        id="tabs-text"
        pills
        role="tablist"
      >
        <NavItem>
          <NavLink
            aria-controls="tabs-text-1"
            aria-selected={step === 1}
            className={"mb-sm-3 mb-md-0 " + (step === 1 ? "active" : "")}
            data-toggle="tab"
            href="#products"
            id="tabs-text-1-tab"
            role="tab"
            onClick={(e) => step > 1 && setStep(1)}
          >
            <span className="d-none d-md-block">{t("products.products")}</span>
            <span className="d-block d-md-none"><i className="fas fa-boxes"></i></span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            aria-controls="tabs-text-2"
            aria-selected={step === 2}
            className={"mb-sm-3 mb-md-0 " + (step === 2 ? "active" : "")}
            data-toggle="tab"
            href="#shipping"
            id="tabs-text-2-tab"
            role="tab"
            onClick={(e) => step > 2 && setStep(2)}
          >
            <span className="d-none d-md-block">{t("cart.deliveryAndOrder")}</span>
            <span className="d-block d-md-none"><i className="fas fa-truck"></i></span>
            
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            aria-controls="tabs-text-3"
            aria-selected={step === 3}
            className={"mb-sm-3 mb-md-0 " + (step === 3 ? "active" : "")}
            data-toggle="tab"
            href="#payment"
            id="tabs-text-3-tab"
            role="tab"
            onClick={(e) => step > 3 && setStep(3)}
          >
            <span className="d-none d-md-block">{t("order.payment.payment")}</span>
            <span className="d-block d-md-none"><i className="fas fa-credit-card"></i></span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            aria-controls="tabs-text-3"
            aria-selected={step === 4}
            className={"mb-sm-3 mb-md-0 " + (step === 4 ? "active" : "")}
            data-toggle="tab"
            href="#summary"
            id="tabs-text-3-tab"
            role="tab"
          >
            <span className="d-none d-md-block">{t("common.summary")}</span>
            <span className="d-block d-md-none"><i className="fas fa-check"></i></span>
          </NavLink>
        </NavItem>
      </Nav>
      <Progress
        max="5"
        value={step}
        color="primary"
        className="mt-3"
      ></Progress>
    </>
  );
}

export default WizardHead;
