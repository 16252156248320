import React, { useState, useEffect } from "react";

import { Row, Col, Card, CardBody } from "reactstrap";

import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";

import SelectCollectPoint from "./SelectCollectPoint";
import apiDriver from "stores/api.driver";
import config from "config/global";
import { useTranslation } from "react-i18next";

function ShippingMethod(props) {
  const { i18n } = useTranslation();
  const { method, selectedMethod, setMethod, selectedPoint, setPoint } = props;
  const [points, setPoints] = useState(null);

  const price =
    method.costs && method.costs.length > 0 ? method.costs[0].cost : 0.0;

  useEffect(() => {
    if (
      (selectedMethod && selectedMethod.id !== method.id) ||
      method.isShippingAddressRequired ||
      points !== null
    ) {
      return;
    }

    const attributes = apiDriver.buildIndexAttributes({
      take: 1000,
      filters: {
        methodId: {
          filterVal: method.id,
          filterType: "Guid",
          comparator: "=",
          caseSensitive: false,
        },
      },
    });

    apiDriver
      .get(
        `${config.api.orders}${i18n.resolvedLanguage}/CollectPoints/${attributes}`,
      )
      .pipe(
        map((response) => {
          setPoints(response.response);
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe(() => {});
  }, [selectedMethod]);

  useEffect(() => {
    if (
      (selectedMethod && selectedMethod.id !== method.id) ||
      points === null
    ) {
      return;
    }
    if (!selectedPoint || !points.some((p) => p.id === selectedPoint.id)) {
      setPoint(points[0]);
    }
  }, [selectedMethod, points]);

  const currentTranslation = method.translations[0];

  const active = selectedMethod && selectedMethod.id === method.id;
  const onClick = () => setMethod(method);

  return (
    <Card
      data-shipping-method={method.id}
      className={
        active ? "border border-primary text-primary my-2" : "border my-2"
      }
      style={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <CardBody className="px-3 py-2">
        <Row>
          <Col md={3} className="text-center pt-2">
            <label htmlFor={`shippingMethod${method.id}`}>
              {currentTranslation?.image ? (
                <img
                  src={currentTranslation?.image}
                  className="img img-fluid"
                  style={{ maxHeight: "32px" }}
                  alt={currentTranslation?.title || method.id}
                />
              ) : method.isShippingAddressRequired ? (
                <i className="fas fa-truck fa-4x"></i>
              ) : (
                <i className="fas fa-people-carry fa-4x"></i>
              )}
            </label>
          </Col>
          <Col md={6}>
            <label htmlFor={`shippingMethod${method.id}`}>
              <b>{currentTranslation?.title}</b>
              <br />
              {currentTranslation?.description}
            </label>
          </Col>
          <Col md={3} className="text-center pt-3 d-none" style={{ fontSize: "150%" }}>
            {Intl.NumberFormat(i18n.resolvedLanguage, {
              style: "currency",
              currency: "PLN"
            }).format(price)}
          </Col>
          {selectedMethod &&
          selectedMethod.id === method.id &&
          points !== null ? (
            <React.Fragment>
              <Col md="3"></Col>
              <SelectCollectPoint
                points={points}
                selectedPoint={selectedPoint}
                setPoint={setPoint}
              />
            </React.Fragment>
          ) : (
            <React.Fragment />
          )}
        </Row>
      </CardBody>
    </Card>
  );
}

export default ShippingMethod;
