import config from "config/global";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  CardTitle,
} from "reactstrap";
import CultureFlag from "./CultureFlag";

function LocaleRow(props) {
  const { culture, onChange } = props;
  const { i18n } = useTranslation();

  return (
    <div
      style={{ cursor: "pointer" }}
      onClick={() => onChange(culture.code)}
      className={`p-3 ${i18n.resolvedLanguage === culture.code ? "bg-primary text-white" : ""
        }`}
    >
      <CultureFlag code={culture.code} />
      {culture.native} &nbsp; ({culture.english})
    </div>
  );
}

export default function LanguageSelector(props) {
  const { isOpen, toggle } = props;
  const { t, i18n } = useTranslation();

  const onChange = (code) => {
    const oldCode = i18n.resolvedLanguage;
    i18n.changeLanguage(code);
    if (window.location.pathname.startsWith(`/${oldCode}/`)) {
      const newPathName = `/${code}/` + window.location.pathname.substring(4);
      //window.location.href = newPathName;
      window.history.replaceState(null, "", newPathName);
    }
  }

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader>
          <CardTitle>{t("locale.change")}</CardTitle>
        </ModalHeader>
        <ModalBody>
          {config.cultures.map((culture) => (
            <LocaleRow
              key={culture.code}
              culture={culture}
              onChange={onChange}
            />
          ))}
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle} color="primary" type="button">
            {t("actions.select")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
