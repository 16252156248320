import RealizationTerm from 'components/Cart/RealizationTerm';
import React from 'react';
import { useTranslation } from "react-i18next";
import { Row, Col, Spinner } from "reactstrap";

export default function OrderValue(props) {
  const { value, products } = props;
  const { t, i18n } = useTranslation();

  if (!value || !products) {
    return <Spinner />
  }

  return (
    <>
      {value.rebate?.netCost !== 0 ?
        <>
          <Row>
            <Col sm="6" className="text-muted">
              {t("order.product.price.net")}
            </Col>
            <Col sm="6">
              {new Intl.NumberFormat(i18n.resolvedLanguage, {
                style: "currency",
                currency: "PLN",
              }).format(value.products?.netCost + value.rebate?.netCost)}
            </Col>
          </Row>
          <Row>
            <Col sm="6" className="text-muted">
              {t('price.rebates')}
            </Col>
            <Col sm="6" className="text-success">
              {new Intl.NumberFormat(i18n.resolvedLanguage, {
                style: "currency",
                currency: "PLN",
              }).format(value.rebate?.netCost * -1)}
            </Col>
          </Row>
        </> :
        <>
          <Row>
            <Col sm="6" className="text-muted">
              {t("order.product.price.net")}
            </Col>
            <Col sm="6">
              {new Intl.NumberFormat(i18n.resolvedLanguage, {
                style: "currency",
                currency: "PLN",
              }).format(value.products?.netCost)}
            </Col>
          </Row>
        </>
      }
      {value.shippingCost?.grossCost !== 0 &&
        <Row>
          <Col sm="6" className="text-muted">
            {t("order.shipping.price.net")}
          </Col>
          <Col sm="6">
            {new Intl.NumberFormat(i18n.resolvedLanguage, {
              style: "currency",
              currency: "PLN",
            }).format(value.shippingCost?.netCost)}
          </Col>
        </Row>}
      {value.paymentProvision?.netCost !== 0 &&
        <Row>
          <Col sm="6" className="text-muted">
            {t("order.payment.provision.net")}
          </Col>
          <Col sm="6">
            {new Intl.NumberFormat(i18n.resolvedLanguage, {
              style: "currency",
              currency: "PLN",
            }).format(value.paymentProvision?.netCost)}
          </Col>
        </Row>}
      {value.discount?.netCost !== 0 &&
        <Row>
          <Col sm="6" className="text-muted">
            {t("order.coupon")}
          </Col>
          <Col sm="6" className="text-success">
            {new Intl.NumberFormat(i18n.resolvedLanguage, {
              style: "currency",
              currency: "PLN",
            }).format(value.discount?.netCost * -1)}
          </Col>
        </Row>}
      <Row>
        <Col sm="6" className="text-muted">
          {t("price.sum.net")}
        </Col>
        <Col sm="6">
          {new Intl.NumberFormat(i18n.resolvedLanguage, {
            style: "currency",
            currency: "PLN",
          }).format(value.total?.netCost)}
        </Col>
      </Row>
      <Row>
        <Col sm="6" className="text-muted">
          {t('price.tax')}
        </Col>
        <Col sm="6" className="text-muted">
          {new Intl.NumberFormat(i18n.resolvedLanguage, {
            style: "currency",
            currency: "PLN",
          }).format(value.total?.taxCost)}
        </Col>
      </Row>
      <Row>
        <Col sm="6" className="text-muted">
          {t("price.sum.gross")}
        </Col>
        <Col sm="6">
          {new Intl.NumberFormat(i18n.resolvedLanguage, {
            style: "currency",
            currency: "PLN",
          }).format(value.total?.grossCost)}
        </Col>
      </Row>
      <Row>
        <Col sm="6" className="text-muted">
          {t("products.fields.realizationTerm")}
        </Col>
        <Col sm="6">
          <RealizationTerm term={products.map((p) => p.realizationTerm).sort()[0]} />
        </Col>
      </Row>
    </>
  )
}