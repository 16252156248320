import React, { StrictMode, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "stores/store";
import "./i18n.js";

// eslint-disable-next-line no-unused-vars
import epic$ from "stores/authorized-epics";

import "assets/css/nucleo-svg.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/printweb.scss?v1.0.0";

import Products from "views/Products/Products.js";
import Product from "views/Product/Product.js";
import Cart from "views/Cart/Cart";
import Login from "views/Auth/Login";

import Footer from "components/footers/Footer";
import Register from "views/Auth/Register";
import ResetPassword from "views/Auth/ResetPassword";

import Panel from "views/Panel/Panel";
import Contact from "views/Contact/Contact";
import PartnerProgram from "views/Contact/Partner";
import CustomCalculation from "views/Contact/CustomCalculation";
import MainNavbar from "components/navbars/Navbar";
import AuthorizeGate from "views/AuthorizeGate";
import OrderPage from "views/Orders/OrderPage";
import Page from "views/Page/Page";
import { ErrorBoundary } from "react-error-boundary";
import Fallback from "views/Fallback/Fallback.js";
import PaymentGate from "views/PaymentGate.js";
import InvoiceGate from "views/InvoiceGate.js";
import { Container } from "reactstrap";
import Error404 from "views/Fallback/Error404.js";

import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import CookiesConfiguration from "cookieconsent-config.js";

export default function App() {

  useEffect(() => {
    CookieConsent.run(CookiesConfiguration);
  }, []);

  return (
    <>
      <StrictMode>
        <Provider store={store}>
          <BrowserRouter>
            <ErrorBoundary FallbackComponent={Fallback}>
              <MainNavbar />
              <Container fluid>
                <Switch>
                  <Route
                    path="/:locale/authorize/:token"
                    exact
                    render={(props) => <AuthorizeGate {...props} />}
                  />
                  <Route
                    path="/:locale/invoices/:id/:buyerDataId?"
                    exact
                    render={(props) => <InvoiceGate {...props} />}
                  />
                  <Route
                    path="/:locale/payment/:id/:method?"
                    exact
                    render={(props) => <PaymentGate {...props} />}
                  />
                  <Route
                    path="/:locale/contact"
                    exact
                    render={(props) => <Contact {...props} />}
                  />
                  <Route
                    path="/:locale/contact/partner-program"
                    exact
                    render={(props) => <PartnerProgram {...props} />}
                  />
                  <Route
                    path="/:locale/contact/custom-calculation"
                    exact
                    render={(props) => <CustomCalculation {...props} />}
                  />
                  <Route path="/:locale/panel" exact render={(props) => <Panel {...props} />} />
                  <Route
                    path="/:locale/orders/:id"
                    exact
                    render={(props) => <OrderPage {...props} />}
                  />
                  <Route path="/:locale/login" exact render={(props) => <Login {...props} />} />
                  <Route
                    path="/:locale/register"
                    exact
                    render={(props) => <Register {...props} />}
                  />
                  <Route
                    path="/:locale/reset/:email?/:token?"
                    exact
                    render={(props) => <ResetPassword {...props} />}
                  />
                  <Route
                    path="/:locale/cart"
                    exact
                    render={(props) => <Cart {...props} />}
                  />
                  <Route
                    path="/:locale/products"
                    exact
                    render={(props) => <Products {...props} />}
                  />
                  <Route path="/:locale/products/:id" component={Product} />
                  <Route
                    path="/:locale/products"
                    exact
                    render={(props) => <Products {...props} />}
                  />
                  <Route path="/:locale/p/:url" component={Page} />
                  <Route
                    path="/:locale/"
                    exact
                    render={(props) => <Products {...props} />}
                  />
                  <Route
                    path="/"
                    exact
                    render={(props) => <Products {...props} />}
                  />
                  <Route
                    path="/404"
                    exact
                    render={(props) => <Error404 {...props} />}
                  />
                  <Redirect to="/404" />
                </Switch>
              </Container>
              <Footer />
            </ErrorBoundary>
          </BrowserRouter>
        </Provider>
      </StrictMode>
    </>
  )
}

ReactDOM.render(<App />, document.getElementById("root"));
