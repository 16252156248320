import Country from "components/AddressForm/Country";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function BillingInfoModal(props) {
  const { t } = useTranslation();
  const { billingInfo } = props;
  const [modalOpen, setModalOpen] = useState(false);

  if (!billingInfo) {
    return <React.Fragment />;
  }

  return (
    <>
      <Button
        type="button"
        color="light"
        size="sm"
        className="mb-2"
        onClick={() => setModalOpen(!modalOpen)}
      >
        <i className="fas fa-address-book"></i>{" "}
        {t("customers.billingInfo.billingInfo")}
      </Button>
      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
          {t("customers.billingInfo.billingInfo")}
        </ModalHeader>
        <ModalBody>
          {billingInfo.buyerName ? (
            <div>
              <b>{billingInfo.buyerName}</b>
            </div>
          ) : (
            <React.Fragment />
          )}
          {billingInfo.vatID ? (
            <div>NIP: {billingInfo.vatID}</div>
          ) : (
            <React.Fragment />
          )}
          <div>{billingInfo.line1}</div>
          {billingInfo.line2 ? (
            <div>{billingInfo.line2}</div>
          ) : (
            <React.Fragment />
          )}
          <div>
            {billingInfo.postalCode} {billingInfo.city}
          </div>
          <div><Country countryCode={billingInfo.countryCode} /></div>
          {billingInfo.email ? (
            <div>
              <i className="fas fa-envelope"></i> &nbsp; {billingInfo.email}
            </div>
          ) : (
            <React.Fragment />
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            className="ml-auto"
            color="link"
            onClick={() => setModalOpen(!modalOpen)}
            type="button"
          >
            {t("actions.close")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default BillingInfoModal;
