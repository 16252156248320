import React, { useState } from "react";

import { Tooltip, Row, Input } from "reactstrap";
import Additive from "./Additive";

function AdditiveGroupForm(props) {
  const { additiveGroup } = props;

  switch (additiveGroup?.inputType) {
    case "Buttons":
    case "Checkbox":
      return <AdditiveGroupButtons {...props} />;
    case "Select":
      return <AdditiveGroupSelect {...props} />;
    default:
      return <span>{additiveGroup?.inputType || "not defined"}</span>;
  }
}

function AdditiveGroupButtons(props) {
  const { additiveGroup, groupAdditives, currentAdditives, onChange } = props;
  return (
    <Row className="px-2">
      {groupAdditives?.map((additive) => (
        <Additive
          key={additive.id}
          additiveGroup={additiveGroup}
          additive={additive}
          currentAdditives={currentAdditives}
          onChange={onChange}
        />
      ))}
    </Row>
  );
}

function AdditiveGroupSelect(props) {
  const { additiveGroup, groupAdditives, onChange } = props;

  return (
    <Input type="select" name={additiveGroup.id} onChange={onChange}>
      {groupAdditives?.map((additive) => (
        <option key={additive.id} value={additive.id}>
          {additive.currentTranslation?.title}{" "}
          {additive.currentTranslation?.description
            ? ` - ${additive.currentTranslation?.description}`
            : ""}
        </option>
      ))}
    </Input>
  );
}

function AdditiveGroup(props) {
  const {
    additiveGroup,
    additives,
    productAdditives,
    onChange,
    conflictedAdditives,
  } = props;

  const [isTooltipOpen, setTooltipOpen] = useState();
  const toggleTooltip = () => setTooltipOpen(!isTooltipOpen);

  const groupAdditives = additiveGroup.additives
    .map((additive) => ({
      additive: additive,
      order:
        productAdditives.find((pa) => pa.additiveId === additive.id).order || 0,
    }))
    .sort((a, b) => b.order - a.order)
    .map((additive) => additive.additive)
    .filter((additive) => !conflictedAdditives.includes(additive.id));

  return (
    <div key={additiveGroup.id} className="mb-3">
      <h4 className="text-uppercase">
        {additiveGroup.currentTranslation?.title}
        {additiveGroup.currentTranslation?.hint ? (
          <>
            <small
              style={{ fontSize: "50%", position: "relative", top: "-3px" }}
            >
              <i
                className="text-primary fas fa-question-circle ml-1"
                id={`tooltipAttribute${additiveGroup.id}`}
              ></i>
            </small>
            <Tooltip
              placement="right"
              isOpen={isTooltipOpen}
              target={`tooltipAttribute${additiveGroup.id}`}
              toggle={toggleTooltip}
            >
              {additiveGroup.currentTranslation?.hint}
            </Tooltip>
          </>
        ) : (
          <React.Fragment />
        )}
      </h4>
      <AdditiveGroupForm
        additiveGroup={additiveGroup}
        groupAdditives={groupAdditives}
        currentAdditives={additives}
        onChange={onChange}
      />
    </div>
  );
}

export default AdditiveGroup;
