import React from "react";
import Helmet from "react-helmet";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Badge,
} from "reactstrap";
import PageEmbed from "components/CMS/PageEmbed";
import { useTranslation } from "react-i18next";
import ContactForm from "./ContactForm";

function CustomCalculation() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>Wycena niestandardowa - Printweb.pl</title>
      </Helmet>
      <div className="bg-dark py-5">
        <Container>
          <Row className="my-5">
            <Col md="5">
              <PageEmbed id="ffacdbac-0f42-420f-bc65-4f45753ce140" content />
            </Col>
            <Col className="m-auto my-5 py-5" md="12">
              <Card className="card-contact card-raised">
                <Row>
                  <Col className="pr-md-0" lg="8" md="7">
                    <CardHeader>
                      <CardTitle tag="h4">
                        Zapytaj się o wycenę niestandardowego zamówienia
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <ContactForm subject={t('contact.calculation')} />
                    </CardBody>
                  </Col>
                  <Col className="mr-auto text-left" lg="4" md="8">
                    <div className="pr-md-5 mt-5 text-center">
                      <div className="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle mb-5">
                        <i className="fas fa-calculator"></i>
                      </div>
                      <h3>Co warto opisać?</h3>
                      <p>
                        Pamiętaj o szczegółowej specyfikacji wycenianego
                        produktu / usługi.
                      </p>
                      <ul className="list-unstyled">
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <Badge
                                className="badge-circle mr-3"
                                color="primary"
                                style={{ opacity: 1 }}
                              >
                                <i className="fas fa-minus"></i>
                              </Badge>
                            </div>
                            <div>
                              <h6 className="mb-0">Format</h6>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <Badge
                                className="badge-circle mr-3"
                                color="primary"
                                style={{ opacity: 0.9 }}
                              >
                                <i className="fas fa-minus"></i>
                              </Badge>
                            </div>
                            <div>
                              <h6 className="mb-0">Rodzaj podłoza</h6>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <Badge
                                className="badge-circle mr-3"
                                color="primary"
                                style={{ opacity: 0.8 }}
                              >
                                <i className="fas fa-minus"></i>
                              </Badge>
                            </div>
                            <div>
                              <h6 className="mb-0">Zadruk, kolorystyka</h6>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <Badge
                                className="badge-circle mr-3"
                                color="primary"
                                style={{ opacity: 0.7 }}
                              >
                                <i className="fas fa-minus"></i>
                              </Badge>
                            </div>
                            <div>
                              <h6 className="mb-0">Krojenie</h6>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <Badge
                                className="badge-circle mr-3"
                                color="primary"
                                style={{ opacity: 0.6 }}
                              >
                                <i className="fas fa-minus"></i>
                              </Badge>
                            </div>
                            <div>
                              <h6 className="mb-0">Nakład</h6>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <Badge
                                className="badge-circle mr-3"
                                color="primary"
                                style={{ opacity: 0.5 }}
                              >
                                <i className="fas fa-minus"></i>
                              </Badge>
                            </div>
                            <div>
                              <h6 className="mb-0">Dodatki</h6>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default CustomCalculation;
