import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const LocalizedLink = (props) => {
  const { to, children, ...otherProps } = props;
  const { i18n } = useTranslation();

  if (i18n?.resolvedLanguage) {
    const newTo = `/${i18n?.resolvedLanguage}${to[0] === '/' ? to : `/${to}`}`;
    return <Link to={newTo} {...otherProps}>{children}</Link >;
  } else {
    return <Link to={to} {...otherProps}>{children}</Link>;
  }
}

export default LocalizedLink;