import React from "react";

import { Button, ButtonGroup } from "reactstrap";
import TabTable from "./TabTable";
import config from "config/global";
import { useTranslation } from "react-i18next";
import apiDriver from "stores/api.driver";

export const InvoiceTypes = [
  { id: 1 },
  { id: 2 },
  { id: 3 },
  { id: 4 },
  { id: 5 },
];

export default function TabInvoices() {
  const { i18n, t } = useTranslation();

  const download = (invoice) => {
    apiDriver
      .download(
        `${invoice.number}.pdf`,
        `${config.api.accountancy}pl/Invoices/${invoice.id}/Download`,
      )
      .subscribe(() => {});
  }

  const columns = [
    {
      dataField: "number",
      text: t("common.number"),
      sort: true,
    },
    {
      dataField: "type",
      text: t("invoices.fields.type"),
      sort: true,
      formatter: (cell) => {
        return t(`invoices.types.${cell}`) || t("common.none");
      },
    },
    {
      dataField: "amount.grossCost",
      text: t("price.amount"),
      sort: true,
      formatter: (cell, row) =>
        new Intl.NumberFormat(i18n?.resolvedLanguage, {
          style: "currency",
          currency: row.currency || "PLN",
        }).format(cell),
    },
    {
      dataField: "amountRemain.grossCost",
      text: t("price.saldo"),
      sort: true,
      formatter: (cell, row) =>
        new Intl.NumberFormat(i18n?.resolvedLanguage, {
          style: "currency",
          currency: row.currency || "PLN",
        }).format(cell),
    },
    {
      dataField: "issued",
      text: t("invoices.fields.submitted"),
      sort: true,
      formatter: (cell) => cell.substr(0, 10),
    },
    {
      dataField: "paymentTerm",
      text: t("invoices.fields.paymentTerm"),
      sort: true,
      formatter: (cell) => cell.substr(0, 10),
    },
    {
      isDummyField: true,
      dataField: "",
      text: "",
      formatter: (_cell, row) => (
        <ButtonGroup>
          <Button color="success" size="sm" title={t("actions.download")} onClick={() => download(row)}>
            <i className="fas fa-download"></i>
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  return (
    <TabTable
      id="invoices"
      url={`${config.api.accountancy}${i18n.resolvedLanguage}/Invoices/`}
      title={t("invoices.invoices")}
      columns={columns}
    />
  );
}
