import config from "config/global";
import React, { useEffect, useState } from "react";

import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import apiDriver from "stores/api.driver";
import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { useTranslation } from "react-i18next";

function SchemaDownloadMenu(props) {
  const { t } = useTranslation();
  const { type, onClickDownload } = props;

  const [isOpen, setOpen] = useState();

  return (
    <ButtonDropdown
      className="btn-block"
      toggle={() => setOpen(!isOpen)}
      isOpen={isOpen}
    >
      <DropdownToggle color="primary" caret>
        {t("actions.download")} ({type.label})
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => onClickDownload(type.key, "pdf")}>
          <i className="fas fa-file-pdf"></i> &nbsp; Portable Document Format
          (.pdf)
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={() => onClickDownload(type.key, "jpeg")}>
          <i className="fas fa-image"></i> &nbsp; Joint Photographic Experts
          Group (.jpeg)
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={() => onClickDownload(type.key, "psd")}>
          <i className="fas fa-layer-group"></i> &nbsp; Adobe Photoshop Document
          (.psd)
        </DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
}

function SchemaImage(props) {
  const { image, fileName } = props;
  return (
    <img
      key={image.key}
      src={`data:image/jpeg;base64,${image.encoded}`}
      alt={fileName + " - " + image.key}
      className="img"
      style={{ maxWidth: "100%", maxHeight: "33vh" }}
    />
  );
}

function Schema(props) {
  const { t, i18n } = useTranslation();
  const { product, size, updatePricings, getCalculationDTO } = props;

  const [images, setImages] = useState([]);

  const imageTypes = [
    { key: "default", label: t("products.mockup.default") },
    { key: "bookcover", label: t("products.mockup.bookcover") },
  ];
  const fileName = `${product.translations[0]?.title} - ${size.x}x${size.y}`;

  const setImage = (key, encoded) => {
    let imgs = [...images];
    if (imgs.some((i) => i.key === key)) {
      imgs = imgs.map((i) =>
        i.key !== key ? i : { ...i, encoded: encoded, fetched: true },
      );
    } else {
      imgs.push({ key: key, encoded: encoded, fetched: true });
    }
    setImages(imgs);
  };

  const fetchImage = (type) => {
    apiDriver
      .post(
        `${config.api.products}${i18n.resolvedLanguage}/Products/${product.id}/Mockup/${type}/base64/jpeg`,
        getCalculationDTO(),
      )
      .pipe(
        map((response) => {
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((response) => onFetchImage(type, response));
  };

  const onClickDownload = (type, format) => {
    apiDriver
      .post(
        `${config.api.products}${i18n.resolvedLanguage}/Products/${product.id}/Mockup/${type}/file/${format}`,
        getCalculationDTO(),
        {},
        "blob",
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((response) => onFetchDownload(type, response, format));
  };

  const onFetchDownload = (type, response, format) => {
    const fileUrl = window.URL.createObjectURL(response.response);
    let tempLink = document.createElement("a");
    tempLink.href = fileUrl;
    tempLink.setAttribute("download", `${fileName} - ${type}.${format}`);
    tempLink.click();
  };

  const onFetchImage = (type, data) => {
    if (typeof data !== "string") {
      setImage(type, null);
      return;
    }
    setImage(type, data);
  };

  useEffect(() => {
    let imgs = [];
    for (let img of imageTypes) {
      imgs.push({
        key: img.key,
        label: img.label,
        encoded: null,
        fetched: false,
      });
    }
    setImages(imgs);
  }, [updatePricings]);

  useEffect(() => {
    let imageToFetch = images.find((i) => !i.fetched);
    if (imageToFetch) {
      fetchImage(imageToFetch.key);
    }
  }, [images]);

  return (
    <React.Fragment>
      <div className="text-center mb-3">
        {images ? (
          images
            .filter((i) => i.encoded !== null)
            .map((i) => (
              <SchemaImage key={i.key} image={i} fileName={fileName} />
            ))
        ) : (
          <React.Fragment />
        )}
      </div>
      {images ? (
        images
          .filter((i) => i.encoded !== null)
          .map((i) => (
            <SchemaDownloadMenu
              key={i.key}
              type={i}
              onClickDownload={onClickDownload}
            />
          ))
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
}

export default Schema;
