import React, { useState } from "react";
import { Card, CardBody, Row, Col, Button, Spinner, Form, Input, FormGroup, InputGroup } from "reactstrap";
import ShippingAddressForm from "./ShippingAddressForm";

import { map, catchError } from "rxjs/operators";

import apiDriver from "stores/api.driver";
import config from "config/global";
import { useTranslation } from "react-i18next";
import Country from "components/AddressForm/Country";

function AddressButton(props) {
  const { address, currentAddress, setAddress } = props;
  const active = address.id === currentAddress;
  const onClick = () =>
    setAddress(address);

  return (
    <Card
      data-shipping-address={address.id}
      className={
        active ? "border border-primary text-primary my-2" : "border my-2"
      }
      style={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <CardBody className="px-3 py-2">
        <Row>
          <Col md={1} className="pt-3">
            {address.isSystem && <i className="fas fa-box"></i>}
            {address.default && <i className="fas fa-star"></i>}
          </Col>
          <Col md={5}>
            <label className="m-0 p-0">{address.person}</label>
            <br />
            <label className="m-0 p-0">{address.email}</label>
            <br />
            <label className="m-0 p-0">{address.phone}</label>
          </Col>
          <Col md={6}>
            <label className="m-0 p-0">{address.line1}</label>
            <br />
            <label className="m-0 p-0">{address.line2}</label>
            <label className="m-0 p-0">
              {address.postalCode} {address.city}
            </label>
            <br />
            <label className="m-0 p-0"><Country countryCode={address.countryCode} /></label>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

function AddressSelector(props) {
  const { t, i18n } = useTranslation();
  const { addresses, currentAddress, setAddress, canCreate, onAddAddress, namespace, onSearch } =
    props;

  const [searchTerm, setSearchTerm] = useState("");

  const dbAddresses =
    addresses?.filter((address) => address.id !== "new") || [];
  const systemAddress = dbAddresses.find((address) => address.isSystem);
  const defaultAddress = dbAddresses.find((address) => address.default);
  const standardAddresses = dbAddresses.filter((address) => !address.default && !address.isSystem);

  const [draftAddress, setDraftAddress] = useState({ countryCode: "pl" });
  const [draftAddressError, setDraftAddressError] = useState("");
  const [draftAddressLoading, setDraftAddressLoading] = useState(false);

  const onChangeDraftAddress = (name, value) =>
    setDraftAddress({ ...draftAddress, [name]: value });

  const addDraftAddress = () => {
    if (!draftAddress.line1) {
      return setDraftAddressError(t("customers.shippingAddress.errors.line1"));
    }
    if (!draftAddress.postalCode) {
      return setDraftAddressError(
        t("customers.shippingAddress.errors.postalCode"),
      );
    }
    if (!draftAddress.city) {
      return setDraftAddressError(t("customers.shippingAddress.errors.city"));
    }
    if (!draftAddress.countryCode) {
      return setDraftAddressError(
        t("customers.shippingAddress.errors.countryCode"),
      );
    }
    setDraftAddressError("");
    setDraftAddressLoading(true);
    apiDriver
      .put(
        `${config.api.orders}${i18n.resolvedLanguage}/${namespace}/`,
        draftAddress,
      )
      .pipe(
        map((response) => response.response),
        catchError(() => {
          return setDraftAddressError(t("errors.common.title"));
        }),
      )
      .subscribe((data) => {
        setDraftAddressLoading(false);
        onAddAddress(data);
      });
  };

  return (
    <>
      {onSearch && <FormGroup>
        <InputGroup>
          <Input type="text" value={searchTerm} onChange={e => setSearchTerm(e.target.value)} placeholder={t('common.search')} />
          <Button color="primary" outline onClick={e => onSearch(searchTerm)}><i className="fas fa-search"></i></Button>
        </InputGroup>
      </FormGroup>}
      <hr className="my-3 p-0" />
      {systemAddress && (
        <>
          <AddressButton
            key={systemAddress.id}
            address={systemAddress}
            currentAddress={currentAddress}
            setAddress={setAddress}
          />
          <hr className="my-3 p-0" />
        </>
      )}
      {defaultAddress && (
        <>
          <AddressButton
            key={defaultAddress.id}
            address={defaultAddress}
            currentAddress={currentAddress}
            setAddress={setAddress}
          />
          <hr className="my-3 p-0" />
        </>
      )}
      {standardAddresses.length > 0 ? (
        <>
          {standardAddresses
            .filter((address) => address.id !== "new" && !address.default)
            .map((address) => (
              <AddressButton
                key={address.id}
                address={address}
                currentAddress={currentAddress}
                setAddress={setAddress}
              />
            ))}
        </>
      ) : (
        <React.Fragment />
      )}
      {canCreate ? (
        <>
          <hr className="my-3 p-0 w-100" />
          <h6 className="text-uppercase">
            {t("customers.shippingAddress.add")}
          </h6>
          <Form onSubmit={addDraftAddress}>
            <ShippingAddressForm
              shippingAddress={draftAddress || {}}
              setShippingAddress={onChangeDraftAddress}
              readOnly={draftAddressLoading}
            />
            <Row>
              <Col sm={12}>
                {draftAddressError ? (
                  <div>
                    <p className="alert alert-danger py-2">
                      {draftAddressError}
                    </p>
                  </div>
                ) : (
                  <React.Fragment />
                )}
              </Col>
              <Col sm={12}>
                {draftAddressLoading ? (
                  <Spinner className="float-right" />
                ) : (
                  <Button onClick={addDraftAddress} block>
                    {t("customers.shippingAddress.add")}
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </>
      ) : (
        <React.Fragment />
      )}
    </>
  );
}

export default AddressSelector;
