import React, { useState } from "react";

import { Col, Row, Card, Tooltip } from "reactstrap";

function Option(props) {
  const { attribute, option, currentOptions, onChange, disabled } = props;

  const onClick = () =>
    !disabled
      ? onChange({
          target: { name: `attribute[${attribute.id}]`, value: option.id },
        })
      : false;

  const active = currentOptions.includes(option.id);

  return (
    <Col lg={6} onClick={onClick} className="p-1">
      <Card
        className={
          active
            ? "border border-primary mx-3 my-0 px-3 py-2 h-100 text-center"
            : disabled
            ? "border mx-3 my-0 px-3 py-2 h-100 text-center text-muted"
            : "border mx-3 my-0 px-3 py-2 h-100 text-center"
        }
        style={{ cursor: disabled ? "default" : "pointer" }}
      >
        <div className={active ? "text-primary" : ""}>
          {option.currentTranslation?.title}
        </div>
        {option.currentTranslation?.description ? (
          <div className={active ? "" : "text-muted"}>
            <small>{option.currentTranslation?.description}</small>
          </div>
        ) : (
          <React.Fragment />
        )}
      </Card>
    </Col>
  );
}

function Attribute(props) {
  const { attribute, options, onChange, productOptions, conflictedOptions } =
    props;

  const [isTooltipOpen, setTooltipOpen] = useState();
  const toggleTooltip = () => setTooltipOpen(!isTooltipOpen);

  let selected = attribute.options.filter((o) => options.includes(o.id));
  if (selected.length > 0) {
    selected = selected[0].id;
  } else {
    selected = undefined;
  }

  return (
    <div className="mb-3">
      <h4 className="text-uppercase">
        {attribute.currentTranslation?.title}
        {attribute.currentTranslation?.hint ? (
          <>
            <small
              style={{ fontSize: "50%", position: "relative", top: "-3px" }}
            >
              <i
                className="text-primary fas fa-question-circle ml-1"
                id={`tooltipAttribute${attribute.id}`}
              ></i>
            </small>
            <Tooltip
              placement="right"
              isOpen={isTooltipOpen}
              target={`tooltipAttribute${attribute.id}`}
              toggle={toggleTooltip}
            >
              {attribute.currentTranslation?.hint}
            </Tooltip>
          </>
        ) : (
          <React.Fragment />
        )}
      </h4>
      <Row className="px-2">
        {attribute.options
          .map((option) => ({
            option: option,
            order:
              productOptions.find((po) => po.optionId === option.id).order || 0,
          }))
          .sort((a, b) => b.order - a.order)
          .map((option) => option.option)
          .map((option) => (
            <Option
              key={option.id}
              attribute={attribute}
              option={option}
              currentOptions={options}
              productOptions={productOptions}
              onChange={onChange}
              disabled={conflictedOptions.includes(option.id)}
            />
          ))}
      </Row>
    </div>
  );
}

export default Attribute;
