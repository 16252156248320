import React from "react";

import CartProduct from "./CartProduct";
import { useTranslation } from "react-i18next";

function CartProducts(props) {
  const { t } = useTranslation();
  const {
    products,
    deleteOrderProduct,
    refreshOrderProduct,
    updateOrderProduct,
  } = props;
  return (
    <>
      <h2 className="text-uppercase">{t("products.products")}</h2>
      {products.map((product) => (
        <CartProduct
          key={product.id}
          product={product}
          deleteOrderProduct={deleteOrderProduct}
          refreshOrderProduct={refreshOrderProduct}
          updateOrderProduct={updateOrderProduct}
        />
      ))}
      <hr className="my-3 p-0 w-100" />
    </>
  );
}
export default CartProducts;
