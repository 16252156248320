import NumericInput from "components/form/NumericInput";
import React from "react";
import { useTranslation } from "react-i18next";

import { Alert, Input, Row, Col, Label } from "reactstrap";

function Colors() {
  return [
    "1+0",
    "1+1",
    "4+0",
    "4+1",
    "4+4",
    "2+0",
    "2+1",
    "5+0",
    "5+1",
    "5+4",
    "5+5",
  ];
}

function PagesRange(props) {
  const { t } = useTranslation();
  const { configuration, pages, changePages, properties } = props;

  const currentPages = {
    inside: pages.inside || configuration.default.inside || 0,
    outside: pages.outside || configuration.default.y || 0,
  };

  const minimal = configuration.minimal || { inside: 0, outside: 0 };
  let maximal = {
    inside: configuration.maximal.inside,
    outside: configuration.maximal.outside,
  };

  let isLimitedByThickness = false;
  if (
    configuration &&
    configuration.maximalThickness &&
    (configuration.maximalThickness.inside ||
      configuration.maximalThickness.outside) &&
    properties &&
    properties.thickness
  ) {
    const perPageThickness = {
      inside: properties.thickness.inside * 0.5,
      outside: properties.thickness.outside * 0.5,
    };
    const maximalThickness = configuration.maximalThickness;
    maximal = {
      inside:
        maximalThickness?.inside && perPageThickness.inside
          ? Math.min(
              configuration.maximal?.inside,
              Math.floor(maximalThickness?.inside / perPageThickness.inside),
            )
          : configuration.maximal?.inside,
      outside:
        maximalThickness?.outside && perPageThickness.outside
          ? Math.min(
              configuration.maximal?.outside,
              Math.floor(maximalThickness?.outside / perPageThickness.outside),
            )
          : configuration.maximal?.outside,
    };
    isLimitedByThickness = true;
  }

  return (
    <React.Fragment>
      <Row>
        <Col md={5}>
          <Label for="pages_inside">
            <small className="text-muted text-uppercase">
              {t("products.pages.inside")}
            </small>
          </Label>
        </Col>
        <Col md={5}>
          <Label for="pages_inside">
            <small className="text-muted text-uppercase">
              {t("products.pages.outside")}
            </small>
          </Label>
        </Col>
        <Col md={2}></Col>
        <Col md={5}>
          <NumericInput
            id="pages_inside"
            type="number"
            name="inside"
            placeholder={currentPages.inside}
            min={minimal.inside || 0}
            max={maximal.inside || null}
            step={configuration.step?.inside || 1}
            value={pages.inside}
            onChange={changePages}
            readOnly={minimal.inside === maximal.inside}
          />
        </Col>
        <Col md={5}>
          <NumericInput
            type="number"
            name="outside"
            placeholder={currentPages.outside}
            min={minimal.outside || 0}
            max={maximal.outside || null}
            step={configuration.step?.outside || 1}
            value={pages.outside}
            onChange={changePages}
            readOnly={minimal.outside === maximal.outside}
          />
        </Col>
        <Col md={2} className="pt-2">
          {t("products.pages.pages")}
        </Col>
      </Row>
      {isLimitedByThickness ? (
        <Alert color="secondary" className="mt-3">
          <p>
            <b>{t("products.fields.thickness")}</b>
          </p>
          {t("products.thickness.limitation")}
          <ul>
            {configuration.maximalThickness?.inside ? (
              <li>
                {t("products.thickness.insideLimit")}:{" "}
                {configuration.maximalThickness.inside} mm,
              </li>
            ) : (
              <React.Fragment />
            )}
            {configuration.maximalThickness?.outside ? (
              <li>
                {t("products.thickness.outsideLimit")}:{" "}
                {configuration.maximalThickness.inside} mm,
              </li>
            ) : (
              <React.Fragment />
            )}
            {properties.thickness?.inside ? (
              <li>
                {t("products.thickness.insideCurrent")}:{" "}
                {properties.thickness.inside} mm,
              </li>
            ) : (
              <React.Fragment />
            )}
            {properties.thickness?.outside ? (
              <li>
                {t("products.thickness.outsideCurrent")}:{" "}
                {properties.thickness.outside} mm,
              </li>
            ) : (
              <React.Fragment />
            )}
            <li>
              {t("products.thickness.maxPages")} - {t("products.pages.inside")}:{" "}
              {maximal.inside}, {t("products.pages.outside")}: {maximal.outside}
              .
            </li>
          </ul>
        </Alert>
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
}

function PagesPool(props) {
  const { t } = useTranslation();
  const { pages, changePages, configuration } = props;

  return (
    <React.Fragment>
      <Input
        data-trigger=""
        id="pagesPool"
        name="pool"
        type="select"
        value={`${pages.inside},${pages.outside}`}
        onChange={changePages}
      >
        {configuration.defined.map((paging) => (
          <option
            key={`${paging.inside},${paging.outside}`}
            value={`${paging.inside},${paging.outside}`}
          >
            {paging.inside} {t("products.pages.inside")}, {paging.outside}{" "}
            {t("products.pages.outside")} {t("products.pages.pages")}
          </option>
        ))}
      </Input>
    </React.Fragment>
  );
}

function PagesColorGroup(props) {
  const {
    configuration,
    id,
    insideColor,
    outsideColor,
    availableColors,
    changeColor,
    canSelectColor,
  } = props;

  const onChangeColor = (e) => {
    const { id, name, value } = e.target;
    const colorValue = Number(value);
    changeColor(id, name, colorValue);
  };

  const isMain = id === "main";

  return (
    <Row className="mb-3">
      <Col md={4}>
        <NumericInput
          type="number"
          id={`${id}.inside`}
          name="pages"
          step={configuration.step?.inside || 1}
          value={insideColor.pages}
          onChange={onChangeColor}
          readOnly={isMain || !insideColor.color}
        />
      </Col>
      <Col md={2}>
        {canSelectColor ? (
          <Input
            type="select"
            id={`${id}.inside`}
            name="color"
            value={insideColor.color}
            onChange={onChangeColor}
            readOnly={!isMain}
          >
            {availableColors.map((label, v) => (
              <option key={v} value={v}>
                {label}
              </option>
            ))}
          </Input>
        ) : (
          <div className="pt-2">{availableColors[insideColor.color]}</div>
        )}
      </Col>
      <Col md={4}>
        <NumericInput
          type="number"
          id={`${id}.outside`}
          name="pages"
          step={configuration.step?.outside || 1}
          value={outsideColor.pages}
          onChange={onChangeColor}
          readOnly={isMain || !outsideColor.color}
        />
      </Col>
      <Col md={2}>
        {canSelectColor ? (
          <Input
            type="select"
            id={`${id}.outside`}
            name="color"
            value={outsideColor.color}
            onChange={onChangeColor}
            readOnly={!isMain}
          >
            {availableColors.map((label, v) => (
              <option key={v} value={v}>
                {label}
              </option>
            ))}
          </Input>
        ) : (
          <div className="pt-2">{availableColors[outsideColor.color]}</div>
        )}
      </Col>
    </Row>
  );
}

function PagesColor(props) {
  const { t } = useTranslation();
  const { configuration, color, changeColor } = props;
  if (!color) return <React.Fragment />;

  const colors = [
    ...new Set(color.map((c) => c.name.substr(0, c.name.indexOf(".")))),
  ]
    .map((c) => {
      const cl = color
        .filter((col) => col.name.indexOf(`${c}.`) !== false)
        .map((col) => ({ ...col, name: col.name.replace(`${c}.`, "") }))
        .reduce((reduced, col) => {
          reduced[col.name] = col;
          return reduced;
        }, {});

      return { key: c, inside: cl.inside || null, outside: cl.outside || null };
    })
    .filter((c) => c.inside?.color !== null || c.outside?.color !== null);

  if (colors.length <= 1) {
    return <React.Fragment />;
  }

  return (
    <React.Fragment>
      <Alert className="mt-3" color="secondary">
        <p>
          <b>{t("products.print.print")}</b>
        </p>
        {t("products.print.canDefinePerPage")}
      </Alert>
      <Row>
        <Col md={6}>
          <Label for="pages_inside">
            <small className="text-muted text-uppercase">
              {t("products.pages.inside")}
            </small>
          </Label>
        </Col>
        <Col md={6}>
          <Label for="pages_inside">
            <small className="text-muted text-uppercase">
              {t("products.pages.outside")}
            </small>
          </Label>
        </Col>
      </Row>
      {colors.map((c) => {
        return (
          <PagesColorGroup
            configuration={configuration}
            key={c.key}
            id={c.key}
            insideColor={c.inside}
            outsideColor={c.outside}
            availableColors={Colors()}
            changeColor={changeColor}
          />
        );
      })}
    </React.Fragment>
  );
}

function BookCoverInfo(props) {
  const { t } = useTranslation();
  const { properties, pages } = props;
  if (pages.outside === 0) {
    return <React.Fragment />;
  }
  const bookThickness =
    (pages.inside / 2) * properties.thickness.inside +
    (pages.outside / 2) * properties.thickness.outside;
  return (
    <Alert className="mt-3" color="secondary">
      <p>
        <b>{t("products.spine.spine")}</b>
      </p>
      {t("products.spine.width")}:{" "}
      {new Intl.NumberFormat().format(Math.ceil(bookThickness * 10) / 10)} mm
    </Alert>
  );
}

function Pages(props) {
  const { configuration, pages, changePages, properties, color, changeColor } =
    props;

  if (!configuration) {
    return <React.Fragment />;
  }

  return (
    <React.Fragment>
      {configuration.type === "Pool" ? (
        <PagesPool
          properties={properties}
          configuration={configuration}
          pages={pages}
          changePages={changePages}
        />
      ) : (
        <React.Fragment />
      )}
      {configuration.type === "Range" ? (
        <PagesRange
          properties={properties}
          configuration={configuration}
          pages={pages}
          changePages={changePages}
        />
      ) : (
        <React.Fragment />
      )}
      {configuration.canDetermineColor ? (
        <PagesColor
          configuration={configuration}
          color={color}
          changeColor={changeColor}
          canSelectColor={configuration.canDetermineColorByUser}
        />
      ) : (
        <React.Fragment />
      )}
      <BookCoverInfo pages={pages} properties={properties} />
    </React.Fragment>
  );
}

export default Pages;
