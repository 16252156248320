import React, { useState } from "react";

import {
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
  Badge,
  Card,
  CardBody,
} from "reactstrap";

import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import apiDriver from "stores/api.driver";
import config from "config/global";
import { useTranslation } from "react-i18next";

function Note(props) {
  const { note, isByClient } = props;
  const rowClass = isByClient
    ? "justify-content-start"
    : "justify-content-end text-right";
  const cardClass = isByClient ? "" : "bg-gradient-primary text-white";
  return (
    <Row className={rowClass}>
      <Col className="col-auto" style={{ minWidth: "50%" }}>
        <Card className={cardClass}>
          <CardBody className="p-2">
            <p className="mb-1">
              <b>{note.title}</b>
            </p>
            <p className="mb-1">{note.content}</p>
            <div>
              <small className="opacity-60">
                <i className="far fa-clock"></i> &nbsp;
                {new Date(note.created).toLocaleTimeString()}
              </small>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

function NoteForm(props) {
  const { i18n } = useTranslation();
  const { order, orderProduct, onCreateNote } = props;
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);

  const onChangeTitle = (e) => setTitle(e.target.value);
  const onChangeContent = (e) => setContent(e.target.value);

  const resetForm = () => {
    setTitle("");
    setContent("");
  };

  const submitForm = () => {
    setLoading(true);
    apiDriver
      .put(`${config.api.orders}${i18n.resolvedLanguage}/OrderNotes/`, {
        orderId: order ? order.id : null,
        productId: orderProduct ? orderProduct.id : null,
        title: title,
        content: content,
      })
      .pipe(
        map((response) => {
          resetForm();
          onCreateNote(response.response);
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe(() => {
        setLoading(false);
      });
  };

  return (
    <Form>
      <h6>Dodaj komentarz</h6>
      <FormGroup>
        <Input
          type="text"
          value={title}
          onChange={onChangeTitle}
          placeholder="Tytuł"
        />
        <Input
          type="textarea"
          value={content}
          onChange={onChangeContent}
          placeholder="Treść komentarza"
          rows="5"
        />
      </FormGroup>
      <FormGroup align="right">
        <Button color="primary" onClick={submitForm} disabled={loading}>
          {!loading ? (
            <span>Dodaj komentarz</span>
          ) : (
            <i className="fas fa-circle-notch fa-spin"></i>
          )}
        </Button>
      </FormGroup>
    </Form>
  );
}

function NotesModal(props) {
  const { t } = useTranslation();
  const { buttonProps, title, notes, order, orderProduct, onCreateNote } =
    props;
  const [modalOpen, setModalOpen] = useState(false);
  let notesGroupedByDate = [];
  notes.forEach((note) => {
    const date = note.created.split("T")[0];
    const index = notesGroupedByDate.findIndex((g) => g.date === date);
    if (index === -1) {
      notesGroupedByDate.push({ date: date, notes: [note] });
    } else {
      notesGroupedByDate[index].notes.push(note);
    }
  });

  return (
    <>
      <Button
        type="button"
        onClick={() => setModalOpen(!modalOpen)}
        {...buttonProps}
      >
        <i className="fas fa-address-book"></i> {t('order.allNotes')} ({notes.length})
      </Button>
      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        size="xl"
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
        {t('order.allNotes')} {title}
        </ModalHeader>
        <ModalBody>
          {notes.length === 0 ? (
            <Alert color="info">Brak komentarzy do tego zamówienia...</Alert>
          ) : (
            <React.Fragment />
          )}
          {notesGroupedByDate.map((group) => (
            <div key={group.date}>
              <Row className="mt-0 mb-3">
                <Col className="text-center" md="12">
                  <Badge className="text-default">
                    {new Date(group.date).toLocaleDateString()}
                  </Badge>
                </Col>
              </Row>
              {group.notes.map((note) => (
                <Note
                  key={note.id}
                  note={note}
                  isByClient={
                    note.userId === order.userId &&
                    note.guestId === order.guestId
                  }
                />
              ))}
            </div>
          ))}
          <hr />
          <NoteForm
            order={order}
            orderProduct={orderProduct}
            onCreateNote={onCreateNote}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            className="ml-auto"
            color="link"
            onClick={() => setModalOpen(!modalOpen)}
            type="button"
          >
            Zamknij
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default NotesModal;
