import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table, Nav, NavItem, NavLink } from "reactstrap";

const COOKIE_CATEGORIES = {
  NECESSARY: 1,
  FUNCTIONAL: 2,
  ANALYTICS: 3,
  ADVERTISMENT: 4,
};

function Duration(props) {
  const { i18n } = useTranslation();
  const { value } = props;
  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  const month = day * 30;
  const year = month * 12;

  const defaultConfig = {style: "unit", roundingMode: "ceil", roundingIncrement: 1, unitDisplay: "long"};

  if(value >= year) {
    return Intl.NumberFormat(i18n.resolvedLanguage, {...defaultConfig, unit: "year", }).format(Math.ceil(value / year));
  }
  if(value >= month) {
    return Intl.NumberFormat(i18n.resolvedLanguage, {...defaultConfig, unit: "month"}).format(Math.ceil(value / month));
  }
  if(value >= day) {
    return Intl.NumberFormat(i18n.resolvedLanguage, {...defaultConfig, unit: "day"}).format(Math.ceil(value / day));
  }
  if(value >= hour) {
    return Intl.NumberFormat(i18n.resolvedLanguage, {...defaultConfig, unit: "hour"}).format(Math.ceil(value / hour));
  }
  if(value >= minute) {
    return Intl.NumberFormat(i18n.resolvedLanguage, {...defaultConfig, unit: "minute"}).format(Math.ceil(value / minute));
  }
  return Intl.NumberFormat(i18n.resolvedLanguage, {...defaultConfig,  unit: "second"}).format(Math.ceil(value));
}

function CookieRow(props) {
  const { cookie, onChange, isActive } = props;

  return (
    <tr>
      <td>
        <i onClick={onChange} style={{cursor: 'pointer'}} className={isActive ? "fas fa-check text-primary" :  "fas fa-times text-muted"}></i>
      </td>
      <td>{cookie.id}</td>
      <td>{cookie.description}</td>
      <td>{cookie.source}</td>
      <td>{isNaN(cookie.duration) ? cookie.duration : <Duration value={cookie.duration} />}</td>
    </tr>
  )
}

function CookiesPersonalizationBody(props) {
  const { t } = useTranslation();
  const { isPersonalizationOpen, setConsent, consents } = props;

  const [ category, setCategory ] = useState(COOKIE_CATEGORIES.NECESSARY);

  if(!isPersonalizationOpen) {
    return <></>;
  }

  return (
    <ModalBody
      style={{ display: isPersonalizationOpen ? "block" : "none" }}
    >
      <div>
        <Nav pills>
          <NavItem onClick={() => setCategory(COOKIE_CATEGORIES.ADVERTISMENT)}>
            <NavLink active={category === COOKIE_CATEGORIES.ADVERTISMENT} href="#">{t('cookies.categories.advertisment')}</NavLink>
          </NavItem>
          <NavItem onClick={() => setCategory(COOKIE_CATEGORIES.ANALYTICS)}>
            <NavLink active={category === COOKIE_CATEGORIES.ANALYTICS} href="#">{t('cookies.categories.analytics')}</NavLink>
          </NavItem>
          <NavItem onClick={() => setCategory(COOKIE_CATEGORIES.FUNCTIONAL)}>
            <NavLink active={category === COOKIE_CATEGORIES.FUNCTIONAL} href="#">{t('cookies.categories.functional')}</NavLink>
          </NavItem>
          <NavItem onClick={() => setCategory(COOKIE_CATEGORIES.NECESSARY)}>
            <NavLink active={category === COOKIE_CATEGORIES.NECESSARY} href="#">{t('cookies.categories.necessary')}</NavLink>
          </NavItem>
        </Nav>
      </div>
      <div>
        <Table responsive>
          <thead>
            <tr>
              <th></th>
              <th>{t('common.id')}</th>
              <th>{t('common.description')}</th>
              <th>{t('cookies.source')}</th>
              <th>{t('common.lifespan')}</th>
            </tr>
          </thead>
          <tbody>
            {getCookiesList().filter(c => c.category === category).map(cookie => <CookieRow key={cookie.id} isActive={consents?.includes(cookie.id)} onChange={(e) => setConsent(cookie.id)} cookie={cookie} />)}
          </tbody>
        </Table>
      </div>
    </ModalBody>
  )
}

export default function CookiesModal() {
  const { t } = useTranslation();
  const [isPersonalizationOpen, setPersonalizationOpen] = useState(false);

  const [isOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!isOpen);
  const [ consents, setConsents ] = useState(getCookiesList().filter(c => c.default));
  const setConsent = (consent) => consents.includes(consent) ? setConsents([...consents.filter(c => c !== consent)]) : setConsents([...consents, consent]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered size="lg">
      <ModalHeader>{t('cookies.title')}</ModalHeader>
      <ModalBody
        className="text-justify"
        style={{ display: !isPersonalizationOpen ? "inherit" : "none" }}
      >
        {t('cookies.description')}
      </ModalBody>
      <CookiesPersonalizationBody isPersonalizationOpen={isPersonalizationOpen} setConsent={setConsent} consents={consents} />
      <ModalFooter>
        <Button color="black" outline size="sm">
          {t('cookies.privacy')}
        </Button>
        <Button>{t('actions.cancel')}</Button>
        <Button
          onClick={() => setPersonalizationOpen(true)}
          color="dark"
          style={{ display: !isPersonalizationOpen ? "inherit" : "none" }}
        >
          {t('cookies.manage')}
        </Button>
        <Button color="primary" onClick={toggle}>
          {t('cookies.acceptAll')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}


const getCookiesList = () => ([
  {
    "source": "Grupa RM sp. z o.o.",
    "id": "cookie_consents",
    "category": COOKIE_CATEGORIES.NECESSARY,
    "description": "Selected consents for cookies and localStorage",
    "duration": 259200,
    "default": true
  },
  {
    "source": "Grupa RM sp. z o.o.",
    "id": "customFormats",
    "category": COOKIE_CATEGORIES.NECESSARY,
    "description": "Custom formats defined by user for specific products",
    "duration": 259200,
    "default": true
  },
  {
    "source": "Grupa RM sp. z o.o.",
    "id": "customAmount",
    "category": COOKIE_CATEGORIES.NECESSARY,
    "description": "Custom amount defined by user for specific products",
    "duration": 259200,
    "default": true
  },
  {
    "source": "Grupa RM sp. z o.o.",
    "id": "i18nextLng",
    "category": COOKIE_CATEGORIES.NECESSARY,
    "description": "Selected locale culture by user",
    "duration": 259200,
    "default": true
  },
  {
    "source": "",
    "id": "state",
    "category": COOKIE_CATEGORIES.NECESSARY,
    "description": "State of cart and authorization data",
    "duration": 259200,
    "default": true
  },
  {
    "source": "",
    "id": "__gsas",
    "category": COOKIE_CATEGORIES.ADVERTISMENT,
    "description": "AdSense for Search",
    "duration": 259200
  },
  {
    "source": "Google, Inc.",
    "id": "__gpi",
    "category": COOKIE_CATEGORIES.ADVERTISMENT,
    "description": "AdSense, Google Ad Manager",
    "duration": 1123200
  },
  {
    "source": "Google, Inc.",
    "id": "__gpi_optout",
    "category": COOKIE_CATEGORIES.ADVERTISMENT,
    "description": "AdSense, Google Ad Manager",
    "duration": 1123200
  },
  {
    "source": "Google, Inc.",
    "id": "__gads",
    "category": COOKIE_CATEGORIES.ADVERTISMENT,
    "description": "AdSense, Display & Video 360, Google Ad Manager, Google Ads",
    "duration": 1123200
  },
  {
    "source": "Google, Inc.",
    "id": "GED_PLAYLIST_ACTIVITY",
    "category": COOKIE_CATEGORIES.ADVERTISMENT,
    "description": "AdSense, Google Ad Manager, YouTube",
    "duration": "session"
  },
  {
    "source": "Google, Inc.",
    "id": "FPGCLAW",
    "category": COOKIE_CATEGORIES.ANALYTICS,
    "description": "Google Ads",
    "duration": 259200
  },
  {
    "source": "Google, Inc.",
    "id": "FPGCLGB",
    "category": COOKIE_CATEGORIES.ANALYTICS,
    "description": "Google Ads",
    "duration": 259200
  },
  {
    "source": "Google, Inc.",
    "id": "_gcl_gb",
    "category": COOKIE_CATEGORIES.ANALYTICS,
    "description": "Google Ads",
    "duration": 259200
  },
  {
    "source": "Google, Inc.",
    "id": "_gac_gb_<wpid>",
    "category": COOKIE_CATEGORIES.ANALYTICS,
    "description": "Google Ads",
    "duration": 259200
  },
  {
    "source": "Google, Inc.",
    "id": "_gcl_aw",
    "category": COOKIE_CATEGORIES.ANALYTICS,
    "description": "Google Ads",
    "duration": 259200
  },
  {
    "source": "Google, Inc.",
    "id": "FPLC",
    "category": COOKIE_CATEGORIES.ANALYTICS,
    "description": "Google Analytics",
    "duration": 72000
  },
  {
    "source": "Google, Inc.",
    "id": "_ga",
    "category": COOKIE_CATEGORIES.ANALYTICS,
    "description": "Google Analytics",
    "duration": 63072000
  },
  {
    "source": "Google, Inc.",
    "id": "_gac_<wpid>",
    "category": COOKIE_CATEGORIES.ADVERTISMENT,
    "description": "Google Analytics",
    "duration": 259200
  },
  {
    "source": "Google, Inc.",
    "id": "_gid",
    "category": COOKIE_CATEGORIES.ANALYTICS,
    "description": "Google Analytics",
    "duration": 86400
  },
  {
    "source": "Google, Inc.",
    "id": "_gat[_<customname>]",
    "category": COOKIE_CATEGORIES.ANALYTICS,
    "description": "Google Analytics",
    "duration": 60
  },
  {
    "source": "Google, Inc.",
    "id": "__utma",
    "category": COOKIE_CATEGORIES.ANALYTICS,
    "description": "Google Analytics",
    "duration": 63072000
  },
  {
    "source": "Google, Inc.",
    "id": "__utmb",
    "category": COOKIE_CATEGORIES.ANALYTICS,
    "description": "Google Analytics",
    "duration": 1800
  },
  {
    "source": "Google, Inc.",
    "id": "__utmc",
    "category": COOKIE_CATEGORIES.ANALYTICS,
    "description": "Google Analytics",
    "duration": "session"
  },
  {
    "source": "Google, Inc.",
    "id": "__utmt",
    "category": COOKIE_CATEGORIES.ANALYTICS,
    "description": "Google Analytics",
    "duration": 600
  },
  {
    "source": "Google, Inc.",
    "id": "__utmz",
    "category": COOKIE_CATEGORIES.ANALYTICS,
    "description": "Google Analytics",
    "duration": 15552000
  },
  {
    "source": "Google, Inc.",
    "id": "__utmv",
    "category": COOKIE_CATEGORIES.ANALYTICS,
    "description": "Google Analytics",
    "duration": 63072000
  },
  {
    "source": "Google, Inc.",
    "id": "AMP_TOKEN",
    "category": COOKIE_CATEGORIES.FUNCTIONAL,
    "description": "Google Analytics",
    "duration": "30 seconds to 1 year",
    "default": true
  },
  {
    "source": "Google, Inc.",
    "id": "FPID",
    "category": COOKIE_CATEGORIES.ANALYTICS,
    "description": "Google Analytics",
    "duration": 63072000
  },
  {
    "source": "Google, Inc.",
    "id": "_ga_<wpid>",
    "category": COOKIE_CATEGORIES.ANALYTICS,
    "description": "Google Analytics 360",
    "duration": 63072000
  },
  {
    "source": "Google, Inc.",
    "id": "_dc_gtm_<property-id>",
    "category": COOKIE_CATEGORIES.ANALYTICS,
    "description": "Google Analytics, Google Tag Manager",
    "duration": 60
  },
  {
    "source": "Google, Inc.",
    "id": "_gaexp",
    "category": COOKIE_CATEGORIES.ANALYTICS,
    "description": "Google Analytics, Optimize",
    "duration": 8035200
  },
  {
    "source": "Google, Inc.",
    "id": "_gaexp_rc",
    "category": COOKIE_CATEGORIES.ANALYTICS,
    "description": "Google Analytics, Optimize",
    "duration": 10
  },
  {
    "source": "Google, Inc.",
    "id": "_opt_awcid",
    "category": COOKIE_CATEGORIES.ANALYTICS,
    "description": "Google Analytics, Optimize",
    "duration": 86400
  },
  {
    "source": "Google, Inc.",
    "id": "_opt_awmid",
    "category": COOKIE_CATEGORIES.ANALYTICS,
    "description": "Google Analytics, Optimize",
    "duration": 86400
  },
  {
    "source": "Google, Inc.",
    "id": "_opt_awgid",
    "category": COOKIE_CATEGORIES.ANALYTICS,
    "description": "Google Analytics, Optimize",
    "duration": 86400
  },
  {
    "source": "Google, Inc.",
    "id": "_opt_awkid",
    "category": COOKIE_CATEGORIES.ANALYTICS,
    "description": "Google Analytics, Optimize",
    "duration": 86400
  },
  {
    "source": "Google, Inc.",
    "id": "_opt_utmc",
    "category": COOKIE_CATEGORIES.ANALYTICS,
    "description": "Google Analytics, Optimize",
    "duration": 86400
  },
  {
    "source": "Google, Inc.",
    "id": "_opt_expid",
    "category": COOKIE_CATEGORIES.ANALYTICS,
    "description": "Optimize",
    "duration": 10
  }
]);