import React from 'react';
import CountryCodeDependentInput from './CountryCodeDependentInput';
import { useTranslation } from 'react-i18next';

export default function VatIDInput(props) {
    const { t } = useTranslation();
    return <CountryCodeDependentInput
                propertyOfPattern="vatID"
                name={props.name || "vatID"}
                placeholder={props.placeholder || t('customers.billingInfo.vatID')}
                {...props}
            />
} 