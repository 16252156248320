import React from "react";

import { Input } from "reactstrap";

function CheckboxOption(props) {
  const { option, value, onClick, inputProps, multiple } = props;

  return (
    <>
      <div className="form-check">
        <Input
          class="form-check-input"
          type={multiple ? "checkbox" : "radio"}
          onChange={() => onClick(option.key)}
          id={option.key}
          {...inputProps}
          checked={multiple ? value.includes(option.key) : value === option.key}
        />
        <label className="form-check-label" htmlFor={option.key}>
          {option.label}
        </label>
      </div>
    </>
  );
}

export default function CheckboxSelector(props) {
  const { options, value, onChange, containerProps, inputProps, multiple } =
    props;

  return (
    <div {...containerProps}>
      {options.map((option) => (
        <CheckboxOption
          key={option.key}
          option={option}
          value={value}
          onClick={() => onChange(option.key)}
          inputProps={inputProps}
          multiple={multiple}
        />
      ))}
    </div>
  );
}
