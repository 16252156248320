import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Button,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Badge,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import { map, catchError } from "rxjs/operators";

import apiDriver from "stores/api.driver";
import config from "config/global";

import * as cartActions from "stores/cart/actions";
import ProductPersonalization from "components/product/ProductPersonalization";
import { url } from "stores/cart/epics";
import { useTranslation } from "react-i18next";
import RealizationTerm from "components/Cart/RealizationTerm";
import useUser from "hooks/useUser";
import LocalizedLink from "components/LocalizedLink";

function CartSummary(props) {
  const { t, i18n } = useTranslation();
  const { deleteCart } = props;

  const [cartValue, setCartValue] = useState(null);

  useEffect(() => {
    apiDriver
      .get(`${url()}/Value`)
      .pipe(
        map((response) => response.response),
        catchError(() => {
          return console.error("Błąd wczytywania produktu z koszyka");
        }),
      )
      .subscribe((data) => {
        setCartValue(data);
      });
  }, []);

  if (!cartValue) {
    return (
      <DropdownItem className="text-center">
        <Spinner />
      </DropdownItem>
    );
  }

  return (
    <DropdownItem tag="div">
      <Row>
        <Col md={4}>
          <Button onClick={deleteCart} color="danger">
            {t("cart.actions.empty")}
          </Button>
        </Col>
        <Col md={4}>
          {cartValue.rebate?.netCost !== 0 ? (
            <div className="clearfix">
              <div className="float-left font-weight-bold">
                {t("price.rebates")}
              </div>
              <div className="float-right text-success">
                {new Intl.NumberFormat(i18n.resolvedLanguage, {
                  style: "currency",
                  currency: "PLN",
                }).format(cartValue.rebate.netCost)}
              </div>
            </div>
          ) : (
            <React.Fragment />
          )}
          <div className="clearfix">
            <div className="float-left font-weight-bold">
              {t("order.product.price.net")}
            </div>
            <div className="float-right">
              {new Intl.NumberFormat(i18n.resolvedLanguage, {
                style: "currency",
                currency: "PLN",
              }).format(cartValue.products.netCost)}
            </div>
          </div>
          {cartValue.discount?.netCost !== 0 ? (
            <div className="clearfix">
              <div className="float-left font-weight-bold">
                {t("price.coupons")}
              </div>
              <div className="float-right text-success">
                {new Intl.NumberFormat(i18n.resolvedLanguage, {
                  style: "currency",
                  currency: "PLN",
                }).format(cartValue.discount.netCost)}
              </div>
            </div>
          ) : (
            <React.Fragment />
          )}
          {cartValue.shippingCost?.netCost !== 0 ? (
            <div className="clearfix">
              <div className="float-left font-weight-bold">
                {t("order.shipping.price.gross")}
              </div>
              <div className="float-right">
                {new Intl.NumberFormat(i18n.resolvedLanguage, {
                  style: "currency",
                  currency: "PLN",
                }).format(cartValue.shippingCost.grossCost)}
              </div>
            </div>
          ) : (
            <React.Fragment />
          )}
          {cartValue.paymentProvision?.netCost !== 0 ? (
            <div className="clearfix">
              <div className="float-left font-weight-bold">
                {t("order.payment.provision.gross")}
              </div>
              <div className="float-right">
                {new Intl.NumberFormat(i18n.resolvedLanguage, {
                  style: "currency",
                  currency: "PLN",
                }).format(cartValue.paymentProvision.grossCost)}
              </div>
            </div>
          ) : (
            <React.Fragment />
          )}
        </Col>
        <Col md={4}>
          {cartValue.discount?.netCost !== 0 ? (
            <div className="clearfix">
              <div className="float-left font-weight-bold">
                {t("order.coupon")}
              </div>
              <div className="float-right text-success">
                {new Intl.NumberFormat(i18n.resolvedLanguage, {
                  style: "currency",
                  currency: "PLN",
                }).format(cartValue.discount.grossCost)}
              </div>
            </div>
          ) : (
            <React.Fragment />
          )}
          <div className="clearfix">
            <div className="float-left font-weight-bold">
              {t("price.price.net")}
            </div>
            <div className="float-right">
              {new Intl.NumberFormat(i18n.resolvedLanguage, {
                style: "currency",
                currency: "PLN",
              }).format(cartValue.total.netCost)}
            </div>
          </div>
          <div className="clearfix">
            <div className="float-left font-weight-bold">
              {t("price.price.gross")}
            </div>
            <div className="float-right">
              {new Intl.NumberFormat(i18n.resolvedLanguage, {
                style: "currency",
                currency: "PLN",
              }).format(cartValue.total.grossCost)}
            </div>
          </div>
        </Col>
      </Row>
    </DropdownItem>
  );
}

function CartProduct(props) {
  const { t, i18n } = useTranslation();
  const { product } = props;
  const dispatch = useDispatch();

  const [fullProduct, setFullProduct] = useState(null);

  useEffect(() => {
    apiDriver
      .get(
        `${config.api.orders}${i18n.resolvedLanguage}/OrderProducts/${product.id}`,
      )
      .pipe(
        map((response) => response.response),
        catchError(() => {
          return console.error("Błąd wczytywania produktu z koszyka");
        }),
      )
      .subscribe((data) => {
        setFullProduct(data);
      });
  }, []);

  const deleteProduct = () => {
    apiDriver
      .remove(
        `${config.api.orders}${i18n.resolvedLanguage}/OrderProducts/${product.id}`,
      )
      .pipe(
        map((response) => response.response),
        catchError(() => {
          return console.error("Błąd usuwania produktu z koszyka");
        }),
      )
      .subscribe(() => {
        setFullProduct(null);
        dispatch(cartActions.get());
      });
  };

  if (!fullProduct) {
    return (
      <DropdownItem className="text-center">
        <Spinner />
      </DropdownItem>
    );
  }

  return (
    <DropdownItem tag="div">
      <Row>
        <Col md={2} className="text-center">
          <div
            className="full-background h-100 w-100"
            style={{
              minHeight: "96px",
              backgroundImage: `url(${config.api.products}${i18n.resolvedLanguage}/Products/${fullProduct?.product?.id}/Photo)`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
            }}
          ></div>
        </Col>
        <Col md={4}>
          <h6>
            {fullProduct.product
              ? fullProduct.product.translations[0]?.title
              : fullProduct.title}
          </h6>
          <p className="w-100 text-wrap">
            <ProductPersonalization product={fullProduct} />
          </p>
        </Col>
        <Col md={1} className="text-center">
          <p className="text-center">
            {fullProduct.personalization.amount} {t("price.piece")}
          </p>
        </Col>
        <Col md={2} className="text-center">
          <p className="text-center p-0 m-0">
            {new Intl.NumberFormat(i18n.resolvedLanguage, {
              style: "currency",
              currency: "PLN",
            }).format(fullProduct.priceObj.netCost)}{" "}
            {t("price.net")}
          </p>
          <p className="text-center text-muted p-0 m-0">
            <small>
              {new Intl.NumberFormat(i18n.resolvedLanguage, {
                style: "currency",
                currency: "PLN",
              }).format(fullProduct.priceObj.grossCost)}{" "}
              {t("price.gross")}
            </small>
          </p>
        </Col>
        <Col md={3}>
          <div className="text-center">
            <p className="text-center p-0 m-0"><RealizationTerm term={fullProduct.realizationTerm} /></p>
          </div>
          <br />
          <div className="text-center">
            <Button onClick={deleteProduct} color="danger" size="sm" outline block>
              {t("actions.delete")}
            </Button>
            <br />
            <Button color="dark" size="sm" outline block>
              {t("actions.edit")}
            </Button>
          </div>
        </Col>
      </Row>
    </DropdownItem>
  );
}

function Cart(props) {
  const { t } = useTranslation();
  const { toggleCollapse, withoutTitle } = props;
  const [isOpen, setOpen] = useState(false);
  const cart = useSelector((state) => state.cart);
  const user = useUser();
  const dispatch = useDispatch();

  const toggleOpen = () => setOpen(!isOpen);

  const checkCart = () => {
    dispatch(cartActions.get());
  };

  const deleteCart = () => {
    dispatch(cartActions.remove());
  };

  useEffect(() => {
    if (cart || cart.loading) {
      return;
    }
    checkCart();
  }, [cart?.id]);

  useEffect(() => {
    if (cart.loading) {
      return;
    }
    checkCart();
  }, [user?.id]);

  if (!cart || !cart.id || !cart.products || cart.products.length === 0) {
    return <React.Fragment />;
  }

  return (
    <UncontrolledDropdown nav inNavbar toggle={toggleOpen} isOpen={isOpen}>
      <DropdownToggle className="m-0 px-0 pt-2 d-none d-lg-block" nav>
        <Button
          onClick={() => setOpen(!isOpen)}
          color="primary"
          data-button="cartOpen"
        >
          <i className="fas fa-shopping-basket"></i>{" "}
          <span className="d-sm-inline d-md-none d-xl-inline">
            {!withoutTitle && t("cart.cart")}
          </span>{" "}
          &nbsp;
          <Badge color="white">{cart.products.length}</Badge>
        </Button>
      </DropdownToggle>
      <DropdownToggle className="m-0 px-0 pt-2 d-block d-lg-none" nav>
        <LocalizedLink to="/cart">
          <Button onClick={() => setTimeout(() => setOpen(false), 100) || toggleCollapse(false)} color="primary">
            <i className="fas fa-shopping-basket"></i>{" "}
            <span className="d-sm-inline d-md-none d-xl-inline">
              {!withoutTitle && t("cart.cart")}
            </span>{" "}
            &nbsp;
            <Badge color="white">{cart.products.length}</Badge>
          </Button>
        </LocalizedLink>
      </DropdownToggle>
      <DropdownMenu right style={{ width: "100vw", maxWidth: "968px" }}>
        <DropdownItem tag="div" header className="pl-4 text-left">
          <h5>{t("cart.cart")}</h5>
        </DropdownItem>
        <DropdownItem className="d-none d-lg-block" divider></DropdownItem>
        <DropdownItem className="d-none d-lg-block">
          <Row>
            <Col md={6} className="text-center text-muted text-uppercase">
              <small>{t("product.product")}</small>
            </Col>
            <Col md={1} className="text-center text-muted text-uppercase">
              <small>{t("products.fields.amount")}</small>
            </Col>
            <Col md={2} className="text-center text-muted text-uppercase">
              <small>{t("price.price.price")}</small>
            </Col>
            <Col md={3} className="text-center text-muted text-uppercase">
              <small>{t("order.shipping.shipping")}</small>
            </Col>
          </Row>
        </DropdownItem>
        <DropdownItem className="d-none d-lg-block" divider></DropdownItem>
        <div
          style={{ maxHeight: "35vh", overflowX: "visible", overflowY: "auto" }}
        >
          {isOpen &&
            cart.products.map((product) => (
              <CartProduct key={product.id} product={product} />
            ))}
        </div>
        <DropdownItem divider></DropdownItem>
        {isOpen ? (
          <CartSummary cart={cart} deleteCart={deleteCart} />
        ) : (
          <React.Fragment />
        )}
        <DropdownItem divider></DropdownItem>
        <Row>
          <Col md="6">&nbsp;</Col>
          <Col md="6">
            <DropdownItem tag="div" className="text-right">
              <LocalizedLink to="/cart">
                <Button color="primary" data-button="cartGo">
                  {t("order.actions.goToCart")}
                </Button>
              </LocalizedLink>
            </DropdownItem>
          </Col>
        </Row>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default Cart;
