import React from "react";
import { useTranslation } from "react-i18next";

import { Row, Col, Card, CardBody } from "reactstrap";

function PaymentMethod(props) {
  const { i18n } = useTranslation();
  const { method, selectedMethod, setMethod } = props;
  const currentTranslation = method.translations[0];
  const price =
    method.provisions && method.provisions.length > 0
      ? method.provisions[0].cost
      : 0.0;

  const active = selectedMethod && selectedMethod.id === method.id;
  const onClick = () => setMethod(method);
  return (
    <Card
      data-payment-method={method.id}
      className={
        active ? "border border-primary text-primary my-2" : "border my-2"
      }
      style={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <CardBody className="px-3 py-2">
        <Row>
          <Col md={3} className="text-center pt-2">
            <label htmlFor={`shippingMethod${method.id}`}>
              {currentTranslation?.image ? (
                <img
                  src={currentTranslation?.image}
                  className="img img-fluid"
                  style={{ maxHeight: "32px" }}
                  alt={currentTranslation?.title || method.id}
                />
              ) : method.isRequiredToProcessOrder ? (
                <i className="fas fa-credit-card fa-4x"></i>
              ) : (
                <i className="fas fa-handshake fa-4x"></i>
              )}
            </label>
          </Col>
          <Col md={6}>
            <label htmlFor={`shippingMethod${method.id}`}>
              <b>{currentTranslation?.title}</b>
              <br />
              {currentTranslation?.description}
            </label>
          </Col>
          <Col md={3} className="text-center pt-3" style={{ fontSize: "150%" }}>
            {Intl.NumberFormat(i18n.resolvedLanguage, {
              style: "currency",
              currency: "PLN"
            }).format(price)}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default PaymentMethod;
