import React from "react";

import TabTable from "./TabTable";
import MarkStatus from "components/mark-status/MarkStatus";
import config from "config/global";
import PaymentButton from "../../Orders/Payments/PaymentButton";
import { useTranslation } from "react-i18next";

export default function TabPayments() {
  const { t, i18n } = useTranslation();

  const columns = [
    {
      dataField: "id",
      text: t("common.id"),
      sort: true,
      formatter: (_cell, row) => (
        <React.Fragment>
          {row.externalId}
          <br />
          <small className="text-muted">{row.id}</small>
        </React.Fragment>
      ),
    },
    {
      dataField: "method",
      text: t("common.method"),
      sort: true,
      formatter: (cell, row) => cell ? (row.method?.translations[0]?.title || row.method?.id) : <span className="text-muted">{t('common.none')}</span>,
    },
    {
      dataField: "order.number",
      text: t("order.order"),
      sort: true,
    },
    {
      dataField: "status",
      text: t("common.status"),
      sort: true,
      formatter: (cell, row) =>
        cell ? (
          <span>
            <MarkStatus status={row.status} /> &nbsp;{row.status?.translations[0]?.title || row.status?.id}
          </span>
        ) : (
          <span className="text-muted">{t('common.none')}</span>
        ),
    },
    {
      dataField: "amount",
      text: t("price.amount"),
      sort: true,
    },
    {
      dataField: "created",
      text: t("order.payment.paymentDate"),
      sort: true,
      formatter: (cell) => cell.substr(0, 10),
    },
    {
      isDummyField: true,
      dataField: "",
      text: "",
      formatter: (_cell, row) => (
        <PaymentButton payment={row} />
      ),
    },
  ];

  return (
    <TabTable
      id="payments"
      url={`${config.api.orders}${i18n.resolvedLanguage}/Payments/`}
      title={t("order.payment.payments")}
      columns={columns}
    />
  );
}
