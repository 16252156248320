import React from "react";

function CustomizationBadges(props) {
  const { orderProduct } = props;
  return (
    <>
      {orderProduct?.personalization?.optionsLoaded.map((option) => {
          const optionTranslation = option.translations[0];
          const attribute = option.attribute;
          const attributeTranslation = attribute.translations[0];
          return (
            <span
              key={`option${option.id}`}
              className="badge badge-secondary mr-3"
            >
              {attributeTranslation?.title}: {optionTranslation?.title}
            </span>
          );
        })}
      {orderProduct?.personalization?.additivesLoaded?.map((additive) => {
          const additiveTranslation = additive.translations[0];
          const group = additive.group;
          if (group) {
            const groupTranslation = group.translations[0];
            return (
              <span
                key={`additive${additive.id}`}
                className="badge badge-secondary mr-3"
              >
                {groupTranslation?.title}: {additiveTranslation?.title}
              </span>
            );
          } else {
            return (
              <span
                key={`additive${additive.id}`}
                className="badge badge-secondary mr-3"
              >
                {additiveTranslation?.title}
              </span>
            );
          }
        })}
    </>
  );
}

export default CustomizationBadges;
