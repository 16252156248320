import React from "react";
import { Badge } from "reactstrap";

function StatusBadge(props) {
  const { status } = props;

  if (!status) {
    return <React.Fragment />;
  }

  const currentTranslation = status.translations[0];

  return (
    <Badge
      color={
        status.isClosed
          ? "secondary"
          : status.isUserAttentionRequired
          ? "danger"
          : "primary"
      }
    >
      {currentTranslation?.title || status.tag}
    </Badge>
  );
}

export default StatusBadge;
