import React from "react";
import { useParams } from "react-router-dom";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// Core Components
import PageEmbed from "components/CMS/PageEmbed";

function Page() {
  const { url } = useParams();

  return (
    <>
      <Container
        fluid
        className="shape-container d-flex align-items-center py-lg"
      >
        <div className="col px-0">
          <Row className="align-items-center justify-content-center">
            <Col xl="10" className="mx-auto">
              <PageEmbed url={url} title content />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}

export default Page;
