// eslint-disable-next-line
import { store, epicMiddleware } from "stores/store";
import { combineEpics } from "redux-observable";

import authEpics from "stores/auth/epics";
import cartEpics from "stores/cart/epics";

const rootEpic = combineEpics(authEpics, cartEpics);

epicMiddleware.run(rootEpic);

export default epicMiddleware;
