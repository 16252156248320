import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { InputGroupAddon, InputGroupText, Button, Row, Col } from "reactstrap";
import NumericInput from "components/form/NumericInput";
import InputSelector from "components/form/InputSelector";
import ButtonSelector from "components/form/ButtonSelector";
import CheckboxSelector from "components/form/CheckboxSelector";
import { useTranslation } from "react-i18next";

function CustomFormat(props) {
  const { t } = useTranslation();
  const { configuration, addCustomFormat } = props;

  const [x, setX] = useState(undefined);
  const [y, setY] = useState(undefined);

  const xRange = {
    min: configuration.minimal.x || 0,
    max: configuration.maximal.x || undefined,
  };
  const yRange = {
    min: configuration.minimal.y || 0,
    max: configuration.maximal.y || undefined,
  };

  const isValid =
    x >= xRange.min && x <= xRange.max && y >= yRange.min && y <= yRange.max;
  const isValidOrNotFilled = (x === undefined && y === undefined) || isValid;
  const isValidAndFilled = x > 0 && y > 0 && isValid;

  const onClick = () => (isValidAndFilled ? addCustomFormat(x, y) : false);
  const onChange = (e) => {
    const { name, value } = e.target;
    const method = name === "x" ? setX : setY;
    method(value ? Number(value) : undefined);
  };

  return (
    <div>
      <hr className="my-3 w-100" />
      <Row>
        <Col xs={5} md={3} lg={2} xl={3} className="p-0">
          <NumericInput
            type="number"
            name="x"
            placeholder={t("products.format.x")}
            min={xRange.min}
            max={xRange.max}
            step="1"
            value={x}
            onChange={onChange}
            className={!isValidOrNotFilled ? "is-invalid" : ""}
          />
        </Col>
        <Col xs={5} md={3} lg={2} xl={3} className="p-0">
          <NumericInput
            type="number"
            name="y"
            placeholder={t("products.format.y")}
            min={yRange.min}
            max={yRange.max}
            step="1"
            value={y}
            onChange={onChange}
            className={!isValidOrNotFilled ? "is-invalid" : ""}
          />
        </Col>
        <Col xs={2} md={2} lg={2} xl={1} className="p-0">
          <InputGroupAddon addonType="append">
            <InputGroupText className="w-100 text-center">
              {Unit(configuration.unit)}
            </InputGroupText>
          </InputGroupAddon>
        </Col>
        <Col xs={12} md={4} lg={6} xl={5} className="p-0">
          <Button
            color="primary"
            block={true}
            onClick={onClick}
            disabled={!isValidAndFilled}
          >
            {t("products.actions.addFormat")}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

function Unit(id) {
  switch (Number(id)) {
    case 1:
      return "mm";
    case 10:
      return "cm";
    case 100:
      return "dm";
    case 1000:
      return "m";
    default:
      return "mm";
  }
}

function FormatButton(props) {
  const { t } = useTranslation();
  const { format, size, removeFormat } = props;

  const active = size.x === format.x && size.y === format.y;

  format.shortest = Math.min(format.x, format.y);
  format.longest = Math.max(format.x, format.y);

  const ratio = {
    x: format.shortest === format.x ? (format.x / format.y) * 100 : 100,
    y: format.shortest === format.x ? 100 : (format.y / format.x) * 100,
  };

  return (
    <>
      <div style={{ height: "96px", width: "96px" }} className="mx-auto">
        <div
          style={{
            border: active ? "1px solid #000" : "1px solid #999",
            width: `${ratio.x}%`,
            height: `${ratio.y}%`,
          }}
          className="text-left mx-auto px-2"
        >
          <span
            className={active ? "text-primary text-bold" : "text-muted"}
            style={{ fontSize: "50%" }}
          >
            {format.translations[0]?.title}
          </span>
        </div>
      </div>
      <div>
        <span className={active ? "text-primary text-bold" : "text-muted"}>
          {format.translations[0]?.title || t("products.format.custom")}
          {format.custom ? (
            <span onClick={() => removeFormat(format.x, format.y)}>
              {" "}
              &nbsp; <i className="fas fa-times"></i>
            </span>
          ) : (
            <React.Fragment />
          )}
        </span>
      </div>
      <small className={active ? "" : "text-muted"}>
        {format.x} x {format.y} mm
      </small>
    </>
  );
}

function Format(props) {
  const { formats, configuration, size, changeSize } = props;
  const { id } = useParams();
  const customTranslation = {};

  const [customFormats, setCustomFormats] = useState(() => {
    let customAmounts = localStorage.getItem(id + ".customFormats");
    if (customAmounts) {
      customAmounts = JSON.parse(customAmounts);
      customAmounts = customAmounts
        .filter((f) => f)
        .map((f) => ({
          custom: true,
          x: f.x,
          y: f.y,
          currentTranslation: customTranslation,
          translations: [customTranslation],
        }));
      return customAmounts;
    }
    return [];
  });

  useEffect(() => {
    localStorage.setItem(id + ".customFormats", JSON.stringify(customFormats));
  }, [customFormats]);

  const addCustomFormat = (x, y) => {
    if (customFormats.some((f) => f.x === x && f.y === y)) {
      return;
    }
    if (formats.some((f) => f.x === x && f.y === y)) {
      return;
    }
    setCustomFormats([
      ...customFormats,
      {
        custom: true,
        x: x,
        y: y,
        currentTranslation: customTranslation,
        translations: [customTranslation],
      },
    ]);
  };

  const removeCustomFormat = (x, y) => {
    setCustomFormats(customFormats.filter((f) => f.x !== x && f.y !== y));
  };

  const allFormats = customFormats.concat(formats);

  if (!configuration) {
    return <React.Fragment />;
  }

  let selector;

  if (allFormats && allFormats.length) {
    switch (configuration.inputType) {
      case "Dropdown":
      case "Select":
        selector = (
          <InputSelector
            options={allFormats.map((f) => ({
              key: `${f.x},${f.y}`,
              label: f.translations[0]?.title || `${f.x} x ${f.y}`,
            }))}
            value={`${size.x},${size.y}`}
            onChange={(val) =>
              changeSize({ target: { name: "pool", value: val } })
            }
          />
        );
        break;
      case "Checkbox":
        selector = (
          <CheckboxSelector
            options={allFormats.map((f) => ({
              key: `${f.x},${f.y}`,
              label: f.translations[0]?.title || `${f.x} x ${f.y}`,
            }))}
            value={`${size.x},${size.y}`}
            onChange={(val) =>
              changeSize({ target: { name: "pool", value: val } })
            }
            inputProps={{ name: "format" }}
          />
        );
        break;
      case "Buttons":
      default:
        selector = (
          <ButtonSelector
            options={allFormats.map((format) => {
              return {
                key: `${format.x},${format.y}`,
                label: (
                  <FormatButton
                    format={format}
                    size={size}
                    removeFormat={removeCustomFormat}
                  />
                ),
              };
            })}
            value={`${size.x},${size.y}`}
            onChange={(val) =>
              changeSize({ target: { name: "pool", value: val } })
            }
          />
        );
        break;
    }
  }

  return (
    <div>
      {allFormats && allFormats.length ? selector : <React.Fragment />}
      {configuration.canUseCustom ? (
        <CustomFormat
          addCustomFormat={addCustomFormat}
          configuration={configuration}
        />
      ) : (
        <React.Fragment />
      )}
    </div>
  );
}

export default Format;
