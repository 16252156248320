/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from "react";

import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";

import { Form } from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import filterFactory from "react-bootstrap-table2-filter";

import apiDriver from "stores/api.driver";
import { useTranslation } from "react-i18next";

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

export default function TabTable(props) {
  const { url, title, columns, id } = props;
  const { i18n } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(25);
  const [filters, setFilters] = useState(undefined);
  const [order, setOrder] = useState(undefined);
  const [searchText, setSearchText] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [totalSize, setTotalSize] = useState(0);

  const fetchData = () => {
    setLoading(true);
    apiDriver
      .get(
        url +
          apiDriver.buildIndexAttributes({
            skip: sizePerPage * (page - 1),
            take: sizePerPage,
            filters: filters,
            order: order,
            searchText: searchText,
          }),
      )
      .pipe(
        map((response) => {
          setData(response.response);
          setLoading(false);
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe(() => {});
  };

  useEffect(fetchData, []);
  useEffect(fetchData, [page, sizePerPage, filters, order, searchText, i18n?.resolvedLanguage]);

  const NoDataIndication = () => {
    const { t } = useTranslation();
    return (
      <div className="text-center p-5">
        <i className="fas fa-box-open fa-5x text-muted p-3 d-block"></i>
        <span className="text-muted">{t("common.noData")}</span>
      </div>
    );
  };

  const onTableChange = (type, newState) => {
    setPage(Math.max(newState.page, 1));
    setSizePerPage(newState.sizePerPage);
    setFilters(newState.filters);
    setOrder(
      newState.sortOrder && newState.sortField
        ? { direction: newState.sortOrder, field: newState.sortField }
        : undefined,
    );
    setSearchText(newState.searchText);
  };

  return (
    <div tabId={id}>
      <Form>
        <div>
          <header>
            <div className="clearfix">
              <div className="float-left">
                <h2>{title}</h2>
              </div>
            </div>
          </header>
          <hr className="line-primary"></hr>
          <br></br>
          <ToolkitProvider
            keyField="id"
            data={data}
            columns={columns}
            search
            exportCSV
          >
            {(props) => [
              <ExportCSVButton {...props.csvProps}>
                <i className="fas fa-download"></i>
              </ExportCSVButton>,
              <SearchBar delay={500} {...props.searchProps} />,
              <BootstrapTable
                {...props.baseProps}
                remote
                pagination={paginationFactory({ page, sizePerPage, totalSize })}
                filter={filterFactory()}
                onTableChange={onTableChange}
                noDataIndication={() => <NoDataIndication />}
                bordered={false}
                loading={loading}
                overlay={overlayFactory({ spinner: true })}
              />,
            ]}
          </ToolkitProvider>
        </div>
      </Form>
    </div>
  );
}
