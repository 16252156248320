import React, { useState, useEffect } from "react";

import {
  Spinner,
  Alert,
  Button,
  Card,
  FormGroup,
  Input,
  Row,
  Col,
  Form,
  CardBody,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { Countries } from "views/Auth/Register";
import apiDriver from "stores/api.driver";
import config from "config/global";
import { useTranslation } from "react-i18next";
import CountrySelector from "components/AddressForm/CountrySelector";
import Country from "components/AddressForm/Country";
import PostalCodeInput from "components/AddressForm/PostalCodeInput";
import FetchDataByVatNumberBanner from "./FetchDataByVatNumberBanner";

export const ShippingModal = (props) => {
  const { t } = useTranslation();
  const { title, initialData, modalOpen, setModalOpen, onSubmit, loading } =
    props;
  const [initialized, setInitialized] = useState(false);
  const [formData, setFormData] = useState({});

  const onSubmitForm = (event) => {
    event.preventDefault();
    onSubmit(formData);
  };

  const onFetchDataFromGus = (data) => {
    setFormData({ ...formData, ...data });
  };

  const onChangeFormData = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (!initialized) {
      let newFormData = { ...initialData };
      if (!newFormData.countryCode)
        newFormData.countryCode = Countries()[0].code;
      setFormData(newFormData);
      setInitialized(true);
    }
  }, [initialized, initialData]);

  return (
    <>
      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        size="xl"
        className="modal-dialog-centered"
      >
        <Form onSubmit={onSubmitForm}>
          <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
            {title}
          </ModalHeader>
          <ModalBody>
            <FetchDataByVatNumberBanner onFetchData={onFetchDataFromGus} />
            {loading ? (
              <div className="text-center">
                <Spinner />
              </div>
            ) : (
              <React.Fragment />
            )}
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <InputGroup>
                        <Input
                          type="text"
                          name="line1"
                          value={formData.line1}
                          onChange={onChangeFormData}
                          placeholder={t("customers.shippingAddress.line1")}
                          required
                          minLength={4}
                          maxLength={64}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <InputGroup>
                        <Input
                          type="text"
                          name="line2"
                          value={formData.line2}
                          onChange={onChangeFormData}
                          placeholder={t("customers.shippingAddress.line2")}
                          minLength={3}
                          maxLength={64}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <InputGroup>
                        <PostalCodeInput
                          countryCode={formData?.countryCode}
                          type="text"
                          name="postalCode"
                          value={formData.postalCode}
                          onChange={onChangeFormData}
                          required={true}
                          minLength={5}
                          maxLength={16}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md={8}>
                    <FormGroup>
                      <InputGroup>
                        <Input
                          type="text"
                          name="city"
                          value={formData.city}
                          onChange={onChangeFormData}
                          placeholder={t("customers.shippingAddress.city")}
                          required={true}
                          minLength={3}
                          maxLength={64}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <InputGroup>
                        <CountrySelector
                          value={formData.countryCode}
                          onChange={onChangeFormData}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Col md={12}>
                  <FormGroup>
                    <InputGroup>
                      <Input
                        type="text"
                        name="person"
                        value={formData.person}
                        onChange={onChangeFormData}
                        placeholder={t("customers.shippingAddress.person")}
                        minLength={3}
                        maxLength={64}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <InputGroup>
                      <Input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={onChangeFormData}
                        placeholder={t("customers.shippingAddress.phone")}
                        minLength={3}
                        maxLength={64}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <InputGroup>
                      <Input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={onChangeFormData}
                        placeholder={t("customers.shippingAddress.email")}
                        minLength={3}
                        maxLength={64}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="d-flex flex-row">
            <Button
              className="mr-auto"
              color="link"
              onClick={() => setModalOpen(!modalOpen)}
              type="button"
              disabled={loading}
            >
              {t("actions.close")}
            </Button>
            <Button
              type="reset"
              disabled={loading}
            >
              {t("actions.reset")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={loading}
            >
              {t("actions.save")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export const SenderAddress = (props) => {
  const { t, i18n } = useTranslation();
  const { address, onDelete, onUpdate, onMakeDefault, setError } = props;
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const setParentLoading = props.setLoading;

  const onClickDelete = async () => {
    setLoading(true);
    setParentLoading(true);
    setError();
    apiDriver
      .remove(`${config.api.orders}${i18n.resolvedLanguage}/SenderAddresses/${address.id}`,)
      .subscribe({
        next: () => {
          onDelete(address.id);
          setLoading(false);
          setParentLoading(false);
        },
        error: () => {
          setError(t('order.errors.deleteAssignedAddress'))
          setLoading(false);
          setParentLoading(false);
        }
      });
  };

  const onClickMakeDefault = async () => {
    setLoading(true);
    setParentLoading(true);
    setError();
    apiDriver
      .patch(
        `${config.api.orders}${i18n.resolvedLanguage}/SenderAddresses/${address.id}/MarkAsDefault`,
      )
      .subscribe({
        next: () => {
          onMakeDefault(address.id);
          setLoading(false);
          setParentLoading(false);
        },
        error: () => {
          setError(t('errors.common.title'))
          setLoading(false);
          setParentLoading(false);
        }
      });
  };

  const onClickEdit = () => {
    setModalOpen(true);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    setParentLoading(true);
    setError();
    apiDriver
      .patch(
        `${config.api.orders}${i18n.resolvedLanguage}/SenderAddresses/${address.id}`,
        { ...data },
      )
      .subscribe({
        next: (response) => {
          onUpdate(response.response);
          setLoading(false);
          setParentLoading(false);
        },
        error: () => {
          setError(t('errors.common.title'))
          setLoading(false);
          setParentLoading(false);
        }
      });
  };

  return (
    <Col lg="6">
      <ShippingModal
        title={t("customers.shippingAddress.edit")}
        initialData={address}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        onSubmit={onSubmit}
        loading={loading}
        setLoading={setLoading}
      />
      <Card>
        <CardBody>
          <div className="clearfix">
            <div className="float-left">
              <div>
                <b>{address.person}</b>
              </div>
            </div>
            <div className="float-right">
              <i
                className={`fas fa-star ${address.default ? "text-primary" : ""
                  }`}
                onClick={onClickMakeDefault}
              ></i>{" "}
              &nbsp;
              <i className="fas fa-edit" onClick={onClickEdit}></i> &nbsp;
              <i
                className="fas fa-trash text-danger"
                onClick={onClickDelete}
              ></i>
            </div>
          </div>
          <div>{address.line1}</div>
          {address.line2 ? <div>{address.line2}</div> : <React.Fragment />}
          <div>
            {address.postalCode} {address.city}
          </div>
          <div><Country countryCode={address.countryCode} /></div>
          {address.email ? <div>{address.email}</div> : <React.Fragment />}
          {address.phone ? <div>{address.phone}</div> : <React.Fragment />}
        </CardBody>
      </Card>
    </Col>
  );
}

const TabSenderAddresses = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState();

  const onMakeDefault = (id) => {
    setData(
      [...data].map((d) => {
        return { ...d, default: d.id === id };
      }),
    );
  };

  const onDelete = (id) => {
    setData([...data].filter((d) => d.id !== id));
  };

  const onUpdate = (updated) => {
    setData(
      [...data].map((d) => {
        if (d.id !== updated.id) return d;
        return updated;
      }),
    );
  };

  const onSubmit = async (d) => {
    setLoading(true);
    apiDriver
      .put(`${config.api.orders}${i18n.resolvedLanguage}/SenderAddresses/`, {
        ...d,
      })
      .subscribe((response) => {
        setData([...data, response.response]);
        setLoading(false);
        setModalOpen(false);
      });
  };

  useEffect(() => {
    if (!loaded) {
      setLoading(true);
      apiDriver
        .get(`${config.api.orders}${i18n.resolvedLanguage}/SenderAddresses` + apiDriver.buildIndexAttributes({ take: 1000 }))
        .subscribe((response) => {
          setData(response.response);
          setLoaded(true);
          setLoading(false);
        });
    }
  });

  return (
    <div tabId="shipping">
      <header>
        <h2 className="text-uppercase">{t("customers.senderAddress.senderAddresses")}</h2>
      </header>
      <hr className="line-primary"></hr>
      <br></br>
      {error && <Alert color="danger">{error}</Alert>}
      <Row>
        <Col md={12}>
          {loading && <div className="text-center"><Spinner /></div>}
        </Col>
      </Row>
      <Row>
        {data.map((d) => (
          <SenderAddress
            loading={loading}
            setLoading={setLoading}
            key={d.id}
            address={d}
            onMakeDefault={onMakeDefault}
            onDelete={onDelete}
            onUpdate={onUpdate}
            setError={setError}
          />
        ))}
        <Col lg="6">
          <Button block onClick={() => setModalOpen(true)}>
            <i className="fas fa-plus fa-5x text-muted m-3"></i>
            <br />
            <span className="text-muted">{t("actions.add")}</span>
          </Button>
          <ShippingModal
            title={t("customers.shippingAddress.add")}
            initialData={{}}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onSubmit={onSubmit}
            loading={loading}
            setLoading={setLoading}
          />
        </Col>
      </Row>
    </div>
  );
}

export default TabSenderAddresses;
