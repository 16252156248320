import Country from "components/AddressForm/Country";
import React from "react";
import { Row, Col, Input } from "reactstrap";

function Address(props) {
  const { input, address } = props;
  return (
    <Row>
      {input ? (
        <Col sm="2" md="1" xl="1">
          <Input type="radio" />
        </Col>
      ) : (
        <React.Fragment />
      )}
      <Col sm={input ? 10 : 12} md={input ? 11 : 12}>
        {address.person && <div><b>{address.person}</b></div>}
        {address.business && <div><b>{address.business}</b></div>}
        <div>{address.line1}</div>
        {address.line2 && <div>{address.line2}</div>}
        <div>
          {address.postalCode} {address.city}
        </div>
        <div><Country countryCode={address.countryCode} /></div>
        {address.email && <div>{address.email}</div>}
        {address.phone && <div>{address.phone}</div>}
      </Col>
    </Row>
  );
}

export default Address;
