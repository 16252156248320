import React from "react";
import Helmet from "react-helmet";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Badge,
} from "reactstrap";
import PageEmbed from "components/CMS/PageEmbed";
import { useTranslation } from "react-i18next";
import ContactForm from "./ContactForm";

function PartnerProgram() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('contact.partnership')} - Printweb.pl</title>
      </Helmet>
      <div className="bg-dark py-5">
        <Container>
          <Row className="my-5">
            <Col md="5">
              <PageEmbed id="369e32d6-8677-4697-afba-dbd36ae037eb" content />
            </Col>
            <Col className="m-auto my-5 py-5" md="12">
              <Card className="card-contact card-raised">
                <Row>
                  <Col className="pr-md-0" lg="8" md="7">
                    <CardHeader>
                      <CardTitle tag="h4">
                        Dołącz do programu partnerskiego
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <ContactForm subject={t('contact.partnership')} />
                    </CardBody>
                  </Col>
                  <Col className="mr-auto text-left" lg="4" md="8">
                    <div className="pr-md-5 mt-5">
                      <div className="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle mb-5">
                        <i className="fas fa-handshake"></i>
                      </div>
                      <h3>Warunki współpracy</h3>
                      <p>
                        Jeśli jesteś wydawcą, agencją reklamową, agencją
                        poligraficzną, to otrzymasz stały standardowy rabat na
                        wszystkie produkty w wysokości 20%.
                      </p>
                      <ul className="list-unstyled mt-5">
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <Badge
                                className="badge-circle mr-3"
                                color="primary"
                              >
                                <i className="ni ni-satisfied"></i>
                              </Badge>
                            </div>
                            <div>
                              <h6 className="mb-0">
                                Przy dłuższej współpracy mozemy zaproponowac
                                rabat nawet do 35%.
                              </h6>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default PartnerProgram;
