import React from 'react';
import CountryCodeDependentInput from './CountryCodeDependentInput';
import { useTranslation } from 'react-i18next';

export default function PostalCodeInput(props) {
    const { t } = useTranslation();
    return <CountryCodeDependentInput
                propertyOfPattern="postalCode"
                name={props.name || "postalCode"}
                placeholder={props.placeholder || t('customers.shippingAddress.postalCode')}
                {...props}
            />
} 