import NumericInput from "components/form/NumericInput";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from "reactstrap";

function CustomPricing(props) {
  const { t } = useTranslation();
  const { min, max, addCustomPricing } = props;

  const [value, setValue] = useState(undefined);

  const isValid = value >= min && value <= max;
  const isValidOrNotFilled = value === undefined || isValid;
  const isValidAndFilled = value > 0 && isValid;

  const onChange = (e) =>
    setValue(e.target.value ? Number(e.target.value) : undefined);
  const onClick = () => (isValidAndFilled ? addCustomPricing(value) : null);

  return (
    <Card className="mt-0 mb-3">
      <CardBody>
        <InputGroup>
          <NumericInput
            type="number"
            name="x"
            placeholder={0}
            min={min}
            max={max}
            step="1"
            value={value}
            onChange={onChange}
            className={!isValidOrNotFilled ? "is-invalid" : ""}
          />
          <InputGroupAddon addonType="append">
            <InputGroupText>{t("price.piece")}</InputGroupText>
          </InputGroupAddon>
          <Button
            color="primary"
            onClick={onClick}
            disabled={!isValidAndFilled}
          >
            {t("products.actions.addAmount")}
          </Button>
        </InputGroup>
      </CardBody>
    </Card>
  );
}

export default CustomPricing;
