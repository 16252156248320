import React from 'react';
import { useTranslation } from 'react-i18next';


export default function RealizationTerm({ term }) {
    const { i18n, t } = useTranslation();
    if (!term) {
        return t("common.none");
    }
    return <>
        {(new Date(term)).toLocaleString(i18n.resolvedLanguage, { day: 'numeric', month: 'long', weekday: 'short', year: "numeric" })},
        {" "}{t("common.at")}{" "}
        {(new Date(term)).toLocaleString(i18n.resolvedLanguage, { timeStyle: 'short' })}
    </>

}