/*eslint-disable*/
import PageEmbed from "components/CMS/PageEmbed";
import PageLink from "components/CMS/PageLink";
import React from "react";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import CookiesModal from "./CookiesModal";
import LocalizedLink from "components/LocalizedLink";

// Core Components

function Footer() {
  const { t } = useTranslation();
  return (
    <>
      <footer className="footer px-3 py-5">
        <CookiesModal />
        <Container fluid>
          <Row className="row-grid align-items-center mb-5">
            <Col lg="6">
              <h3 className="text-primary font-weight-light mb-2">
                {t("marketing.footer.line1")}
              </h3>
              <h4 className="mb-0 font-weight-light">
                {t("marketing.footer.line2")}
              </h4>
            </Col>
            <Col className="d-none d-xl-inline" xl={2}></Col>
            <Col className="btn-wrapper">
              <a href="mailto:info@printweb.pl" className="text-white">
                <Button
                  className="btn btn-block"
                  color="primary"
                  id="tooltip844497435"
                >
                  <span className="btn-inner--icon">
                    <i className="fas fa-envelope"></i> info@printweb.pl
                  </span>
                </Button>
              </a>
              <UncontrolledTooltip delay={0} target="tooltip844497435">
                {t("marketing.footer.mail")}
              </UncontrolledTooltip>
            </Col>
            <Col className="btn-wrapper">
              <a href="tel:893000044" className="text-white">
                <Button
                  className="btn btn-block"
                  color="primary"
                  id="tooltip46149983"
                >
                  <span className="btn-inner--icon">
                    <i className="fas fa-phone"></i> 89 300 00 44
                  </span>
                </Button>
              </a>
              <UncontrolledTooltip delay={0} target="tooltip46149983">
                {t("marketing.footer.call")}
              </UncontrolledTooltip>
            </Col>
          </Row>
          <hr></hr>
          <Row className="align-items-center justify-content-md-between">
            <Col md="4">
              <div className="copyright">
                © {(new Date()).getFullYear()} <a>printweb.pl</a>.
              </div>
            </Col>
            <Col md="8">
              <Nav className="nav-footer justify-content-end">
                <NavItem>
                  <PageLink
                    id="60856b21-cd78-4048-b336-feb9c3bc9398"
                    linkProps={{ className: "nav-link" }}
                  />
                </NavItem>
                <NavItem>
                  <PageLink
                    id="fcb4329d-4a54-4176-9fb8-51422614ec29"
                    linkProps={{ className: "nav-link" }}
                  />
                </NavItem>
                <NavItem>
                  <LocalizedLink className="nav-link" to="/contact/custom-calculation">
                    <PageEmbed
                      id="ffacdbac-0f42-420f-bc65-4f45753ce140"
                      titlePlain
                    />
                  </LocalizedLink>
                </NavItem>
                <NavItem>
                  <LocalizedLink className="nav-link" to="/contact/partner-program">
                    <PageEmbed
                      id="369e32d6-8677-4697-afba-dbd36ae037eb"
                      titlePlain
                    />
                  </LocalizedLink>
                </NavItem>
                <NavItem>
                  <PageLink
                    id="89a8869c-c869-43ad-998a-1bcc18a66b67"
                    linkProps={{ className: "nav-link" }}
                  />
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer >
    </>
  );
}

export default Footer;
