import React from "react";

// reactstrap components
import { CardTitle, Row } from "reactstrap";
import Product from "./Product";

// Core Components

function Category(props) {
  const { category, products } = props;
  let currentTranslation = category.translations[0];
  if (products.length === 0) {
    return <React.Fragment />;
  }
  return (
    <React.Fragment>
      <CardTitle tag="h3" className="mt-0 mt-md-3 pt-0 pt-md-3">{currentTranslation?.title}</CardTitle>
      <Row className="align-items-center">
        {products.map((product) => (
          <Product key={product.id} product={product}></Product>
        ))}
      </Row>
    </React.Fragment>
  );
}

export default Category;
