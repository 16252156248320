import CustomizationBadges from "components/Products/Product/CustomizationBadges";
import React, { useState, useEffect } from "react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Input,
  Table,
  ButtonGroup,
  ModalFooter,
  Spinner,
} from "reactstrap";
import NotesModal from "./NotesModal";
import PaginationComponent from "./PaginationComponent";

import apiDriver from "stores/api.driver";
import config from "config/global";

import { FileSize } from "views/Cart/Uploader";
import { useTranslation } from "react-i18next";

function ProofMaterial(props) {
  const { material } = props;

  const name = material.objectName;
  const tmp = name.split(".");
  const extension = tmp[tmp.length - 1];

  return (
    <tr>
      <td>
        <a href="/" target="_blank">
          {name}
        </a>
      </td>
      <td>{extension.toUpperCase()}</td>
      <td>
        <FileSize value={material.stat.size} />
      </td>
      <td>{material.stat.lastModified.substr(0, 10)}</td>
    </tr>
  );
}

function ProofMaterials(props) {
  const { t } = useTranslation();
  const { materials } = props;

  if (!materials || !materials.length) {
    return <Spinner />;
  }

  return (
    <>
      <h6>{t("order.product.clientFiles")}</h6>
      <Table borderless size="sm" className="w-100">
        <thead>
          <tr>
            <th>{t("common.name")}</th>
            <th>{t("common.type")}</th>
            <th>{t("common.size")}</th>
            <th>{t("common.date")}</th>
          </tr>
        </thead>
        <tbody>
          {materials.map((material) => (
            <ProofMaterial key={material.objectName} material={material} />
          ))}
        </tbody>
      </Table>
    </>
  );
}

function ProofModal(props) {
  const { t, i18n } = useTranslation();

  const {
    orderProduct,
    file,
    order,
    title,
    buttonTitle,
    buttonProps,
    entityType,
    callback
  } = props;
  const product = orderProduct.product;
  const productTranslation = product ? product.translations[0] : null;
  const orderProductNotes = order.notes
    ? [...order.notes.filter((n) => n.productId === orderProduct.id)]
    : [];

  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [review, setReview] = useState("");
  const [page, setPage] = useState(1);
  const [previews, setPreviews] = useState();

  const onClickAccept = () => {
    setLoading(true);
    apiDriver
      .post(
        `${config.api.orders}${i18n.resolvedLanguage}/OrderProducts/${orderProduct.id}/OrderProduct${entityType}s/${file.objectName}/Review`,
        {
          accepted: true,
          note: "",
        },
      )
      .subscribe({
        next: onResponse,
        error: console.error,
        complete: () => setLoading(false)
      });
  };

  const onClickDecline = () => {
    setLoading(true);
    apiDriver
      .post(
        `${config.api.orders}${i18n.resolvedLanguage}/OrderProducts/${orderProduct.id}/OrderProduct${entityType}s/${file.objectName}/Review`,
        {
          accepted: false,
          note: review,
        },
      )
      .subscribe({
        next: onResponse,
        error: console.error,
        complete: () => setLoading(false)
      })
  };

  const onResponse = () => {
    setModalOpen(false);
    if(callback) {
      setTimeout(callback(), 1000);
    }
  }

  const onLoadPreviews = (response) => {
    setPreviews(response.response);
  };

  const loadPreviews = () => {
    setLoading(true);
    apiDriver
      .get(
        `${config.api.orders}${i18n.resolvedLanguage}/OrderProducts/${orderProduct.id}/OrderProduct${entityType}s/${file.objectName}/Preview?Take=10`,
      )
      .subscribe({
        next: onLoadPreviews,
        error: console.error,
        complete: () => setLoading(false)
      });
  };

  useEffect(() => {
    if (modalOpen) {
      loadPreviews();
    }
  }, [modalOpen]);

  return (
    <>
      <Button
        type="button"
        onClick={() => setModalOpen(!modalOpen)}
        {...buttonProps}
      >
        <i className="fas fa-bars"></i>{" "}
        {buttonTitle || t("order.product.proof")}
      </Button>
      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        className="modal-dialog-centered"
        size="xl"
      >
        <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
          {title || t("order.product.proof")} {orderProduct.number}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md="8">
              <h6>
                {orderProduct.number} &nbsp;{" "}
                {productTranslation?.title || orderProduct?.title || t('common.untitled')}
              </h6>
              <small className="text-muted">
                <CustomizationBadges orderProduct={orderProduct} />
              </small>
            </Col>
            <Col md="4" className="text-center">
              <h6>
                {t("order.order")} {order.number}
              </h6>
              <NotesModal
                title={`${orderProduct.number || orderProduct.id}`}
                notes={orderProductNotes}
                buttonProps={{ size: "sm", color: "default", outline: true }}
                order={order}
              />
            </Col>
          </Row>
          <hr className="w-100" />
          <Row>
            <Col md="8" className="mb-3 px-5 text-center">
              {previews && previews.length > 0 ? (
                <img
                  src={previews[page - 1]}
                  alt="Preview"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              ) : (
                <React.Fragment />
              )}
            </Col>
            <Col md="4" className="text-left">
              <h6>{t("common.notes")}</h6>
              {orderProduct.status.isUserAttentionRequired ? (
                <>
                  <Input
                    type="textarea"
                    rows="8"
                    value={review}
                    onChange={(e) => setReview(e.target.value)}
                  ></Input>
                </>
              ) : (
                <p>{review}</p>
              )}
              {orderProduct.personalization &&
              orderProduct.personalization.project ? (
                <ProofMaterials materials={orderProduct.materials} />
              ) : (
                <React.Fragment />
              )}
            </Col>
          </Row>
          <Row>
            <Col md="4">
              {previews && previews.length ? (
                <PaginationComponent
                  offset={3}
                  page={page}
                  pages={previews.length}
                  onChange={setPage}
                />
              ) : (
                <React.Fragment />
              )}
            </Col>
            <Col md="4" className="text-center">
              {orderProduct.personalization && file ? (
                <Button type="button" color="primary">
                  <i className="fas fa-download"></i> &nbsp;{" "}
                  {t("actions.download")} (<FileSize value={file.stat.size} />)
                </Button>
              ) : (
                <React.Fragment />
              )}
            </Col>
            <Col md="4" className="text-center">
              {orderProduct.status.isUserAttentionRequired ? (
                <ButtonGroup>
                  <Button
                    type="button"
                    color="success"
                    onClick={onClickAccept}
                    disabled={isLoading}
                  >
                    <i className="fas fa-check-circle"></i>{" "}
                    {t("actions.accept")}
                    {isLoading ? <Spinner /> : <></>}
                  </Button>
                  <Button
                    type="button"
                    color="danger"
                    onClick={onClickDecline}
                    disabled={isLoading}
                  >
                    <i className="fas fa-times-circle"></i>{" "}
                    {t("actions.decline")}
                    {isLoading ? <Spinner /> : <></>}
                  </Button>
                </ButtonGroup>
              ) : file.accepted !== null && file.accepted !== undefined ? (
                file.accepted === true ? (
                  <h4 className="text-center text-success">
                    {t("common.accepted")}
                  </h4>
                ) : (
                  <h4 className="text-center text-danger">
                    {t("common.declined")}
                  </h4>
                )
              ) : (
                <React.Fragment />
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            className="ml-auto"
            color="link"
            onClick={() => setModalOpen(!modalOpen)}
            type="button"
          >
            {t("actions.close")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ProofModal;
