import React from "react";
import { useTranslation } from "react-i18next";

function RealizationTime(props) {
  const { i18n } = useTranslation();
  const { time } = props;
  if (time <= 8) {
    return (
      <span>
        {Intl.NumberFormat(i18n.resolvedLanguage, {
          style: "unit",
          unit: "day",
          unitDisplay: "long"
        }).format(1)}
      </span>
    );
  }
  return (
    <span>
      {Intl.NumberFormat(i18n.resolvedLanguage, {
        style: "unit",
        unit: "day",
        unitDisplay: "long"
      }).format(Math.ceil(time / 8))}
    </span>
  );
}

export default RealizationTime;
