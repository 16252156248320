import React from "react";

function ShippingLabel(props) {
  const { shipping } = props;
  return (
    <div>
      {shipping.trackingNumber} &nbsp;
      {shipping.status.isClosed ? (
        <i className="fas fa-check-circle text-success"></i>
      ) : (
        <React.Fragment />
      )}
      {shipping.status.isProcessing ? (
        shipping.isShippingAddressRequired ? (
          <i className="fas fa-truck text-primary"></i>
        ) : (
          <i className="fas fa-box text-primary"></i>
        )
      ) : (
        <React.Fragment />
      )}
    </div>
  );
}

export default ShippingLabel;
