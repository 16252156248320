import { useTranslation } from 'react-i18next';
import Countries from './Countries';

export default function Country({ countryCode }) {
    const { i18n } = useTranslation();

    if (!countryCode) {
        return '';
    }

    const countryNameRegionalizer = new Intl.DisplayNames([i18n.resolvedLanguage], { type: 'region' });
    try {
        return countryNameRegionalizer?.of(countryCode.toUpperCase());
    }
    catch (exception) {
        return Countries.find(country => country.code === countryCode)?.label;
    }
} 