/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import {
  Alert,
  Row,
  Col,
  Container,
  Button,
  CardHeader,
  CardBody,
  Card,
  Spinner,
  Form,
} from "reactstrap";

import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { useSelector, useDispatch } from "react-redux";

import { Redirect, useLocation } from "react-router-dom";
import Helmet from "react-helmet";
import WizardHead from "./WizardHead";
import CartProducts from "./CartProducts";
import ShippingAndCollect from "./ShippingAndCollect";
import CartBilling from "./CartBilling";
import CartSummary from "./CartSummary";
import apiDriver from "stores/api.driver";
import config from "config/global";

import * as cartActions from "stores/cart/actions";
import { useTranslation } from "react-i18next";
import useUser from "hooks/useUser";

const OrderProductLoadedState = {
  NotLoaded: 0,
  Loaded: 1,
  WithFiles: 2,
};

function CartButtons(props) {
  const { t } = useTranslation();
  const { validate, onClickLogin, step } = props;

  const user = useUser();

  if (step === 1) {
    if (user) {
      return (
        <React.Fragment>
          <Col md="6" lg="4" className="offset-lg-5 offset-xl-6">
            <Button color="primary" block={true} disabled={!validate()} size="lg">
              {t("actions.next")}
            </Button>
          </Col>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Col md="4" lg="3" xl="2" className="offset-lg-3 offset-xl-6">
            <Button
              color="primary"
              block={true}
              disabled={!validate()}
              onClick={onClickLogin}
              size="lg"
            >
              {t("auth.login.do")}
            </Button>
          </Col>
          <Col md="4" lg="3" xl="2">
            <Button color="primary" block={true} disabled={!validate()} size="lg">
              {t("cart.actions.orderAsGuest")}
            </Button>
          </Col>
        </React.Fragment>
      );
    }
  }

  if (step < 4) {
    return (
      <React.Fragment>
        <Col md="6" lg="4" className="offset-lg-5 offset-xl-6">
          <Button color="primary" block={true} disabled={!validate()} size="lg">
            {t("actions.next")}
          </Button>
        </Col>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Col md="6" lg="4" className="offset-lg-5 offset-xl-6">
        <Button color="primary" block={true} disabled={!validate()} size="lg">
          {t("cart.actions.order")}
        </Button>
      </Col>
    </React.Fragment>
  );
}

function CartBody(props) {
  const { t } = useTranslation();
  const {
    cart,
    updateCart,
    submitCart,
    step,
    setStep,
    products,
    updateOrderProduct,
    deleteOrderProduct,
    refreshOrderProduct,
    shippingAddress,
    setShippingAddress,
    setShippingAddressData,
    senderAddress,
    setSenderAddress,
    setSenderAddressData,
    shippingMethod,
    setShippingMethod,
    collectPoint,
    setCollectPoint,
    billingInfo,
    setBillingInfo,
    setBillingInfoData,
    invoice,
    setInvoice,
    paymentMethod,
    setPaymentMethod,
    setCartEmail,
    setCartPhone,
    validate,
  } = props;

  const [redirect, setRedirect] = useState();

  const onClickSubmit = (event) => {
    event.preventDefault();
    if (step < 4) {
      const newStep = step + 1;
      updateCart(() => setStep(newStep));
    } else {
      submitCart();
    }
  };

  const onClickBack = (event) => {
    if (step > 1) {
      const newStep = step - 1;
      setStep(newStep);
    }
  }

  const onClickGoToStore = (event) => {
    event.preventDefault();
    setRedirect('/');
  }

  const onClickLogin = (event) => {
    event.preventDefault();
    setRedirect('/login');
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <React.Fragment>
      <WizardHead step={step} setStep={setStep} />
      {step === 1 ? (
        <CartProducts
          products={products}
          updateOrderProduct={updateOrderProduct}
          deleteOrderProduct={deleteOrderProduct}
          refreshOrderProduct={refreshOrderProduct}
        />
      ) : (
        <React.Fragment />
      )}
      {step === 2 ? (
        <ShippingAndCollect
          cart={cart}
          shippingAddress={shippingAddress}
          setShippingAddress={setShippingAddress}
          setShippingAddressData={setShippingAddressData}
          setSenderAddress={setSenderAddress}
          setSenderAddressData={setSenderAddressData}
          shippingMethod={shippingMethod}
          setShippingMethod={setShippingMethod}
          collectPoint={collectPoint}
          setCollectPoint={setCollectPoint}
        />
      ) : (
        <React.Fragment />
      )}
      {step === 3 ? (
        <CartBilling
          cart={cart}
          billingInfo={billingInfo}
          setBillingInfo={setBillingInfo}
          setBillingInfoData={setBillingInfoData}
          invoice={invoice}
          setInvoice={setInvoice}
          selectedMethod={paymentMethod}
          setMethod={setPaymentMethod}
          shippingMethod={shippingMethod}
        />
      ) : (
        <React.Fragment />
      )}
      {step === 4 &&
        <CartSummary
          cart={cart}
          products={products}
          senderAddress={senderAddress}
          shippingAddress={shippingAddress}
          shippingMethod={shippingMethod}
          paymentMethod={paymentMethod}
          billingInfo={billingInfo}
          invoice={invoice}
          setCartEmail={setCartEmail}
          setCartPhone={setCartPhone}
        />
      }
      <Form onSubmit={onClickSubmit}>
        <Row>
          <Col md="4" lg="3" xl="2">
            {step === 1 && <Button color="secondary" block={true} onClick={onClickGoToStore} size="lg">
              {t("cart.actions.backToShop")}
            </Button>}
            {step > 1 && <Button color="secondary" block={true} onClick={onClickBack} size="lg">
              {t('common.back')}
            </Button>}
          </Col>
          <CartButtons
            validate={validate}
            onClickSubmit={onClickSubmit}
            step={step}
            onClickLogin={onClickLogin}
          />
        </Row>
      </Form>
    </React.Fragment>
  );
}

function CartWrapper(props) {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("cart.cart")} - Printweb.pl</title>
      </Helmet>
      <Container fluid className="px-1 px-lg-4 pb-1 pb-lg-5">
        <h1 className="text-uppercase">{t("cart.cart")}</h1>
        {props.children}
      </Container>
    </>
  );
}

function Cart() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const search = location.search;
  const searchParams = new URLSearchParams(search);

  const [step, setStep] = useState(
    searchParams.get("step") ? Number(searchParams.get("step")) : 1,
  );
  const [redirect, setRedirect] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [statusCode, setStatusCode] = useState(0);
  const [invoice, setInvoice] = useState(false);
  const [currentCallback, setCurrentCalback] = useState(null);
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState(undefined);
  const [updating, setUpdating] = useState(false);
  const reduxCart = useSelector((state) => state.cart);
  const user = useUser();

  const dispatch = useDispatch();

  const fetchCart = () => {
    dispatch(cartActions.get());
  };

  const prepareDto = () => {
    let {
      shippingMethod,
      paymentMethod,

      collectPointId,
      shippingAddressId,
      billingInfoId,
      senderAddressId,

      collectPoint,
      shippingAddress,
      billingInfo,
      senderAddress,

      shippings,
      notes,
      payments,
      user,
      assignee,
      creator,
      guest,
      products,
      ...dto
    } = { ...cart };

    if (cart.shippingAddress) {
      if (cart.shippingAddress?.id === "new") {
        dto.shippingAddress = { ...cart.shippingAddress };
        delete dto.shippingAddress.id;
      } else {
        dto.shippingAddressId = cart.shippingAddress?.id;
      }
    }
    if (cart.senderAddress) {
      if (cart.senderAddress.isSystem === true) {
        delete dto.senderAddress;
        delete dto.senderAddressId;
      } else {
        if (cart.senderAddress?.id === "new") {
          dto.senderAddress = { ...cart.senderAddress };
          delete dto.senderAddress.id;
        } else {
          dto.senderAddressId = cart.senderAddress?.id;
        }
      }
    }
    if (cart.billingInfo) {
      if (cart.billingInfo?.id === "new") {
        dto.billingInfo = { ...cart.billingInfo };
        delete dto.billingInfo.id;
      } else {
        dto.billingInfoId = cart.billingInfo.id;
      }
    }

    if (cart.shippingMethod) {
      dto.shippingMethodId = cart.shippingMethod.id;
    }
    if (cart.paymentMethod) {
      dto.paymentMethodId = cart.paymentMethod.id;
    }

    dto.culture = i18n.resolvedLanguage || "pl";

    return dto;
  }

  const submitCart = (callback) => {
    const dto = prepareDto();
    dispatch(cartActions.submit(dto));
    setUpdating(true);
    setCurrentCalback(callback);
  };

  const updateCart = (callback) => {
    const dto = prepareDto();
    dispatch(cartActions.update(dto));
    setUpdating(true);
    setCurrentCalback(callback);
  };

  const onFetchCart = () => {
    if (!reduxCart || !reduxCart.products) {
      if (cart?.id) return setRedirect("/panel#current");
      else return setRedirect("/");
    }
    if (products?.length !== reduxCart?.products?.length) {
      setProducts(
        reduxCart.products.map((p) => ({
          ...p,
          loaded: OrderProductLoadedState.NotLoaded,
        })),
      );
    }
    setInvoice(reduxCart.billingInfo !== null);
    if (reduxCart) {
      setCart({ ...reduxCart, loading: false });
    }
    setUpdating(false);
    if (reduxCart.status && reduxCart.status.tag !== 0) {
      if (user) {
        dispatch(cartActions.clear());
        setRedirect("/panel#current");
      } else {
        const cartId = reduxCart.id;
        dispatch(cartActions.clear());
        setRedirect(`/orders/${cartId}`);
      }
    }
  };

  const loadOrderProductFiles = (orderProduct) => {
    let filesType = "";
    let filesUrl = "";
    if (orderProduct?.personalization?.project === true) {
      filesType = "materials";
      filesUrl = `${config.api.orders}${i18n.resolvedLanguage}/OrderProducts/${orderProduct.id}/OrderProductMaterials/`;
    } else {
      filesType = "projects";
      filesUrl = `${config.api.orders}${i18n.resolvedLanguage}/OrderProducts/${orderProduct.id}/OrderProductProjects/`;
    }

    apiDriver
      .get(filesUrl)
      .pipe(
        map((response) => {
          onLoadOrderProductFiles(
            orderProduct.id,
            filesType,
            response.response,
          );
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe(() => { });
  };

  const onLoadOrderProductFiles = (id, type, files) => {
    setProducts(
      [...products].map((op) =>
        op.id !== id
          ? op
          : {
            ...op,
            [type]: [...files],
            loaded: OrderProductLoadedState.WithFiles,
          },
      ),
    );
  };

  const onLoadOrderProduct = (orderProduct) => {
    if (products.some((p) => p.id === orderProduct.id)) {
      setProducts(
        [...products].map((p) => {
          if (p.id !== orderProduct.id) return p;
          return { ...orderProduct, loaded: OrderProductLoadedState.Loaded };
        }),
      );
    } else {
      setProducts([
        ...products,
        { ...orderProduct, loaded: OrderProductLoadedState.Loaded },
      ]);
    }
  };

  const refreshOrderProduct = (orderProductId) => {
    setProducts(
      products.map((p) => {
        if (p.id !== orderProductId) return p;
        return { ...p, loaded: OrderProductLoadedState.NotLoaded };
      }),
    );
  };

  const loadOrderProduct = async (orderProduct) => {
    apiDriver
      .get(
        `${config.api.orders}${i18n.resolvedLanguage}/OrderProducts/${orderProduct.id}`,
      )
      .pipe(
        map((response) => {
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => {
        onLoadOrderProduct(data);
      });
  };

  const deleteOrderProduct = (orderProductId) => {
    apiDriver
      .remove(
        `${config.api.orders}${i18n.resolvedLanguage}/OrderProducts/${orderProductId}`,
      )
      .pipe(
        map(() => {
          setProducts([...products].filter((p) => p.id !== orderProductId));
          fetchCart();
          if (products.length === 1) {
            setRedirect('/');
          }
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe(() => { });
  };

  const setShippingAddress = (value) =>
    setCart({ ...cart, shippingAddress: value });

  const setShippingAddressData = (name, value) =>
    setCart({ ...cart, shippingAddress: { ...cart.shippingAddress, [name]: value, id: 'new' } });

  const setSenderAddress = (value) =>
    setCart({ ...cart, senderAddress: value });

  const setSenderAddressData = (name, value) =>
    setCart({ ...cart, senderAddress: { ...cart.senderAddress, [name]: value, id: 'new' } });

  const setBillingInfo = (value) =>
    setCart({ ...cart, billingInfo: value });

  const setBillingInfoData = (name, value) =>
    setCart({ ...cart, billingInfo: { ...cart.billingInfo, [name]: value, id: 'new' } });

  const setCollectPoint = (value) =>
    setCart({ ...cart, collectPoint: value });

  const setShippingMethod = (value) => {
    if (value.isShippingAddressRequired) {
      setCart({ ...cart, shippingMethod: value, collectPoint: null });
    } else {
      setCart({ ...cart, shippingMethod: value, shippingAddress: null, senderAddress: null });
    }
  }

  const setPaymentMethod = (value) =>
    setCart({ ...cart, paymentMethod: value });

  const updateOrderProduct = (orderProductId, value) => {
    setProducts([
      ...products.map((p) => {
        if (orderProductId !== p.id) return p;
        return { ...value };
      }),
    ]);
  };

  const setCartEmail = (value) =>
    setCart({ ...cart, email: value });

  const setCartPhone = (value) =>
    setCart({ ...cart, phone: value });

  const validate = () => {
    switch (step) {
      case 1:
        return (
          products.length > 0 &&
          products.filter(
            (p) =>
              p.projects &&
              p.projects.length === 0 &&
              p.materials &&
              p.materials.length === 0,
          ).length === 0
        );
      case 2:
        return (
          cart.shippingMethod &&
          ((cart.shippingMethod.isShippingAddressRequired &&
            cart.shippingAddress?.line1 &&
            cart.shippingAddress?.countryCode &&
            cart.shippingAddress?.postalCode &&
            cart.shippingAddress?.phone &&
            cart.shippingAddress?.city) ||
            (!cart.shippingMethod.isShippingAddressRequired &&
              cart.collectPoint)) &&
          (!cart.shippingMethod.isSenderAddressAvailable || ((cart.senderAddress &&
            cart.senderAddress?.line1 &&
            cart.senderAddress?.countryCode &&
            cart.senderAddress?.postalCode &&
            cart.senderAddress?.phone &&
            cart.senderAddress?.city) || cart.senderAddress?.id === undefined))
        );
      case 3:
        return (
          ((invoice &&
            (
              (cart.billingInfo &&
                cart.billingInfo.line1 &&
                cart.billingInfo.countryCode &&
                cart.billingInfo.city &&
                cart.billingInfo.postalCode &&
                cart.billingInfo.buyerName &&
                cart.billingInfo.vatID))) ||
            !invoice) &&
          cart.paymentMethod
        );
      case 4:
        return (
          cart.userId ||
          (cart.phone &&
            cart.email &&
            String(cart.email)
              .toLowerCase()
              .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              ))
        );
      default:
        return true;
    }
  };

  useEffect(() => {
    if (!reduxCart?.loading) {
      if (reduxCart?.error) {
        setRedirect(cart?.id ? "/panel#current" : "/");
      } else if (!reduxCart?.id) {
        if (cart === undefined) {
          setCart({});
          fetchCart();
        }
        else setRedirect("/");
      } else {
        onFetchCart();
      }
    }
  }, [reduxCart]);

  useEffect(() => {
    if (updating && !reduxCart.loading) {
      if (currentCallback) {
        currentCallback();
        setCurrentCalback(null);
      }
    }
  }, [updating, reduxCart?.loading]);

  useEffect(() => {
    if (!products || !products.length) {
      return;
    }
    const notLoadedProducts = products.filter(
      (p) => p.loaded !== OrderProductLoadedState.WithFiles,
    );
    if (notLoadedProducts.length) {
      const p = notLoadedProducts[0];
      switch (p.loaded) {
        case OrderProductLoadedState.NotLoaded:
          loadOrderProduct(p);
          break;
        case OrderProductLoadedState.Loaded:
          loadOrderProductFiles(p);
          break;
        default:
          break;
      }
    }
  }, [products]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (statusCode === 404) {
    return (
      <CartWrapper>
        <Alert color="warning">
          <p>
            <b>{t("errors.common.header")}</b>
          </p>
          <p>{t("cart.errors.noCart")}</p>
        </Alert>
      </CartWrapper>
    );
  }

  if (
    updating ||
    !cart?.id ||
    (cart.status && cart.status.tag !== 0) ||
    !products ||
    !products.length ||
    products.some((p) => p.loaded < OrderProductLoadedState.Loaded)
  ) {
    return (
      <CartWrapper>
        <div className="text-center">
          <Spinner />
        </div>
      </CartWrapper>
    );
  }

  return (
    <CartWrapper>
      <CartBody

        cart={cart}
        updateCart={updateCart}
        submitCart={submitCart}

        step={step}
        setStep={setStep}

        products={products}
        refreshOrderProduct={refreshOrderProduct}
        updateOrderProduct={updateOrderProduct}
        deleteOrderProduct={deleteOrderProduct}


        shippingAddress={cart.shippingAddress}
        setShippingAddress={setShippingAddress}
        setShippingAddressData={setShippingAddressData}

        senderAddress={cart.senderAddress}
        setSenderAddress={setSenderAddress}
        setSenderAddressData={setSenderAddressData}

        shippingMethod={cart.shippingMethod}
        setShippingMethod={setShippingMethod}

        collectPoint={cart.collectPoint}
        setCollectPoint={setCollectPoint}

        billingInfo={cart.billingInfo}
        setBillingInfo={setBillingInfo}
        setBillingInfoData={setBillingInfoData}

        invoice={invoice}
        setInvoice={setInvoice}

        paymentMethod={cart.paymentMethod}
        setPaymentMethod={setPaymentMethod}

        setCartEmail={setCartEmail}
        setCartPhone={setCartPhone}
        validate={validate}

      />
    </CartWrapper>
  );
}

export default Cart;
