import React from "react";

import { Row, Col, Spinner } from "reactstrap";

import CollectPoint from "./CollectPoint";
import { useTranslation } from "react-i18next";

function SelectCollectPoint(props) {
  const { t } = useTranslation();
  const { points, selectedPoint, setPoint } = props;
  if (points === null) {
    return (
      <Col md="9">
        <div className="text-center">
          <Spinner />
        </div>
      </Col>
    );
  }
  if (points.length === 1) {
    return (
      <CollectPoint
        point={points[0]}
        selectedPoint={selectedPoint}
        setPoint={setPoint}
      />
    );
  }
  return (
    <Col md="9">
      <b>{t("order.actions.selectCollectPoint")}</b>
      <Row>
        {points.map((point) => (
          <CollectPoint
            key={point.id}
            point={point}
            selectedPoint={selectedPoint}
            setPoint={setPoint}
          />
        ))}
      </Row>
    </Col>
  );
}
export default SelectCollectPoint;
