import React, { useState, useEffect } from "react";

import { Col, Row, Spinner } from "reactstrap";

import ShippingMethod from "./ShippingMethod";
import AddressSelector from "./AddressSelector";
import apiDriver from "stores/api.driver";
import config from "config/global";
import ShippingAddressForm from "./ShippingAddressForm";
import { useTranslation } from "react-i18next";

function ShippingAndCollect(props) {
  const { t, i18n } = useTranslation();
  const {
    cart,
    shippingAddress,
    setShippingAddress,
    setShippingAddressData,
    setSenderAddress,
    shippingMethod,
    setShippingMethod,
    collectPoint,
    setCollectPoint,
  } = props;

  const defaultSenderAddress = {
    id: undefined,
    person: t('customers.senderAddress.default'),
    line1: 'Ostrzeszewo 19E',
    postalCode: '10-687',
    city: 'Olsztyn',
    email: 'info@printweb.pl',
    phone: '+48 893000044',
    isSystem: true
  }

  const [userAddresses, setUserAddresses] = useState(null);
  const [userSenderAddresses, setUserSenderAddresses] = useState(null);
  const [shippingMethods, setShippingMethods] = useState(null);
  const [collectMethods, setCollectMethods] = useState(null);

  const [areShippingMethodsLoading, setShippingMethodsLoading] = useState(false);
  const [areCollectMethodsLoading, setCollectMethodsLoading] = useState(false);
  const [areUserAddressesLoading, setUserAddressesLoading] = useState(false);
  const [areUserSenderAddressesLoading, setUserSenderAddressesLoading] = useState(false);

  const loadShippingMethods = () => {
    setShippingMethodsLoading(true);
    apiDriver
      .get(`${config.api.orders}${i18n.resolvedLanguage}/ShippingMethods/?ForCart=true`,)
      .subscribe({
        next: (response) => setShippingMethods(response.response),
        error: alert,
        complete: () => setShippingMethodsLoading(false)
      });
  };

  const loadCollectMethods = () => {
    setCollectMethodsLoading(true);
    apiDriver
      .get(`${config.api.orders}${i18n.resolvedLanguage}/ShippingMethods/?ForCart=true`)
      .subscribe({
        next: (response) => setCollectMethods(response.response),
        error: alert,
        complete: () => setCollectMethodsLoading(false)
      });
  };

  const loadUserAddresses = (searchTerm) => {
    setUserAddressesLoading(true);
    apiDriver
      .get(`${config.api.orders}${i18n.resolvedLanguage}/ShippingAddresses${apiDriver.buildIndexAttributes({ take: 4, searchText: searchTerm })}`)
      .subscribe({
        next: (response) => {
          const addresses = [...response.response];
          if (cart.shippingAddress && !addresses.some(a => a.id === cart.shippingAddress.id)) {
            addresses.push(cart.shippingAddress);
          }
          setUserAddresses(addresses);
        },
        error: () => setUserAddresses([]),
        complete: () => setUserAddressesLoading(false)
      });
  };

  const loadUserSenderAddresses = (searchTerm) => {
    setUserSenderAddressesLoading(true);
    apiDriver
      .get(`${config.api.orders}${i18n.resolvedLanguage}/SenderAddresses${apiDriver.buildIndexAttributes({ take: 4, searchText: searchTerm })}`)
      .subscribe({
        next: (response) => {
          const addresses = [defaultSenderAddress, ...response.response];
          if (cart.senderAddress && !addresses.some(a => a.id === cart.senderAddress.id)) {
            addresses.push(cart.senderAddress);
          }
          setUserSenderAddresses(addresses);
        },
        error: () => setUserSenderAddresses([defaultSenderAddress]),
        complete: () => setUserSenderAddressesLoading(false)
      });
  };

  useEffect(() => {
    if (!cart.userId && !cart.shippingAddress) {
      setShippingAddress({ id: 'new' });
      setSenderAddress(defaultSenderAddress);
    }

    if (userAddresses === null) {
      if (cart.userId) {
        loadUserAddresses();
        loadUserSenderAddresses();
      } else {
        setUserAddresses([]);
        setUserSenderAddresses([defaultSenderAddress]);
      }
    }

    loadShippingMethods();
    loadCollectMethods();
  }, [cart?.userId]);

  const addUserAddress = (data) =>
    setUserAddresses([...userAddresses, data]);

  const addUserSenderAddress = (data) =>
    setUserSenderAddresses([...userSenderAddresses, data]);

  if (!userAddresses) {
    return (
      <div className="text-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <h2 className="text-uppercase">{t("order.actions.selectShippingMethod")}</h2>
      <Row>
        <Col md={4}>
          <h3>{t("order.delivery")}</h3>
          <h5 className="display-5">{t("order.delivery")} {areShippingMethodsLoading && <Spinner />}</h5>
          {shippingMethods !== null &&
            shippingMethods
              .filter((m) => m.isShippingAddressRequired)
              .map((m) => (
                <ShippingMethod
                  key={m.id}
                  method={m}
                  selectedMethod={shippingMethod}
                  setMethod={setShippingMethod}
                />
              ))}
          <hr className="my-3 p-0 w-100" />
          <h5 className="display-5">{t("order.collect")} {areCollectMethodsLoading && <Spinner />}</h5>
          {collectMethods !== null &&
            collectMethods
              .filter((m) => !m.isShippingAddressRequired)
              .map((m) => (
                <ShippingMethod
                  key={m.id}
                  method={m}
                  selectedMethod={shippingMethod}
                  setMethod={setShippingMethod}
                  selectedPoint={collectPoint}
                  setPoint={setCollectPoint}
                />
              ))}
          <span className="text-muted">{t('cart.shippingCostFeeInformation')}</span>
        </Col>
        {shippingMethod && shippingMethod.isShippingAddressRequired &&
          <Col md={4}>
            <h3>{t('customers.shippingAddress.shippingAddress')}</h3>
            {cart.userId ? (
              <>
                <h5 className="display-5">{t("customers.addressBook")} {areUserAddressesLoading && <Spinner />}</h5>
                <AddressSelector
                  addresses={userAddresses}
                  currentAddress={cart.shippingAddress?.id}
                  setAddress={setShippingAddress}
                  canCreate={true}
                  showNone={true}
                  onAddAddress={addUserAddress}
                  namespace='ShippingAddresses'
                  onSearch={loadUserAddresses}
                />
              </>
            ) : (
              <ShippingAddressForm
                shippingAddress={shippingAddress}
                setShippingAddress={setShippingAddressData}
                readOnly={false}
              />
            )}
          </Col>
        }
        {shippingMethod && shippingMethod.isSenderAddressAvailable &&
          <Col md={4}>
            <h3>{t('customers.senderAddress.senderAddress')} {areUserSenderAddressesLoading && <Spinner />}</h3>
            {cart.userId ? (
              <>
                <h5 className="display-5">{t("customers.addressBook")}</h5>
                <AddressSelector
                  addresses={userSenderAddresses}
                  currentAddress={cart.senderAddress?.id}
                  setAddress={setSenderAddress}
                  canCreate={true}
                  showNone={true}
                  onAddAddress={addUserSenderAddress}
                  namespace='SenderAddresses'
                  onSearch={loadUserSenderAddresses}
                />
              </>
            ) : (
              <>
                <h5 className="display-5">{t("customers.addressBook")}</h5>
                <AddressSelector
                  addresses={userSenderAddresses}
                  currentAddress={cart.senderAddress?.id}
                  setAddress={setSenderAddress}
                  canCreate={false}
                  showNone={false}
                  onAddAddress={addUserSenderAddress}
                  namespace='SenderAddresses'
                />
                <hr />
                <ShippingAddressForm
                  shippingAddress={cart.senderAddress}
                  setShippingAddress={setSenderAddress}
                  readOnly={false}
                />
              </>
            )}
          </Col>
        }
      </Row>
      <hr className="my-3 p-0 w-100" />
    </>
  );
}
export default ShippingAndCollect;
