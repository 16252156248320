const Countries = [
    {
        "code": "AT",
        "label": "Austria",
        "native": "Österreich",
        "postalCode": "^\\d{4}$",
        "vatID": "^ATU\\d{8}$"
    },
    {
        "code": "BE",
        "label": "Belgium",
        "native": "België/Belgique/Belgien",
        "postalCode": "^\\d{4}$",
        "vatID": "^BE0\\d{9}$"
    },
    {
        "code": "BG",
        "label": "Bulgaria",
        "native": "България",
        "postalCode": "^\\d{4}$",
        "vatID": "^BG\\d{9,10}$"
    },
    {
        "code": "CY",
        "label": "Cyprus",
        "native": "Κύπρος",
        "postalCode": "^\\d{4}$",
        "vatID": "^CY\\d{8}L$"
    },
    {
        "code": "CZ",
        "label": "Czech Republic",
        "native": "Česká republika",
        "postalCode": "^\\d{3} \\d{2}$",
        "vatID": "^CZ\\d{8,10}$"
    },
    {
        "code": "DE",
        "label": "Germany",
        "native": "Deutschland",
        "postalCode": "^\\d{5}$",
        "vatID": "^DE\\d{9}$",
        "culture": "de"
    },
    {
        "code": "DK",
        "label": "Denmark",
        "native": "Danmark",
        "postalCode": "^\\d{4}$",
        "vatID": "^DK\\d{8}$"
    },
    {
        "code": "EE",
        "label": "Estonia",
        "native": "Eesti",
        "postalCode": "^\\d{5}$",
        "vatID": "^EE\\d{9}$"
    },
    {
        "code": "ES",
        "label": "Spain",
        "native": "España",
        "postalCode": "^\\d{5}$",
        "vatID": "^ES[A-Z0-9]{1}\\d{7}[A-Z0-9]{1}$"
    },
    {
        "code": "FI",
        "label": "Finland",
        "native": "Suomi",
        "postalCode": "^\\d{5}$",
        "vatID": "^FI\\d{8}$"
    },
    {
        "code": "FR",
        "label": "France",
        "native": "France",
        "postalCode": "^\\d{5}$",
        "vatID": "^FR[A-Z0-9]{2}\\d{9}$"
    },
    {
        "code": "GR",
        "label": "Greece",
        "native": "Ελλάδα",
        "postalCode": "^\\d{3} \\d{2}$",
        "vatID": "^EL\\d{9}$"
    },
    {
        "code": "HR",
        "label": "Croatia",
        "native": "Hrvatska",
        "postalCode": "^\\d{5}$",
        "vatID": "^HR\\d{11}$"
    },
    {
        "code": "HU",
        "label": "Hungary",
        "native": "Magyarország",
        "postalCode": "^\\d{4}$",
        "vatID": "^HU\\d{8}$"
    },
    {
        "code": "IE",
        "label": "Ireland",
        "native": "Éire",
        "postalCode": "^[A-Z]\\d{1,2}[A-Z]{0,2} \\d[A-Z]{2}$",
        "vatID": "^IE\\d{7}[A-Z]{1,2}$"
    },
    {
        "code": "IT",
        "label": "Italy",
        "native": "Italia",
        "postalCode": "^\\d{5}$",
        "vatID": "^IT\\d{11}$"
    },
    {
        "code": "LT",
        "label": "Lithuania",
        "native": "Lietuva",
        "postalCode": "^LT-\\d{5}$",
        "vatID": "^LT\\d{9,12}$"
    },
    {
        "code": "LU",
        "label": "Luxembourg",
        "native": "Luxembourg",
        "postalCode": "^\\d{4}$",
        "vatID": "^LU\\d{8}$"
    },
    {
        "code": "LV",
        "label": "Latvia",
        "native": "Latvija",
        "postalCode": "^LV-\\d{4}$",
        "vatID": "^LV\\d{11}$"
    },
    {
        "code": "MT",
        "label": "Malta",
        "native": "Malta",
        "postalCode": "^[A-Z]{3} \\d{4}$",
        "vatID": "^MT\\d{8}$"
    },
    {
        "code": "NL",
        "label": "Netherlands",
        "native": "Nederland",
        "postalCode": "^\\d{4} [A-Z]{2}$",
        "vatID": "^NL\\d{9}B\\d{2}$"
    },
    {
        "code": "PL",
        "label": "Poland",
        "native": "Polska",
        "postalCode": "^((\\d{2}-\\d{3})|\\d{5})$",
        "vatID": "^((PL\\d{10})|(\\d{10}))$",
        "culture": "pl"
    },
    {
        "code": "PT",
        "label": "Portugal",
        "native": "Portugal",
        "postalCode": "^\\d{4}-\\d{3}$",
        "vatID": "^PT\\d{9}$"
    },
    {
        "code": "RO",
        "label": "Romania",
        "native": "România",
        "postalCode": "^\\d{6}$",
        "vatID": "^RO\\d{2,10}$"
    },
    {
        "code": "SE",
        "label": "Sweden",
        "native": "Sverige",
        "postalCode": "^\\d{3} \\d{2}$",
        "vatID": "^SE\\d{12}$"
    },
    {
        "code": "SI",
        "label": "Slovenia",
        "native": "Slovenija",
        "postalCode": "^\\d{4}$",
        "vatID": "^SI\\d{8}$"
    },
    {
        "code": "SK",
        "label": "Slovakia",
        "native": "Slovensko",
        "postalCode": "^\\d{3} \\d{2}$",
        "vatID": "^SK\\d{10}$"
    }
];

export default Countries;