import React, { useState } from "react";

import {
  Alert,
  Button,
  FormGroup,
  Input,
  Row,
  Col,
  InputGroup,
} from "reactstrap";
import apiDriver from "stores/api.driver";
import config from "config/global";
import { useTranslation } from "react-i18next";

export default (props) => {
  const { onFetchData, thin } = props;
  const { t, i18n } = useTranslation();
  const [vatId, setVatId] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const getFromGus = () => {
    setLoading(true);
    apiDriver
      .get(
        `${config.api.orders}${i18n.resolvedLanguage}/BillingInfos/Gus/${vatId}`,
      )
      .subscribe({
        next: (response) => {
          onFetchData(response.response);
          setError(false);
        },
        error: () => {
          setError(true);
        },
        complete: () => {
          setLoading(false);
        }
      });
  };

  return (
    <>
      {error && <Alert color="danger" className="mb-5">{t('users.fillByVatNumber')}</Alert>}
      <Row className="bg-light p-3 pt-5 mb-5">
        <Col md={thin ? 12 : 6}>{t('users.fillByVatNumber')}</Col>
        <Col md={thin ? 12 : 6}>
          <Row>
            <Col md={9}>
              <FormGroup>
                <InputGroup>
                  <Input type="text" placeholder={t("customers.billingInfo.vatID")} value={vatId} onChange={(e) => setVatId(e.target.value)} />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md={3}>
              <Button type="button" onClick={getFromGus} className="float-right" disabled={!vatId || loading}>
                <i className="fas fa-download"></i>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
