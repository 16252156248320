import React from "react";
import { Alert, Spinner, Row, Col, Card, CardBody } from "reactstrap";
import CustomPricing from "./CustomPricing";

import Pricing from "./Pricing";
import { useTranslation } from "react-i18next";

function PricingWrapper(props) {
  const { t } = useTranslation();
  const {
    pricings,
    pricingLoaded,
    amount,
    changeAmount,
    canUseCustom,
    removeCustomPricing,
    addCustomPricing,
    min,
    max,
  } = props;

  if (!pricingLoaded) {
    return (
      <div className="text-center m-5 p-5">
        <Spinner animation="border" role="status"></Spinner>
      </div>
    );
  }

  return (
    <React.Fragment>
      {canUseCustom ? (
        <CustomPricing
          key="custom"
          addCustomPricing={addCustomPricing}
          min={min}
          max={max}
        />
      ) : (
        <React.Fragment />
      )}
      <Card className="my-0 border text-muted" style={{ cursor: "pointer" }}>
        <CardBody className="py-2">
          <Row>
            <Col xs={4}>
              <small>{t("products.fields.amount")}</small>
            </Col>
            <Col xs={4}>
              <small>{t("price.net")}</small>
            </Col>
            <Col xs={4}>
              <small>{t("price.gross")}</small>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {pricings.length > 0 ? (
        pricings
          .sort((a, b) => a.amount - b.amount)
          .map((pricing) => (
            <Pricing
              key={pricing.amount}
              pricing={pricing}
              amount={amount}
              changeAmount={changeAmount}
              removeCustomPricing={removeCustomPricing}
            />
          ))
      ) : (
        <Alert color="info">
          <p>
            <b>{t("products.errors.noPricing.title")}</b>
          </p>
          <p>{t("products.errors.noPricing.content")}</p>
        </Alert>
      )}
    </React.Fragment>
  );
}

export default PricingWrapper;
