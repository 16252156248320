import React from "react";
import MarkStatus from "components/mark-status/MarkStatus";
import TabTable from "./TabTable";
import config from "config/global";
import { useTranslation } from "react-i18next";

export default function TabShippings() {
  const { t, i18n } = useTranslation();

  const columns = [
    {
      dataField: "id",
      text: t("common.id"),
      sort: true,
      formatter: (_cell, row) => (
        <React.Fragment>
          {row.externalId}
          <br />
          <small className="text-muted">{row.id}</small>
        </React.Fragment>
      ),
    },
    {
      dataField: "trackingNumber",
      text: t('order.shipping.trackingNumber'),
      sort: true,
      formatter: (_cell, row) => {
        if (row.trackingNumber && row.trackingLink) return <a href={row.trackingLink} target="_blank" rel="noreferrer">{row.trackingNumber}</a>
        else if (row.trackingNumber) return row.trackingNumber;
        else return t('common.none');
      },
    },
    {
      dataField: "method",
      text: t("common.method"),
      sort: true,
      formatter: (cell, row) => cell ? (row.method?.translations[0]?.title || row.method?.id) : <span className="text-muted">{t('common.none')}</span>,
    },
    {
      dataField: "order.number",
      text: t("order.order"),
      sort: true,
    },
    {
      dataField: "status",
      text: t("common.status"),
      sort: true,
      formatter: (cell, row) =>
        cell ? (
          <span>
            <MarkStatus status={row.status} /> &nbsp;{row.status?.translations[0]?.title || row.status?.id}
          </span>
        ) : (
          <span className="text-muted">{t('common.none')}</span>
        ),
    },
    {
      dataField: "created",
      text: t("order.shipping.dateSent"),
      sort: true,
      formatter: (cell) => cell.substr(0, 10),
    },
    {
      isDummyField: true,
      dataField: "",
      text: "",
    },
  ];
  return (
    <TabTable
      id="shippings"
      url={`${config.api.orders}${i18n.resolvedLanguage}/Shippings/`}
      title={t("cart.deliveryAndOrder")}
      columns={columns}
    />
  );
}
