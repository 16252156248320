import React from "react";

import { Input } from "reactstrap";

export default function InputSelector(props) {
  const { options, value, onChange, inputProps } = props;

  return (
    <Input
      type="select"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      {...inputProps}
    >
      {options.map((option) => (
        <option value={option.key} key={option.key}>
          {option.label}
        </option>
      ))}
    </Input>
  );
}
