import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Redirect } from "react-router-dom";
import * as auth from "stores/auth/actions";

export default function AuthorizeGate() {
  const { token } = useParams();
  const dispatch = useDispatch();
  const stateToken = useSelector((state) => state.auth.token);
  const [logged, setLogged] = useState(false);

  useEffect(() => {
    dispatch(auth.setToken(token));
  }, []);

  useEffect(() => {
    if (stateToken !== token) return;
    dispatch(auth.refresh());
    setLogged(true);
  }, [stateToken]);

  return (
    <>
      {logged && <Redirect push to="/" />}
      Authenticating...
    </>
  );
}
