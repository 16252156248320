import React from 'react';
import { Alert, Input, Row, Col, Form, Button, Table, FormGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const TabBusiness = () => {
  const { t } = useTranslation();
  return (
    <div tabId="business">
      <header>
        <h2 className="text-uppercase">{t("business.business")}</h2>
      </header>
      <hr className="line-primary"></hr>
      <br></br>
      <Form>
        <div>
          <Alert color="success">{t("common.success.changeData")}</Alert>
          <Alert color="danger">{t("errors.common.title")}</Alert>
          <h5 className="display-5">{t("business.data")}</h5>
          <Row>
            <Col className="align-self-center" md="3">
              <label className="labels" htmlFor="#name">
                {t("customers.fields.businessName")}
              </label>
            </Col>
            <Col className="align-self-center" md="9">
              <FormGroup>
                <Input
                  defaultValue=""
                  id="name"
                  name="name"
                  required="required"
                  type="text"
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="align-self-center" md="3">
              <label className="labels" htmlFor="#name">
                {t("business.referal")}
              </label>
            </Col>
            <Col className="align-self-center" md="9">
              <FormGroup>
                <Input
                  defaultValue="https://..."
                  id="name"
                  name="name"
                  type="text"
                  readOnly={true}
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="text-right">
              <Button color="primary" type="submit">
                {t("actions.save")}
              </Button>
              <Button color="primary" outline type="reset">
                {t("actions.cancel")}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
      <h5 className="display-5 mt-5">{t("business.members")}</h5>
      <Table>
        <thead>
          <tr>
            <td>
              <small className="text-uppercase">
                {t("users.fields.givenName")}
              </small>
            </td>
            <td>
              <small className="text-uppercase">
                {t("users.fields.surname")}
              </small>
            </td>
            <td>
              <small className="text-uppercase">{t("order.orders")}</small>
            </td>
            <td>
              <small className="text-uppercase">{t("orders.sum")}</small>
            </td>
            <td>
              <small className="text-uppercase">{t("price.saldo")}</small>
            </td>
            <td></td>
          </tr>
        </thead>
      </Table>
    </div>
  );
}

export default TabBusiness;