import React from "react";
import { useTranslation } from "react-i18next";

import { Card, CardBody, Row, Col } from "reactstrap";

function Pricing(props) {
  const { t, i18n } = useTranslation();
  const { pricing, amount, changeAmount, removeCustomPricing } = props;

  const onClick = () =>
    pricing.costGross
      ? changeAmount({ target: { name: "amount", value: pricing.amount } })
      : false;
  const active = amount === pricing.amount;

  const pricingPerAmount = {
    net: Math.ceil((pricing.costNet / pricing.amount) * 100) / 100,
    gross: Math.ceil((pricing.costGross / pricing.amount) * 100) / 100,
  };

  return (
    <Card
      data-pricing={pricing.amount}
      className={
        active
          ? "my-0 border border-primary text-primary"
          : pricing.costGross
          ? "my-0 border"
          : "text-muted my-0 border"
      }
      style={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <CardBody className="py-3">
        <Row>
          <Col xs={4}>
            <span>
              {pricing.amount.toLocaleString(i18n.resolvedLanguage)} {t('price.piece')}
              {pricing.custom ? (
                <span onClick={() => removeCustomPricing(pricing.amount)}>
                  {" "}
                  &nbsp; <i className="fas fa-times"></i>
                </span>
              ) : (
                <React.Fragment />
              )}
            </span>
          </Col>
          <Col xs={4}>
            <div>
              {pricing.costNet.toLocaleString(i18n.resolvedLanguage, {
                style: "currency",
                currency: "PLN",
              })}
            </div>
            <div>
              <small>
                <small className="text-muted">
                  {pricingPerAmount.net.toLocaleString(i18n.resolvedLanguage, {
                    style: "currency",
                    currency: "PLN",
                  })}{" "}
                  / {t("price.piece")}
                </small>
              </small>
            </div>
          </Col>
          <Col xs={4}>
            <div>
              {pricing.costGross.toLocaleString(i18n.resolvedLanguage, {
                style: "currency",
                currency: "PLN",
              })}
            </div>
            <div>
              <small>
                <small className="text-muted">
                  {pricingPerAmount.gross.toLocaleString(
                    i18n.resolvedLanguage,
                    { style: "currency", currency: "PLN" },
                  )}{" "}
                  / {t("price.piece")}
                </small>
              </small>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default Pricing;
