import React from "react";

function MarkStatus(props) {
  const { status } = props;
  if (status.isSuccessful) {
    return <i className="fas fa-check-circle text-success"></i>;
  }
  if (status.isRejected) {
    return <i className="fas fa-times-circle text-danger"></i>;
  }
  if (status.isProcessing) {
    return <i className="far fa-circle text-primary"></i>;
  }
  return <i className="fas fa-circle"></i>;
}

export default MarkStatus;
