import React from "react";

import { Col, Card, Row } from "reactstrap";
import RealizationTime from "./RealizationTime";
import { useTranslation } from "react-i18next";

function RealizationTimeTypeOption(props) {
  const {
    id,
    title,
    icon,
    realizationTimeType,
    setRealizationTimeType,
    pricings,
  } = props;
  const onClick = () => setRealizationTimeType(id);
  const isChecked = realizationTimeType === id;

  return (
    <Col className="m-0 p-1">
      <Card
        onClick={onClick}
        className={
          isChecked
            ? "border border-primary text-primary px-3 py-2 text-center mb-0"
            : "border text-muted px-3 py-2 text-center mb-0"
        }
        style={{ cursor: "pointer" }}
      >
        <div>
          <i className={icon}></i> &nbsp; {title}
        </div>
        <div className={isChecked ? "text-default" : "text-muted"}>
          <small>
            {isChecked && pricings.length ? (
              <RealizationTime time={pricings[0].realizationTime} />
            ) : (
              <span>&nbsp;</span>
            )}
          </small>
        </div>
      </Card>
    </Col>
  );
}

export default function RealizationTimeType(props) {
  const { t } = useTranslation();
  const {
    realizationTimeType,
    setRealizationTimeType,
    realizationTimes,
    pricings,
  } = props;

  return (
    <Row className="p-0 m-0 mb-2">
      {realizationTimes.some((t) => t.type === "Express") ? (
        <RealizationTimeTypeOption
          id="Express"
          title={t("products.realizationTimeType.express")}
          icon="fas fa-shipping-fast"
          realizationTimeType={realizationTimeType}
          setRealizationTimeType={setRealizationTimeType}
          pricings={pricings}
        />
      ) : (
        <React.Fragment />
      )}
      {realizationTimes.some((t) => t.type === "Standard") ? (
        <RealizationTimeTypeOption
          id="Standard"
          title={t("products.realizationTimeType.standard")}
          icon="fas fa-truck"
          realizationTimeType={realizationTimeType}
          setRealizationTimeType={setRealizationTimeType}
          pricings={pricings}
        />
      ) : (
        <React.Fragment />
      )}
      {realizationTimes.some((t) => t.type === "Economic") ? (
        <RealizationTimeTypeOption
          id="Economic"
          title={t("products.realizationTimeType.economic")}
          icon="fas fa-dolly"
          realizationTimeType={realizationTimeType}
          setRealizationTimeType={setRealizationTimeType}
          pricings={pricings}
        />
      ) : (
        <React.Fragment />
      )}
    </Row>
  );
}
