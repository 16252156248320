import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";

import * as authActions from "stores/auth/actions";

import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import useUser from "hooks/useUser";
import LocalizedLink from "components/LocalizedLink";

function LoginCard() {
  const { t } = useTranslation();
  const [emailFocus, setEmailFocus] = React.useState("");
  const [passwordFocus, setPasswordFocus] = React.useState("");

  const [authData, setAuthData] = React.useState({ email: "", password: "" });
  const onChangeAuthData = (event) => {
    const { name, value } = event.target;
    setAuthData({ ...authData, [name]: value });
  };

  const loading = useSelector((state) => state.auth.loading);
  const error = useSelector((state) => state.auth.error);
  const user = useUser();
  const dispatch = useDispatch();

  const onClickLoginButton = () => {
    dispatch(authActions.login(authData.email, authData.password));
  };

  return (
    <>
      <Card className="bg-secondary shadow border-0">
        <CardHeader>
          <CardTitle className="text-center" tag="h4">
            {t("auth.login.login")}
          </CardTitle>
        </CardHeader>
        <CardBody className="px-lg-5 py-lg-5">
          {error ? (
            <Alert color="danger">{t("auth.errors.common.content")}</Alert>
          ) : (
            <React.Fragment />
          )}
          {user ? (
            <React.Fragment>
              <Alert color="success">{t("auth.login.success")}</Alert>
              <Redirect to="/" />
            </React.Fragment>
          ) : (
            <React.Fragment />
          )}
          <div className="text-center text-muted mb-4">
            <small>{t("auth.login.header")}</small>
          </div>
          <div className="text-center text-muted mb-4">
            <small className="font-weight-bold">{t('auth.login.aboutPreviousSystemAccounts')}</small>
          </div>
          <Form role="form">
            <FormGroup className={"mb-3 " + emailFocus}>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder={t("auth.login.fields.email")}
                  type="email"
                  name="email"
                  value={authData.email}
                  onFocus={() => setEmailFocus("focused")}
                  onBlur={() => setEmailFocus("")}
                  onChange={onChangeAuthData}
                ></Input>
              </InputGroup>
            </FormGroup>
            <FormGroup className={passwordFocus}>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder={t("auth.login.fields.password")}
                  type="password"
                  name="password"
                  value={authData.password}
                  onFocus={() => setPasswordFocus("focused")}
                  onBlur={() => setPasswordFocus("")}
                  onChange={onChangeAuthData}
                ></Input>
              </InputGroup>
            </FormGroup>
            <div className="custom-control custom-control-alternative custom-checkbox">
              <input
                className="custom-control-input"
                id=" customCheckLogin2"
                type="checkbox"
              ></input>
              <label
                className="custom-control-label"
                htmlFor=" customCheckLogin2"
              >
                <span className="text-default opacity-5">
                  {t("auth.login.remember")}
                </span>
              </label>
            </div>
            <div className="text-center">
              <Button
                className="my-4"
                color="primary"
                type="button"
                onClick={onClickLoginButton}
                disabled={loading}
              >
                {t("auth.login.do")}
              </Button>
            </div>
            <div className="text-center">
              {t("auth.notHaveAnAccount")}{" "}
              <LocalizedLink to="/register">{t("auth.register.register")}</LocalizedLink>.
            </div>
            <div className="text-center">
              {t("auth.forgotPassword")}{" "}
              <LocalizedLink to="/reset">{t("auth.reset.reset")}</LocalizedLink>.
            </div>
          </Form>
        </CardBody>
      </Card>
    </>
  );
}

function Login() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("auth.login.login")} - Printweb.pl</title>
      </Helmet>
      <div className="bg-dark py-5">
        <Container>
          <div className="header-body text-center mb-7">
            <Row className="justify-content-center">
              <Col className="px-5 mt-5 pt-5" md="8" lg="8" xl="6">
                <LoginCard />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}
export default withRouter(Login);
