import CountrySelector from "components/AddressForm/CountrySelector";
import PostalCodeInput from "components/AddressForm/PostalCodeInput";
import React from "react";
import { useTranslation } from "react-i18next";

import { Row, Col, Input, FormGroup } from "reactstrap";

function ShippingAddressForm(props) {
  const { t } = useTranslation();
  const { shippingAddress, setShippingAddress, readOnly } = props;

  const onChange = (e) => setShippingAddress(e.target.name, e.target.value);

  return (
    <Row>
      <Col md="12">
        <FormGroup>
          <Input
            id="person"
            name="person"
            placeholder={t("customers.shippingAddress.person")}
            type="text"
            value={shippingAddress?.person}
            onChange={onChange}
            minLength={3}
            maxLength={48}
            readOnly={readOnly}
          />
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <Input
            id="line1"
            name="line1"
            placeholder={t("customers.shippingAddress.line1")}
            type="text"
            value={shippingAddress?.line1}
            onChange={onChange}
            required={true}
            minLength={3}
            maxLength={64}
            readOnly={readOnly}
          />
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <Input
            id="line2"
            name="line2"
            placeholder={t("customers.shippingAddress.line2")}
            type="text"
            value={shippingAddress?.line2}
            onChange={onChange}
            readOnly={readOnly}
            minLength={3}
            maxLength={64}
          />
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <PostalCodeInput
            countryCode={shippingAddress?.countryCode}
            id="postalCode"
            name="postalCode"
            type="text"
            value={shippingAddress?.postalCode}
            onChange={onChange}
            required={true}
            minLength={3}
            maxLength={16}
            readOnly={readOnly}
          />
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <Input
            id="city"
            name="city"
            placeholder={t("customers.shippingAddress.city")}
            type="text"
            value={shippingAddress?.city}
            onChange={onChange}
            required={true}
            minLength={3}
            maxLength={64}
            readOnly={readOnly}
          />
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <CountrySelector
            value={shippingAddress?.countryCode}
            onChange={onChange}
            readOnly={readOnly}
          />
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <Input
            id="email"
            name="email"
            placeholder={t("customers.shippingAddress.email")}
            type="text"
            onChange={onChange}
            value={shippingAddress?.email}
            readOnly={readOnly}
            minLength={3}
            maxLength={64}
          />
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <Input
            id="phone"
            name="phone"
            placeholder={t("customers.shippingAddress.phone")}
            type="text"
            onChange={onChange}
            value={shippingAddress?.phone}
            readOnly={readOnly}
            minLength={3}
            maxLength={64}
          />
        </FormGroup>
      </Col>
    </Row>
  );
}
export default ShippingAddressForm;
