import React from "react";

import DotPayButton from "./DotPay";

export default function PaymentButton(props) {
  const { payment } = props;
  if (
    payment.status &&
    (payment.status.isClosed || payment.status.isProcessing)
  ) {
    return <React.Fragment />;
  }
  switch (payment.method.integration) {
    case "DotPay":
      return <DotPayButton payment={payment} />;
    default:
      return <React.Fragment />;
  }
}
