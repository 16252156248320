import React, { useEffect, useState } from "react";

import { Row, Col, Spinner } from "reactstrap";

import ProductPersonalization from "components/product/ProductPersonalization";

import apiDriver from "stores/api.driver";
import config from "config/global";

import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { useTranslation } from "react-i18next";
import RealizationTerm from "components/Cart/RealizationTerm";

function ProductProject(props) {
  const { t, i18n } = useTranslation();
  const { project, orderProduct } = props;
  const [projectPreviews, setProjectPreviews] = useState(null);

  const loadProjectPreviews = () => {
    apiDriver
      .get(
        `${config.api.orders}${i18n.resolvedLanguage}/OrderProducts/${orderProduct.id}/OrderProductProjects/${project?.objectName}/Preview?Take=1`,
      )
      .pipe(
        map((response) => {
          setProjectPreviews(response.response);
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe(() => { });
  };

  useEffect(() => {
    if (project && orderProduct) {
      loadProjectPreviews();
    }
  }, []);

  return (
    <>
      {project ? (
        <Col md={6} lg={3} className="text-center">
          {projectPreviews?.length > 0 && <img
            alt={t("products.fields.project")}
            src={projectPreviews[0]}
            className="d-block mx-auto mb-3"
            style={{ maxHeight: "128px", maxWidth: "100%" }}
          />}
          {project?.objectName}
          <br />
          <span className="text-muted">
            <small>
              {Math.round(project.stat.size / 1024)} KB -{" "}
              {project.stat.lastModified.substr(0, 10)}
            </small>
          </span>
        </Col>
      ) : (
        <Spinner />
      )}
    </>
  );
}

function ProductMaterial(props) {
  const { material } = props;

  return (
    <>
      {material ? (
        <>
          <div className="clearfix">
            <div className="float-left">
              {material?.objectName}
            </div>
            <div className="float-right">
              <small>
                {Math.round(material.stat.size / 1024)} KB -{" "}
                {material.stat.lastModified.substr(0, 10)}
              </small>
            </div>
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
}

function CartProductSummary(props) {
  const { t, i18n } = useTranslation();
  const { product } = props;
  const currentTranslation = product?.product?.translations[0];
  return (
    <Row>
      <Col md={12}>
        <hr />
        <h3 className="text-uppercase text-primary">
          {currentTranslation?.title || product.title}
        </h3>
      </Col>
      <Col md={7}>
        <Row>
          <Col md={3}>
            <div
              className="full-background h-100 w-100"
              style={{
                backgroundImage: `url(${config.api.products}${i18n.resolvedLanguage}/Products/${product?.product?.id}/Photo)`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
              }}
            ></div>
          </Col>
          <Col md={9}>
            <p>
              <ProductPersonalization product={product} full={true} includePrices={true} includeWeight={true} includeRealizationTerm={true} />
            </p>
          </Col>
        </Row>
      </Col>
      <Col md={5}>
        <h6 className="text-uppercase">{t("order.product.files")}</h6>
        <Row>
          {product.projects.map((project) => (
            <ProductProject
              key={project?.objectName}
              project={project}
              orderProduct={product}
            />
          ))}
        </Row>
        {product.materials.map((material) => (
          <ProductMaterial
            key={material?.objectName}
            material={material}
            orderProduct={product}
          />
        ))}
        <br />
      </Col>
      <Col md={2} className="text-right d-none">
        <div>
          <h6 className="text-uppercase">
            <small>{ }</small>
          </h6>
          <h5>

          </h5>
        </div>
      </Col>
      <Col md={2} className="text-right d-none">
        <div>
          <h6 className="text-uppercase">
            <small>{t("order.checking.checking")}</small>
          </h6>
          <h5>
            {t(`order.checking.${product?.personalization?.checking?.toLowerCase()}.title`)}
          </h5>
        </div>
      </Col>
      <Col md={2} className="text-right d-none">
        <div>
          <h6 className="text-uppercase">
            <small>{t("products.fields.weight")}</small>
          </h6>
          <h5>{product.weight} kg</h5>
        </div>
      </Col>
      <Col md={2} className="text-right d-none">
        <div>
          <h6 className="text-uppercase">
            <small>{t("products.fields.realizationTerm")}</small>
          </h6>
          <h5>
            <RealizationTerm term={product.realizationTerm} />
          </h5>
        </div>
      </Col>
      <Col md={2} className="text-right d-none">
        <div>
          <h6 className="text-uppercase">
            <small>{t("price.price.net")}</small>
          </h6>
          <h5>
            {new Intl.NumberFormat(i18n.resolvedLanguage, {
              style: "currency",
              currency: "PLN",
            }).format(product.priceObj.netCost)}
          </h5>
        </div>
      </Col>
      <Col md={2} className="text-right d-none">
        <div>
          <h6 className="text-uppercase">
            <small>{t("price.price.gross")}</small>
          </h6>
          <h5>
            {new Intl.NumberFormat(i18n.resolvedLanguage, {
              style: "currency",
              currency: "PLN",
            }).format(product.priceObj.grossCost)}
          </h5>
        </div>
      </Col>
    </Row>
  );
}

export default CartProductSummary;
