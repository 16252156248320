import React from "react";
import ValidatedInput from "./ValidatedInput";

export default function NumericInput(props) {
  return(
    <ValidatedInput
      type="number"
      {...props}
      />
  );
}
