import React from "react";

// reactstrap components
import { CardTitle, CardBody, Card, Col } from "reactstrap";
import config from "config/global";
import { useTranslation } from "react-i18next";
import useUser from "hooks/useUser";
import LocalizedLink from "components/LocalizedLink";

// Core Components

function Product(props) {
  const { product } = props;

  const { i18n } = useTranslation();
  const user = useUser();

  let currentTranslation = product.translations[0];

  const isNotAccesible = !product.isAvailable || (!user && !product.isGuestAccessible);

  return (
    <Col xs="4" md="3" lg="2" xl="2" className="product-thumbnail px-2 px-md-3">
      <Card className="card-blog card-background" data-animation="zooming" style={{ opacity: isNotAccesible ? '0.25' : '1' }}>
        {
          !isNotAccesible ?
            <LocalizedLink to={`/products/${currentTranslation?.url || product.id}`}>
              <CardBody>
                <div
                  className="full-background"
                  style={{
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "top center",
                    backgroundImage: `url(${config.api.products}${i18n.resolvedLanguage}/Products/${product.id}/Photo)`,
                  }}
                ></div>
                <div className="content-bottom" style={{ position: 'absolute', bottom: '1vh', width: '100%', padding: '1%' }}>
                  <CardTitle tag="h6" className="text-dark text-center text-shadow">{currentTranslation?.title}</CardTitle>
                </div>
              </CardBody>
            </LocalizedLink> :
            <CardBody>
              <div
                className="full-background"
                style={{
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top center",
                  backgroundImage: `url(${config.api.products}${i18n.resolvedLanguage}/Products/${product.id}/Photo)`,
                }}
              ></div>
              <div className="content-bottom" style={{ position: 'absolute', bottom: '1vh', width: '100%' }}>
                <CardTitle tag="h6" className="text-dark text-center text-shadow">{currentTranslation?.title}</CardTitle>
              </div>
            </CardBody>
        }
      </Card>
    </Col>
  );
}

export default Product;
