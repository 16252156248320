import React from "react";

import { Col, Card, Row } from "reactstrap";

function AdditiveButton(props) {
  const { onClick, active, disabled, additive } = props;
  return (
    <Col lg={6} onClick={onClick} className="p-1">
      <Card
        className={
          active
            ? "border border-primary mx-3 my-0 px-3 py-2 h-100 text-center"
            : disabled
            ? "border mx-3 my-0 px-3 py-2 h-100 text-center text-muted"
            : "border mx-3 my-0 px-3 py-2 h-100 text-center"
        }
        style={{ cursor: disabled ? "default" : "pointer" }}
      >
        <div className={active ? "text-primary" : ""}>
          {additive.currentTranslation?.title}
        </div>
        {additive.currentTranslation?.description ? (
          <div className={active ? "" : "text-muted"}>
            <small>{additive.currentTranslation?.description}</small>
          </div>
        ) : (
          <React.Fragment />
        )}
      </Card>
    </Col>
  );
}

function AdditiveCheckbox(props) {
  const { onClick, active, disabled, additive } = props;

  return (
    <Col lg={6} onClick={onClick} className="p-1">
      <Card
        className={
          active
            ? "border border-primary mx-3 my-0 px-3 py-2 h-100 text-center"
            : disabled
            ? "border mx-3 my-0 px-3 py-2 h-100 text-center text-muted"
            : "border mx-3 my-0 px-3 py-2 h-100 text-center"
        }
        style={{ cursor: disabled ? "default" : "pointer" }}
      >
        <Row>
          <Col md={2}>
            <i
              className={
                active
                  ? "fa fa-check-circle text-primary"
                  : "far fa-circle text-muted"
              }
            ></i>
          </Col>
          <Col md={10} className="text-left">
            <div className={active ? "text-primary" : ""}>
              {additive.currentTranslation?.title}
            </div>
            {additive.currentTranslation?.description && (
              <div className={active ? "" : "text-muted"}>
                <small>{additive.currentTranslation?.description}</small>
              </div>
            )}
          </Col>
        </Row>
      </Card>
    </Col>
  );
}

function Additive(props) {
  const { additiveGroup, additive, currentAdditives, onChange, disabled } =
    props;

  const onClick = () => {
    if (disabled) return false;
    if (additiveGroup)
      return onChange({
        target: { name: additiveGroup.id, value: additive.id },
      });
    else return onChange({ target: { name: "", value: additive.id } });
  };

  const active = currentAdditives.includes(additive.id);

  switch (additiveGroup?.inputType) {
    case "Checkbox":
      return (
        <AdditiveCheckbox
          additive={additive}
          active={active}
          disabled={disabled}
          onClick={onClick}
        />
      );
    default:
      return (
        <AdditiveButton
          additive={additive}
          active={active}
          disabled={disabled}
          onClick={onClick}
        />
      );
  }
}

export default Additive;
