import React from "react";

import { Row, Col, Card } from "reactstrap";

function ButtonOption(props) {
  const { option, value, onClick, buttonProps } = props;

  const active = option.key === value;

  return (
    <Col xs={6} md={4} lg={4} xl={3} className="p-1">
      <Card
        onClick={onClick}
        className={
          active
            ? "text-center py-3 m-0 border border-primary text-primary"
            : "text-center py-3 m-0 border text-muted"
        }
        style={{ cursor: "pointer" }}
        {...buttonProps}
      >
        {option.label}
      </Card>
    </Col>
  );
}

export default function ButtonSelector(props) {
  const { options, value, onChange, containerProps, buttonProps } = props;

  return (
    <Row {...containerProps}>
      {options.map((option) => (
        <ButtonOption
          key={option.key}
          option={option}
          value={value}
          onClick={() => onChange(option.key)}
          buttonProps={buttonProps}
        />
      ))}
    </Row>
  );
}
