import * as constants from "./constants";
import ICartState from "./state";

const cart = (state: ICartState = {} as ICartState, action: any) => {
  switch (action.type) {
    case constants.GET_FAILURE:
      return { error: action.payload, loading: false };
    case constants.GET_SUCCESS:
      return { ...action.payload, loading: false };
    case constants.GET:
      return { ...state, loading: true };
    case constants.UPDATE:
      return { ...state, loading: true };
    case constants.UPDATE_SUCCESS:
      return { ...state, ...action.payload, loading: false };
    case constants.SUBMIT:
      return { ...state, loading: true };
    case constants.SUBMIT_SUCCESS:
      return { ...state, ...action.payload, loading: false };
    case constants.PUT_PRODUCT:
    case constants.REMOVE_PRODUCT:
      return { ...state, loading: true };
    case constants.PUT_PRODUCT_SUCCESS:
      return { ...state, ...action.payload, loading: false };
    case constants.REMOVE_PRODUCT_SUCCESS:
      return { ...state, ...action.payload, loading: false };
    case constants.REMOVE_SUCCESS:
    case constants.CLEAR:
    case constants.CLEAR_SUCCESS:
      return { loading: false };
    case constants.FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default cart;
