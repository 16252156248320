import { combineEpics, ofType } from "redux-observable";
import { map, mergeMap, catchError } from "rxjs/operators";
import config from "config/global";
import * as constants from "./constants";
import * as actions from "./actions";
import { of } from "rxjs";
import api from "stores/api.driver";
import { store } from "../store";

import i18n from "i18n";

export const API_URL_ORDER_PRODUCT = `${config.api.orders}${
  i18n.resolvedLanguage || "en"
}/OrderProducts/`;
export const API_URL = `${config.api.orders}${
  i18n.resolvedLanguage || "en"
}/Cart`;
export const API_URL_GUEST = `${config.api.orders}${
  i18n.resolvedLanguage || "en"
}/GuestCart/`;

export const url = (): string => {
  const state = store.getState();

  const cartId = state.cart.id;
  const user = state.auth.user;

  return user
    ? API_URL
    : `${API_URL_GUEST}${cartId || "00000000-0000-0000-0000-000000000000"}`;
};

const getEpic = (action$: any) =>
  action$.pipe(
    ofType(constants.GET),
    mergeMap((action: any) =>
      api.get(url()).pipe(
        map((response: any) => actions.getSuccess(response.response)),
        catchError((error: any) =>
          of({
            type: constants.FAILURE,
            payload: error.status,
          }),
        ),
      ),
    ),
  );

const updateEpic = (action$: any) =>
  action$.pipe(
    ofType(constants.UPDATE),
    mergeMap((action: any) =>
      api.patch(url(), action.payload).pipe(
        map((response: any) => actions.updateSuccess(response.response)),
        catchError((error: any) =>
          of({
            type: constants.FAILURE,
            payload: error.status,
          }),
        ),
      ),
    ),
  );

const submitEpic = (action$: any) =>
  action$.pipe(
    ofType(constants.SUBMIT),
    mergeMap((action: any) =>
      api.post(url(), action.payload).pipe(
        map((response: any) => actions.submitSuccess(response.response)),
        catchError((error: any) =>
          of({
            type: constants.FAILURE,
            payload: error.status,
          }),
        ),
      ),
    ),
  );

const removeEpic = (action$: any) =>
  action$.pipe(
    ofType(constants.REMOVE),
    mergeMap((action: any) =>
      api.remove(url()).pipe(
        map((response: any) => actions.removeSuccess()),
        catchError((error: any) =>
          of({
            type: constants.FAILURE,
            payload: error.status,
          }),
        ),
      ),
    ),
  );

const putProduct = (action$: any) =>
  action$.pipe(
    ofType(constants.PUT_PRODUCT),
    mergeMap((action: any) =>
      api.put(url(), action.payload).pipe(
        map((response: any) => actions.putProductSuccess(response.response)),
        catchError((error: any) =>
          of({
            type: constants.FAILURE,
            payload: error.status,
          }),
        ),
      ),
    ),
  );

const removeProduct = (action$: any) =>
  action$.pipe(
    ofType(constants.REMOVE_PRODUCT),
    mergeMap((action: any) =>
      api.remove(`${API_URL_ORDER_PRODUCT}${action.payload}`).pipe(
        map((response: any) => actions.removeProductSuccess(response.response)),
        catchError((error: any) =>
          of({
            type: constants.FAILURE,
            payload: error.status,
          }),
        ),
      ),
    ),
  );

export default combineEpics(
  getEpic,
  updateEpic,
  submitEpic,
  removeEpic,
  putProduct,
  removeProduct,
);
