import React from "react";

import { Col, Row, Card } from "reactstrap";

function CardOption(props) {
  const { id, option, value, onChange, vertical, large } = props;

  const onClick = () =>
    !disabled ? onChange({ target: { name: id, value: option.id } }) : false;

  const active = option.id === value;
  const disabled = option.disabled;

  return (
    <Col lg={vertical ? 12 : large ? 6 : 4} onClick={onClick} className="p-1">
      <Card
        className={
          active
            ? "border border-primary m-0 px-3 py-2 h-100 text-center"
            : disabled
            ? "border m-0 px-3 py-2 h-100 text-center text-muted"
            : "border m-0 px-3 py-2 h-100 text-center"
        }
        style={{ cursor: disabled ? "default" : "pointer" }}
      >
        <div className={active ? "text-primary" : ""}>{option.title}</div>
        {option.description ? (
          <div className={active ? "" : "text-muted"}>
            <small>{option.description}</small>
          </div>
        ) : (
          <React.Fragment />
        )}
      </Card>
    </Col>
  );
}

function CardSelect(props) {
  const { id, title, value, options, onChange, vertical, large } = props;

  return (
    <>
      {title ? <h4 className="text-uppercase">{title}</h4> : <React.Fragment />}
      <Row className="px-2">
        {options.map((option) => (
          <CardOption
            key={option.id}
            id={id}
            option={option}
            value={value}
            onChange={onChange}
            large={large}
            vertical={vertical}
          />
        ))}
      </Row>
    </>
  );
}

export default CardSelect;
