import React from "react";

import { Col } from "reactstrap";

function CollectPoint(props) {
  const { point, selectedPoint } = props;
  return (
    <Col
      width={12}
      className={selectedPoint === point.id ? "bg-light" : ""}
    >
      <div>
        <b>{point.name}</b>
      </div>
      <div>{point.line1}</div>
      {point.line2 ? <div>{point.line2}</div> : <React.Fragment />}
      <div>
        {point.postalCode} {point.city}
      </div>
    </Col>
  );
}
export default CollectPoint;
