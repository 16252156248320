import { ajax } from "rxjs/ajax";
import { catchError, map } from "rxjs/operators";
import { Subject } from "rxjs";
import { store } from "./store";
import * as authActions from "stores/auth/actions";

export let defaultHeaders = () => {
  const state = store.getState();
  return {
    Authorization: "Bearer " + state.auth.token,
    "Content-Type": "application/json",
    "Access-Control-Allow-Headers":
      "Results-Count-Total, Results-Count-Filtered, Results-Pages, Results-Skip, Results-Take",
    "Access-Control-Expose-Headers":
      "Results-Count-Total, Results-Count-Filtered, Results-Pages, Results-Skip, Results-Take",
  };
};

export const catchHttpErrorsPipe = catchError((error) => {
  if (error.status === 401) {
    store.dispatch(authActions.logout());
    document.location.href = "/auth/login";
  } else {
    console.error(`#${error.status}: ${error.message}`);
    document.location.href = "/";
  }
  return {};
});

const apiDriver = {
  get: (url, headers = {}, responseType = "json") =>
    ajax({
      method: "GET",
      url: url,
      headers: Object.assign({}, defaultHeaders(), headers),
      responseType: responseType,
    }),
  post: (url, data, headers = {}, responseType = "json") =>
    ajax({
      method: "POST",
      url: url,
      body: data,
      headers: Object.assign({}, defaultHeaders(), headers),
      responseType: responseType,
    }),
  patch: (url, data, headers = {}, responseType = "json") =>
    ajax({
      method: "PATCH",
      url: url,
      body: data,
      headers: Object.assign({}, defaultHeaders(), headers),
      responseType: responseType,
    }),
  put: (url, data, headers = {}, responseType = "json") =>
    ajax({
      method: "PUT",
      url: url,
      body: data,
      headers: Object.assign({}, defaultHeaders(), headers),
      responseType: responseType,
    }),
  upload: (url, file, name, headers = {}, responseType = "json") => {
    const progressSubscriber = new Subject();

    let data = new FormData();
    data.append("name", name);
    data.append("file", file);

    const request = ajax({
      method: "PUT",
      url: url,
      body: data,
      headers: Object.assign({}, defaultHeaders(), {
        "Content-Type": "multipart/form-data",
        ...headers
      }),
      progressSubscriber,
      responseType: responseType,
    });
    return [request, progressSubscriber];
  },
  uploadPresigned: (url, file, headers = {}) => {
    const progressSubscriber = new Subject();

    let data = new FormData();
    data.append("file", file);

    const attrs = {
      method: "PUT",
      url: url,
      body: file,
      headers: { "Content-Type": "multipart/form-data" , ...headers},
      progressSubscriber,
    };

    const request = ajax(attrs);
    return [request, progressSubscriber];
  },
  download: (name, url, headers) =>
    ajax({
      url: url,
      headers: Object.assign({}, defaultHeaders(), headers),
      responseType: "blob",
    })
    .pipe(catchHttpErrorsPipe)
    .pipe(
      map((response) => {
        if (response.response && response.status === 200) {
          var blob = new Blob([response.response], {
            type: response.response.type,
          });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = name;
          link.click();
        }
        return response;
      }),
    ),
  remove: (url, headers) =>
    ajax.delete(url, Object.assign({}, defaultHeaders(), headers)),
  buildIndexAttributes: (payload) => {
    let attributes = [];
    if (payload.skip) {
      attributes.push("skip=" + payload.skip);
    }
    if (payload.take) {
      attributes.push("take=" + payload.take);
    }
    if (payload.filters) {
      let filterKeys = Object.keys({ ...payload.filters });
      let filterValues = filterKeys.map((i) => ({
        ...payload.filters[i],
        filterKey: i,
      }));
      attributes.push(
        "filters=" + encodeURIComponent(JSON.stringify(filterValues)),
      );
    }
    if (payload.order) {
      attributes.push(
        "order=" + encodeURIComponent(JSON.stringify(payload.order)),
      );
    }
    if (payload.searchText) {
      attributes.push("searchText=" + encodeURIComponent(payload.searchText));
    }
    if (attributes.length === 0) {
      return "";
    }
    return "?" + attributes.join("&");
  },
};

export default apiDriver;
