import MainNavbar from 'components/navbars/Navbar';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Spinner } from 'reactstrap';

function PrintError({ error }) {
  const state = useSelector(s => s);

  useEffect(() => {

  })

  return (
    <div className="bg-light p-3" style={{ borderLeft: '2px solid #d40000', overflow: 'auto' }}>
      <div className="clearfix w-100">
        <div className="float-left">
          <pre className="text-danger"><b>{error.name}</b>: {error.message}</pre>
          <pre>{error.stack}</pre>
          <pre>{new Date().toISOString()}</pre>
          <hr />
          <h5>Redux state</h5>
          <pre>{Object.keys({ ...state }).map(key => <div key={key}>{key}: {JSON.stringify(state[key])}</div>)}</pre>
          <hr />
          <h5>Local storage</h5>
          <pre>{Object.keys({ ...localStorage }).map(key => <div key={key}>{key}: {JSON.stringify(localStorage.getItem(key))}</div>)}</pre>
          <hr />
          <h5>Session storage</h5>
          <pre>{Object.keys({ ...sessionStorage }).map(key => <div key={key}>{key}: {JSON.stringify(sessionStorage.getItem(key))}</div>)}</pre>
        </div>
        <div className="float-right text-danger">
          <i className="fa fa-exclamation fa-3x m-3"></i>
        </div>
      </div>
    </div>
  )
}

export default function Fallback({ error, resetErrorBoundary }) {

  const testingEnvironments = [
    'localhost',
    'qa.printweb.pl',
    'uat.printweb.pl',
    'printweb-qa-client.h0pe.cloud',
    'printweb-uat-client.h0pe.cloud'
  ];

  const isTestingEnvironment = testingEnvironments.includes(window.location.hostname);

  useEffect(() => {
    if (!isTestingEnvironment) {
      setTimeout(reset, 2500);
    }
  }, []);

  const redirect = () => window.location.href = '/';

  const reset = () => {
    resetErrorBoundary();
    redirect();
  }

  return (
    <>
      <MainNavbar />
      <Container>

        <div className="clearfix">
          <div className="float-left">
            <h1>Ooops!</h1>
            <h3>Something went wrong...</h3>
          </div>
          <div className="float-right">
            <i className="fas fa-bug fa-5x m-3 text-muted"></i>
          </div>
        </div>
        <hr />
        {!isTestingEnvironment && <h5>We take customer satisfaction seriously! So we registered informations about this bug to improve your future feelings of using our service.</h5>}
        {!isTestingEnvironment && <h5>You will be redirected to home page, your informations about state of application will be deleted to provide you refreshed application!</h5>}
        {isTestingEnvironment && <PrintError error={error} />}
        <hr />
        <div className="text-right">
          <Spinner />
        </div>
      </Container>
    </>
  )
}