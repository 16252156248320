import config from 'config/global';
import React, { useState } from 'react';
import { Alert, Input, Row, Col, Form, Button, Spinner, FormGroup } from 'reactstrap';
import apiDriver from 'stores/api.driver';
import * as authActions from "stores/auth/actions";
import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { useTranslation } from 'react-i18next';
import useUser from 'hooks/useUser';
import { useDispatch } from 'react-redux';

const TabData = () => {
  const { t } = useTranslation();
  const [statusCode, setStatusCode] = useState(0);
  const [loading, setLoading] = useState(false);
  const user = useUser();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    givenName: user.givenName,
    surname: user.surname,
    username: user.username,
  });
  const onChangeFormData = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const feedback = () => {
    if (loading) {
      return (
        <div className="text-center">
          <Spinner />
        </div>
      );
    }
    switch (statusCode) {
      case 200:
        return <Alert color="success">{t("common.success.changeData")}</Alert>;
      case 500:
        return <Alert color="danger">{t("errors.common.title")}</Alert>;
      default:
        return <React.Fragment />;
    }
  };
  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    await apiDriver
      .patch(config.api.iam + "Account", formData)
      .pipe(
        map(() => {
          setStatusCode(200);
          dispatch(authActions.refresh());
        }),
        catchError((error) => {
          setStatusCode(error.status);
          return of(error);
        }),
      )
      .subscribe(() => {
        setLoading(false);
      });
  };

  return (
    <div tabId="data">
      <Form onSubmit={onSubmit}>
        <div>
          <header>
            <h2 className="text-uppercase">{t("users.myData")}</h2>
          </header>
          <hr className="line-primary"></hr>
          <br></br>
          {feedback()}
          <Row>
            <Col className="align-self-center" md="3">
              <label className="labels" htmlFor="#firstName">
                {t("users.fields.givenName")}
              </label>
            </Col>
            <Col className="align-self-center" md="9">
              <FormGroup>
                <Input
                  id="givenName"
                  name="givenName"
                  value={formData.givenName}
                  onChange={onChangeFormData}
                  required="required"
                  type="text"
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="align-self-center" md="3">
              <label className="labels" htmlFor="#surname">
                {t("users.fields.surname")}
              </label>
            </Col>
            <Col className="align-self-center" md="9">
              <FormGroup>
                <Input
                  id="surname"
                  name="surname"
                  value={formData.surname}
                  onChange={onChangeFormData}
                  required="required"
                  type="text"
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="text-right">
              <Button color="primary" type="submit" disabled={loading}>
                {t("actions.save")}
              </Button>
              <Button color="primary" outline type="reset" disabled={loading}>
                {t("actions.reset")}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
}

export default TabData;