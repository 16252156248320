import React, { useEffect, useState } from 'react';
import Countries from './Countries';
import ValidatedInput from 'components/form/ValidatedInput';

export default function CountryCodeDependentInput(props) {
    const { countryCode, propertyOfPattern, ...inheritedProps } = props;
    const [hadCountryCode, setHadCountryCode] = useState(false);
    const [toValidate, setToValidate] = useState(false);

    const country = Countries.find(country => country.code === countryCode?.toUpperCase());
    const pattern = country ? country[propertyOfPattern] : undefined;

    useEffect(() => {
        if (hadCountryCode) {
            setToValidate(true);
            return;
        }
        if (countryCode) {
            setHadCountryCode(true);
            return;
        }
    }, [countryCode]);

    return <ValidatedInput
        type="text"
        pattern={pattern}
        toValidate={toValidate}
        setToValidate={setToValidate}
        {...inheritedProps}
    />
} 