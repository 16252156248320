import React from "react";
import ReactHtmlParser from "react-html-parser";

export default function PageContent(props) {
  const { page } = props;

  return (
    <>
      <div> {ReactHtmlParser(page?.translations[0]?.content)} </div>
    </>
  );
}
