import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import { useParams } from "react-router-dom";

import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";

import {
  Card,
  TabPane,
  Row,
  Col,
  Form,
  Container,
  Spinner,
} from "reactstrap";

import Order from "./Order";
import apiDriver from "stores/api.driver";
import config from "config/global";
import { useTranslation } from "react-i18next";

function OrderWrapper() {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [isFetching, setIsFetching] = useState([]);

  const setFetchingState = (name, state) => {
    if (state === true) {
      setIsFetching([...isFetching, name]);
    } else {
      setIsFetching([...isFetching].filter((n) => n !== name));
    }
  };

  const onFetchOrder = (ord) => {
    setFetchingState(`order`, false);
    setOrder({
      ...ord,
      products: ord.products.map((p) => ({ ...p, loaded: false })),
    });
  };

  const fetchOrder = () => {
    setFetchingState("order", true);
    apiDriver
      .get(`${config.api.orders}${i18n.resolvedLanguage}/Orders/${id}`)
      .pipe(
        map((response) => {
          onFetchOrder(response.response);
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe(() => {});
  };
  const onFetchOrderProduct = (orderProduct) => {
    setFetchingState(`order.products.${orderProduct.id}`, false);
    setOrder({
      ...order,
      products: [...order.products].map((p) => {
        if (p.id !== orderProduct.id) return p;
        return { ...orderProduct, loaded: true };
      }),
    });
  };

  const fetchOrderProduct = (orderProductId) => {
    setFetchingState(`order.products.${orderProductId}`, true);
    apiDriver
      .get(
        `${config.api.orders}${i18n.resolvedLanguage}/OrderProducts/${orderProductId}`,
      )
      .pipe(
        map((response) => {
          onFetchOrderProduct(response.response);
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe(() => {});
  };

  useEffect(() => {
    if(i18n?.resolvedLanguage) {
      fetchOrder();
    }
  }, [ i18n?.resolvedLanguage ]);

  useEffect(() => {
    if (order?.products?.some((p) => p.loaded === false)) {
      const product = order?.products?.find((p) => p.loaded === false);
      if (!isFetching.includes(`order.products.${product.id}`)) {
        fetchOrderProduct(product.id);
      }
    }
  }, [isFetching]);

  return (
    <TabPane tabId="current">
      <Form>
        <div>
          <hr className="line-primary"></hr>
          <br></br>
          <div className="clearfix">
            <div className="float-left">
              <h4>{t("order.orders")}</h4>
            </div>
            <div className="float-left">
              {isFetching.length > 0 ? (
                <Spinner className="ml-3" />
              ) : (
                <React.Fragment />
              )}
            </div>
          </div>
          <hr />
          {order && (
            <Order
              key={order.id}
              order={order}
              isFetching={isFetching}
              setFetchingState={setFetchingState}
            />
          )}
        </div>
      </Form>
    </TabPane>
  );
}

function OrderPage() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("order.orders")} - Printweb.pl</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col md="12" lg="12" xl="10" className="mx-auto">
            <Card className="bg-white my-5">
              <Row>
                <Col md="10" className="mx-auto">
                  <OrderWrapper />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default OrderPage;
