import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, Redirect } from "react-router-dom";

import { Spinner, Alert } from 'reactstrap';

import config from "config/global";
import apiDriver from "stores/api.driver";

export default function InvoiceGate() {
  const { id, buyerDataId } = useParams();
  const { i18n } = useTranslation();

  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(false);
  const [ downloaded, setDownloaded ] = useState(false);
  const [ invoice, setInvoice ] = useState();
  

  useEffect(() => {
    if(id && buyerDataId && i18n.resolvedLanguage) {
      apiDriver
        .get(`${config.api.accountancy}${i18n.resolvedLanguage}/Invoices/${id}?BuyerDataId=${buyerDataId}`)
        .subscribe({
          next: (response) => setInvoice(response.response),
          error: () => setError(true),
        })
    }
  }, [ id, buyerDataId, i18n.resolvedLanguage ]);

  useEffect(() => {
    if(invoice) {
      apiDriver
        .download(
          `${invoice.number.replace('/', '-').replace('\\', '-')}.pdf`,
          `${config.api.accountancy}${i18n.resolvedLanguage}/Invoices/${id}/Download?BuyerDataId=${buyerDataId}`
        )
        .subscribe({
          next: (response) => setDownloaded(true),
          error: () => setError(true),
          complete: () => setLoading(false)
        })
    }
  }, [ invoice ]);

  return (
    <>
      {invoice && <h1 className="text-center">{invoice.number}</h1>}
      {loading && <div className="m-5 p-5 text-center"><Spinner /></div>}
      {error && <Alert color="danger">Error</Alert>}
      {downloaded && <Redirect to="/" />}
    </>
  );
}
