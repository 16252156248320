import React, { useCallback, useEffect, useMemo, useState } from "react";
import { withRouter, Redirect } from "react-router-dom";
import Helmet from "react-helmet";
import { useDispatch } from "react-redux";
import _ from "lodash";

import * as authActions from "stores/auth/actions";

import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";
import PasswordStrengthBar from "react-password-strength-bar";

import config from "config/global";
import apiDriver from "stores/api.driver";
import { useTranslation } from "react-i18next";
import CountrySelector from "components/AddressForm/CountrySelector";
import useUser from "hooks/useUser";

function useFocus() {
  const [current, setCurrent] = useState("");
  const onFocus = useCallback((event) => {
    setCurrent(event.target.name);
  }, []);
  const isFocused = useCallback((name) => {
    return current === name;
  }, []);
  return {
    current, setCurrent, onFocus, isFocused
  }
}

export function Countries() {
  return [
    {
      code: "pl",
      name: "Polska",
      postalCode: "(([0-9]{2}-[0-9]{3})|([0-9]{5}))",
      phonePrefix: "+48",
      phonePattern:
        "(([0-9]{3}-[0-9]{3}-[0-9]{3})|([0-9]{2}-[0-9]{3}-[0-9]{2}-[0-9]{2})|([0-9]{3} [0-9]{3} [0-9]{3})|([0-9]{2} [0-9]{3} [0-9]{2} [0-9]{2})|([0-9]{9}))",
    },
  ];
}

function RegisterStepFirst({ isBusiness, setIsBusiness, readOnly, ...props }) {
  const { t } = useTranslation();
  const onSetIndividual = () => setIsBusiness(false);
  const onSetBusiness = () => setIsBusiness(true);

  return (
    <>
      <h3 className="mb-5">{t('auth.register.steps.1')}</h3>
      <Row className="mb-5">
        <Col md="4" className="offset-md-2">
          <Button type="button" color={isBusiness === false ? "primary" : "secondary"} onClick={onSetIndividual} disabled={readOnly} block>
            {t('users.accountType.private')}
          </Button>
        </Col>
        <Col md="4">
          <Button type="button" color={isBusiness === true ? "primary" : "secondary"} onClick={onSetBusiness} disabled={readOnly} block>
            {t('users.accountType.business')}
          </Button>
        </Col>
      </Row>
    </>
  )
}

function RegisterStepSecond({ form, setForm, onChangeForm, onChangePasswordScore, setPreferredDelivery, setOverwriteBillingInfo, isBusiness, readOnly, error, ...props }) {
  const { t, i18n } = useTranslation();
  const [isPending, setPending] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [success, setSuccess] = useState(false);
  const focus = useFocus();

  const vatId = useMemo(() => {
    if (!form.billingInfo.vatID) return "";
    return form.billingInfo.vatID
      .replace('-', '')
      .replace(' ', '')
      .toUpperCase();
  }, [form.billingInfo.vatID]);

  const isVatValid = useMemo(() => {
    if (!vatId) return;
    if (!isNaN(Number(vatId)) && vatId.length === 10) return true;
    if (vatId.startsWith('PL') && vatId.length === 12) return true;
    return false;
  }, [vatId]);

  const fetchFromGus = () => {
    setPending(true);
    apiDriver
      .get(
        `${config.api.orders}${i18n.resolvedLanguage}/BillingInfos/Gus/${vatId}`,
      )
      .subscribe({
        next: (response) => {
          setForm({
            ...form,
            businessName: response.response.buyerName,
            shippingAddress: response.response,
            billingInfo: response.response,
          });
          setOverwriteBillingInfo(false);
          setPreferredDelivery(true);
          setFetchError(false);
          setSuccess(true);
        },
        error: () => {
          setFetchError(true);
        },
        complete: () => {
          setPending(false);
        }
      });
  };

  return (
    <>
      <h3 className="mb-5">2. {t('auth.register.steps.2')}</h3>

      {isBusiness && (
        <Row>
          <Col md="3" className="text-left">
            <span className="text-sm text-muted text-uppercase">{t("customers.billingInfo.vatID")}*</span>
            <FormGroup className={`${focus.isFocused("billingInfo.vatID") ? "focused" : ""}`}>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-percent"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder={t("customers.billingInfo.vatID")}
                  type="text"
                  name="billingInfo.vatID"
                  value={form.billingInfo?.vatID}
                  onChange={onChangeForm}
                  onFocus={focus.onFocus}
                  required={true}
                  minLength={8}
                  className={vatId ? (isVatValid ? "is-valid" : "is-invalid") : ""}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md="9" className="text-left">
            <span className="text-sm text-muted text-uppercase">&nbsp;</span><br />
            <div className="d-flex">
              <Button type="button" color="primary" disabled={!isVatValid || isPending} onClick={fetchFromGus}>
                <i className="fas fa-sync"></i>
              </Button>
              <div className="flex-grow-1">
                {fetchError && <Alert color="danger" className="mb-0 h-100">Nie można pobrać danych z GUS</Alert>}
                {success && <Alert color="info" className="mb-0 h-100"> Pobrano dane z GUS, będziesz mógł użyć tych danych do faktury bądź wysyłki</Alert>}
              </div>
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col md="6" className="text-left">
          <span className="text-sm text-muted text-uppercase">{t("users.fields.givenName")}*</span>
          <FormGroup className={`${focus.isFocused("user.givenName") ? "focused" : ""}`}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-user"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={t("users.fields.givenName")}
                type="text"
                name="user.givenName"
                value={form.user.givenName}
                onChange={onChangeForm}
                onFocus={focus.onFocus}
                required={true}
                minLength={2}
                className={form.user.givenName ? (error === "auth.validation.givenName" ? "is-invalid" : "") : ""}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="6" className="text-left">
          <span className="text-sm text-muted text-uppercase">{t("users.fields.surname")}*</span>
          <FormGroup className={`${focus.isFocused("user.surname") ? "focused" : ""}`}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-user"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={t("users.fields.surname")}
                type="text"
                name="user.surname"
                value={form.user.surname}
                onChange={onChangeForm}
                onFocus={focus.onFocus}
                required={true}
                minLength={2}
                className={form.user.surname ? (error === "auth.validation.surname" ? "is-invalid" : "") : ""}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6" className="text-left">
          <span className="text-sm text-muted text-uppercase">{t("users.fields.email")}*</span>
          <FormGroup className={`${focus.isFocused("user.email") ? "focused" : ""}`}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-at"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={t("users.fields.email")}
                type="email"
                name="user.email"
                value={form.user.email}
                onChange={onChangeForm}
                onFocus={focus.onFocus}
                required={true}
                minLength={3}
                className={form.user.email ? (error === "auth.validation.email" ? "is-invalid" : "") : ""}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="6" className="text-left">
          <span className="text-sm text-muted text-uppercase">{t("users.fields.phone")}</span>
          <FormGroup className={`${focus.isFocused("user.phone") ? "focused" : ""}`}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-phone"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={t("users.fields.phone")}
                type="phone"
                name="user.phone"
                value={form.user.phone}
                onChange={onChangeForm}
                onFocus={focus.onFocus}
                minLength={8}
                className={form.user.phone ? (error === "auth.validation.phone" ? "is-invalid" : "") : ""}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6" className="text-left">
          <span className="text-sm text-muted text-uppercase">{t("users.fields.password")}*</span>
          <FormGroup className={`${focus.isFocused("user.password") ? "focused" : ""}`}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-lock"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={t("users.fields.password")}
                type="password"
                name="user.password"
                value={form.user.password}
                onChange={onChangeForm}
                onFocus={focus.onFocus}
                required={true}
                minLength={8}
                className={form.user.password ? (error === "auth.validation.password" ? "is-invalid" : "") : ""}
              />
            </InputGroup>
            <PasswordStrengthBar
              password={form.user?.password}
              scoreWords={[
                t("users.password.weak"),
                t("users.password.weak"),
                t("users.password.enough"),
                t("users.password.strong"),
                t("users.password.veryStrong"),
              ]}
              shortScoreWord={t("users.password.tooShort")}
              minLength={8}
              onChangeScore={onChangePasswordScore}
            />
          </FormGroup>
        </Col>
        <Col md="6" className="text-left">
          <span className="text-sm text-muted text-uppercase">{t("users.fields.repeatPassword")}*</span>
          <FormGroup className={`${focus.isFocused("user.repeatPassword") ? "focused" : ""}`}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-lock"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={t("users.fields.repeatPassword")}
                type="password"
                name="user.repeatPassword"
                value={form.user.repeatPassword}
                onChange={onChangeForm}
                onFocus={focus.onFocus}
                required={true}
                minLength={8}
                className={form.user.repeatPassword ? (error === "auth.validation.passwordsNotThatSame" ? "is-invalid" : "") : ""}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
    </>
  )
}

function RegisterStepThird({ form, setForm, onChangeForm, preferredDelivery, setPreferredDelivery, isBusiness, readOnl, error, ...props }) {
  const { t } = useTranslation();
  const focus = useFocus();
  return (
    <>
      <h3 className="mb-5">{t('auth.register.steps.3')}</h3>
      <Row className="mb-5">
        <Col sm="2" md="1" className="text-left">
          <label
            className="custom-toggle"
          >
            <input
              type="checkbox"
              name="system"
              checked={preferredDelivery}
              onChange={(event) => setPreferredDelivery(event.target.checked)}
            />
            <span className="custom-toggle-slider rounded-circle bg-white"></span>
          </label>
        </Col>
        <Col sm="10" md="11" className="text-left font-medium pt-1">
          <h5>{t('auth.register.preferredDelivery')}</h5>
        </Col>
      </Row>
      {preferredDelivery && (
        <Row>
          <Col md="12" className="text-left">
            <span className="text-sm text-muted text-uppercase">{t("customers.shippingAddress.person")}</span>
            <FormGroup className={`${focus.isFocused("shippingAddress.person") ? "focused" : ""}`}>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-user"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder={t("customers.shippingAddress.person")}
                  type="text"
                  name="shippingAddress.person"
                  value={form.shippingAddress?.person}
                  onChange={onChangeForm}
                  onFocus={focus.onFocus}
                  required={true}
                  minLength={2}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md="12" className="text-left">
            <span className="text-sm text-muted text-uppercase">{t("customers.shippingAddress.line1")}*</span>
            <FormGroup className={`${focus.isFocused("shippingAddress.line1") ? "focused" : ""}`}>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-building"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder={t("customers.shippingAddress.line1")}
                  type="text"
                  name="shippingAddress.line1"
                  value={form.shippingAddress?.line1}
                  onChange={onChangeForm}
                  onFocus={focus.onFocus}
                  required={true}
                  minLength={2}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md="12" className="text-left">
            <span className="text-sm text-muted text-uppercase">{t("customers.shippingAddress.line2")}</span>
            <FormGroup className={`${focus.isFocused("shippingAddress.line2") ? "focused" : ""}`}>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-building"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder={t("customers.shippingAddress.line2")}
                  type="text"
                  name="shippingAddress.line2"
                  value={form.shippingAddress?.line2}
                  onChange={onChangeForm}
                  onFocus={focus.onFocus}
                  minLength={2}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md="3" className="text-left">
            <span className="text-sm text-muted text-uppercase">{t("customers.shippingAddress.postalCode")}*</span>
            <FormGroup className={`${focus.isFocused("shippingAddress.postalCode") ? "focused" : ""}`}>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-envelope"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder={t("customers.shippingAddress.postalCode")}
                  type="text"
                  name="shippingAddress.postalCode"
                  value={form.shippingAddress?.postalCode}
                  onChange={onChangeForm}
                  onFocus={focus.onFocus}
                  required={true}
                  minLength={2}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md="9" className="text-left">
            <span className="text-sm text-muted text-uppercase">{t("customers.shippingAddress.city")}*</span>
            <FormGroup className={`${focus.isFocused("shippingAddress.city") ? "focused" : ""}`}>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-map-marker"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder={t("customers.shippingAddress.city")}
                  type="text"
                  name="shippingAddress.city"
                  value={form.shippingAddress?.city}
                  onChange={onChangeForm}
                  onFocus={focus.onFocus}
                  required={true}
                  minLength={2}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md="12" className="text-left">
            <span className="text-sm text-muted text-uppercase">{t("customers.shippingAddress.countryCode")}*</span>
            <FormGroup className={`${focus.isFocused("shippingAddress.countryCode") ? "focused" : ""}`}>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-globe"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <CountrySelector
                  name="shippingAddress.countryCode"
                  value={form.shippingAddress?.countryCode}
                  onChange={onChangeForm}
                  onFocus={focus.onFocus}
                  required={true}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md="6" className="text-left">
            <span className="text-sm text-muted text-uppercase">{t("customers.shippingAddress.email")}</span>
            <FormGroup className={`${focus.isFocused("shippingAddress.email") ? "focused" : ""}`}>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-at"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder={t("customers.shippingAddress.email")}
                  type="text"
                  value={form.shippingAddress?.email}
                  name="shippingAddress.email"
                  onChange={onChangeForm}
                  onFocus={focus.onFocus}
                  minLength={2}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md="6" className="text-left">
            <span className="text-sm text-muted text-uppercase">{t("customers.shippingAddress.phone")}</span>
            <FormGroup className={`${focus.isFocused("shippingAddress.phone") ? "focused" : ""}`}>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-phone"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder={t("customers.shippingAddress.phone")}
                  type="text"
                  name="shippingAddress.phone"
                  value={form.shippingAddress?.phone}
                  onChange={onChangeForm}
                  onFocus={focus.onFocus}
                  minLength={2}
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
      )}
    </>
  )
}

function RegisterStepFourth({ form, setForm, onChangeForm, overwriteBillingInfo, setOverwriteBillingInfo, isBusiness, readOnly, error, ...props }) {
  const { t } = useTranslation();
  const focus = useFocus();
  const readOnlyLocal = useMemo(() => readOnly || !overwriteBillingInfo, [readOnly, overwriteBillingInfo]);
  return (
    <>
      <h3 className="mb-5">{t('auth.register.steps.4')}</h3>
      <Row className="mb-5">
        <Col sm="2" md="1" className="text-left">
          <label
            className="custom-toggle"
          >
            <input
              type="checkbox"
              name="system"
              checked={overwriteBillingInfo}
              onChange={(event) => setOverwriteBillingInfo(event.target.checked)}
            />
            <span className="custom-toggle-slider rounded-circle bg-white"></span>
          </label>
        </Col>
        <Col sm="10" md="11" className="text-left font-medium pt-1">
          <h5>{t('auth.register.overwriteBillingInfo')}</h5>
        </Col>
      </Row>
      <Row>
        <Col md="12" className="text-left">
          <span className="text-sm text-muted text-uppercase">{t("customers.billingInfo.vatID")}*</span>
          <FormGroup className={`${focus.isFocused("billingInfo.vatID") ? "focused" : ""}`}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-percent"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={t("customers.billingInfo.vatID")}
                type="text"
                name="billingInfo.vatID"
                value={form.billingInfo?.vatID}
                onChange={onChangeForm}
                onFocus={focus.onFocus}
                required={true}
                minLength={8}
                readOnly={readOnlyLocal}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="12" className="text-left">
          <span className="text-sm text-muted text-uppercase">{t("customers.billingInfo.buyerName")}*</span>
          <FormGroup className={`${focus.isFocused("billingInfo.buyerName") ? "focused" : ""}`}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-percent"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={t("customers.billingInfo.buyerName")}
                type="text"
                name="billingInfo.buyerName"
                value={form.billingInfo?.buyerName}
                onChange={onChangeForm}
                onFocus={focus.onFocus}
                required={true}
                minLength={8}
                readOnly={readOnlyLocal}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="12" className="text-left">
          <span className="text-sm text-muted text-uppercase">{t("customers.shippingAddress.line1")}*</span>
          <FormGroup className={`${focus.isFocused("billingInfo.line1") ? "focused" : ""}`}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-building"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={t("customers.shippingAddress.line1")}
                type="text"
                name="billingInfo.line1"
                value={form.billingInfo?.line1}
                onChange={onChangeForm}
                onFocus={focus.onFocus}
                required={true}
                minLength={2}
                readOnly={readOnlyLocal}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="12" className="text-left">
          <span className="text-sm text-muted text-uppercase">{t("customers.shippingAddress.line2")}</span>
          <FormGroup className={`${focus.isFocused("billingInfo.line2") ? "focused" : ""}`}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-building"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={t("customers.shippingAddress.line2")}
                type="text"
                name="billingInfo.line2"
                value={form.billingInfo?.line2}
                onChange={onChangeForm}
                onFocus={focus.onFocus}
                minLength={2}
                readOnly={readOnlyLocal}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="3" className="text-left">
          <span className="text-sm text-muted text-uppercase">{t("customers.shippingAddress.postalCode")}*</span>
          <FormGroup className={`${focus.isFocused("billingInfo.postalCode") ? "focused" : ""}`}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-envelope"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={t("customers.shippingAddress.postalCode")}
                type="text"
                name="billingInfo.postalCode"
                value={form.billingInfo?.postalCode}
                onChange={onChangeForm}
                onFocus={focus.onFocus}
                required={true}
                minLength={2}
                readOnly={readOnlyLocal}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="9" className="text-left">
          <span className="text-sm text-muted text-uppercase">{t("customers.shippingAddress.city")}*</span>
          <FormGroup className={`${focus.isFocused("billingInfo.city") ? "focused" : ""}`}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-map-marker"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={t("customers.shippingAddress.city")}
                type="text"
                name="billingInfo.city"
                value={form.billingInfo?.city}
                onChange={onChangeForm}
                onFocus={focus.onFocus}
                required={true}
                minLength={2}
                readOnly={readOnlyLocal}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="12" className="text-left">
          <span className="text-sm text-muted text-uppercase">{t("customers.shippingAddress.countryCode")}*</span>
          <FormGroup className={`${focus.isFocused("billingInfo.countryCode") ? "focused" : ""}`}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-map-marker"></i>
                </InputGroupText>
              </InputGroupAddon>
              <CountrySelector
                name="billingInfo.countryCode"
                value={form.billingInfo?.countryCode}
                onChange={onChangeForm}
                onFocus={focus.onFocus}
                required={true}
                readOnly={readOnlyLocal}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="6" className="text-left">
          <span className="text-sm text-muted text-uppercase">{t("customers.billingInfo.email")}</span>
          <FormGroup className={`${focus.isFocused("billingInfo.email") ? "focused" : ""}`}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-at"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={t("customers.billingInfo.email")}
                type="text"
                name="billingInfo.email"
                value={form.billingInfo?.email}
                onChange={onChangeForm}
                onFocus={focus.onFocus}
                minLength={2}
                readOnly={readOnlyLocal}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
    </>
  )
}

function Register() {
  const { t, i18n } = useTranslation();
  const [state, setState] = useState({
    user: undefined,
    billingInfo: undefined,
    shippingAddress: undefined
  });
  const user = useUser();
  const [passwordScore, onChangePasswordScore] = useState(0);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [preferredDelivery, setPreferredDelivery] = useState(false);
  const [overwriteBillingInfo, setOverwriteBillingInfo] = useState(true);
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [form, setForm] = useState({
    user: {},
    billingInfo: {},
    shippingAddress: {}
  });
  const [isBusiness, setIsBusiness] = useState(undefined);

  const step = useMemo(() => {
    if (isBusiness === undefined) return 1;
    if (
      form.user.givenName === undefined ||
      form.user.surname === undefined ||
      form.user.email === undefined ||
      form.user.password === undefined ||
      form.user.repeatPassword === undefined
    ) return 2;
    return 4;
  }, [isBusiness, form]);

  useEffect(() => {
    const result = validateForm(form, isBusiness, preferredDelivery);
    if (result === "") {
      setError(null);
      setIsValid(true);
    } else {
      setError(result);
      setIsValid(false);
    }
  }, [isBusiness, form, preferredDelivery]);

  useEffect(() => {
    if (!wasSubmitted) return;
    if (error) {
      window.scrollTo(0, 0);
      setWasSubmitted(true);
    }
  }, [error, wasSubmitted]);

  useEffect(() => {
    setForm({
      ...form,
      shippingAddress: {
        ...form.shippingAddress,
        email: form.user?.email
      },
      billingInfo: {
        ...form.billingInfo,
        email: form.user?.email
      }
    });
  }, [form.user.email]);

  useEffect(() => {
    setForm({
      ...form,
      shippingAddress: {
        ...form.shippingAddress,
        phone: form.user?.phone
      }
    });
  }, [form.user.phone]);

  useEffect(() => {
    setForm({
      ...form,
      shippingAddress: {
        ...form.shippingAddress,
        person: [form.user?.givenName ?? '', form.user?.surname ?? ''].join(' ')
      }
    });
  }, [form.user.givenName, form.user.surname]);

  const readOnly = useMemo(() => loading !== false, [loading]);
  const disabled = useMemo(() => loading !== false || !isValid, [loading, isValid]);

  const onChangeForm = (event) => {
    const { name, value } = event.target;
    setForm(_.set({ ...form }, name, value));
  };

  const createShippingAddress = (headers) => {
    apiDriver
      .put(
        `${config.api.orders}${i18n.resolvedLanguage}/ShippingAddresses/`,
        { ...form.shippingAddress, default: true },
        headers,
      )
      .subscribe({
        next: (response) => onCreateShippingAddress(response, headers),
        error: () => setError(t("auth.errors.shippingAddress")),
        complete: () => setLoading(false),
      });
  }

  const onCreateShippingAddress = (shippingAddressResponse, headers) => {
    if (isBusiness) {
      createBillingInfo(headers);
    } else {
      onEndSignUp();
    }
  }

  const createBillingInfo = (headers) => {
    apiDriver
      .put(
        `${config.api.orders}${i18n.resolvedLanguage}/BillingInfos/`,
        { ...form.billingInfo, default: true, isBusiness: true },
        headers,
      )
      .subscribe({
        next: (response) => onCreateBillingInfo(response, headers),
        error: () => setError(t("auth.errors.billingInfo")),
        complete: () => setLoading(false),
      });
  }

  const onCreateBillingInfo = (billingInfoResponse, headers) => {
    onEndSignUp();
  }

  const onEndSignUp = () => {
    dispatch(authActions.login(form.user.email, form.user.password));
    setLoading(false);
  }

  const createUser = () => {
    setError(null);
    apiDriver
      .post(config.api.iam + "Authentication/SignUp", {
        ...form.user,
        culture: i18n.resolvedLanguage || "pl",
        username: form.user.email,
      })
      .subscribe({
        next: onCreateUser,
        error: (err) => {
          setError(t("auth.errors.register.content") + err.response[0].description);
          setLoading(false)
        }
      });
  };

  const onCreateUser = (signUpResponse) => {
    const token = signUpResponse.response.token;
    const headers = {
      Authorization: token ? `Bearer ${token}` : null,
    };

    if (preferredDelivery) {
      createShippingAddress(headers);
    } else {
      if (isBusiness) {
        createBillingInfo(headers);
      } else {
        onEndSignUp();
      }
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setWasSubmitted(true);
    if (!isValid) return;
    createUser();
    return false;
  };

  if (user) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Helmet>
        <title>{t("auth.register.registerTitle")} - Printweb.pl</title>
      </Helmet>
      <div className="bg-dark py-5">
        <Form onSubmit={onSubmit}>
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col className="px-5 mt-5 pt-5">
                  <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-white py-4">
                      <CardTitle className="text-center" tag="h4">
                        {t("auth.register.registerTitle")}
                      </CardTitle>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                      {error && wasSubmitted && (
                        <Alert color="danger">
                          <p>{t(error)}</p>
                        </Alert>
                      )}
                      {step >= 1 && <RegisterStepFirst
                        isBusiness={isBusiness}
                        setIsBusiness={setIsBusiness}
                        readOnly={readOnly}
                      />}
                      {step >= 2 && <RegisterStepSecond
                        form={form}
                        setForm={setForm}
                        onChangeForm={onChangeForm}
                        onChangePasswordScore={onChangePasswordScore}
                        setPreferredDelivery={setPreferredDelivery}
                        setOverwriteBillingInfo={setOverwriteBillingInfo}
                        isBusiness={isBusiness}
                        readOnly={readOnly}
                        error={error}
                      />}
                      {step >= 3 && <RegisterStepThird
                        form={form}
                        setForm={setForm}
                        onChangeForm={onChangeForm}
                        preferredDelivery={preferredDelivery}
                        setPreferredDelivery={setPreferredDelivery}
                        isBusiness={isBusiness}
                        readOnly={readOnly}
                        error={error}
                      />}
                      {isBusiness && step >= 4 && <RegisterStepFourth
                        form={form}
                        setForm={setForm}
                        onChangeForm={onChangeForm}
                        overwriteBillingInfo={overwriteBillingInfo}
                        setOverwriteBillingInfo={setOverwriteBillingInfo}
                        isBusiness={isBusiness}
                        readOnly={readOnly}
                        error={error}
                      />}
                      <Row>
                        <Col md={3}>
                          <Button type="reset" color="light" block>{t('actions.reset')}</Button>
                        </Col>
                        <Col md={3} className="offset-md-6">
                          <Button type="submit" color="primary" disabled={disabled} block>{t('auth.register.do')}</Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </Form>
      </div>
    </>
  )
}

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const validateForm = (form, isBusiness, preferredDelivery) => {
  if (!form.user.givenName || form.user.givenName.length < 3) {
    return "auth.validation.givenName";
  }
  if (!form.user.surname || form.user.surname.length < 3) {
    return "auth.validation.surname";
  }
  if (!form.user.email || form.user.email.length < 6 || !validateEmail(form.user.email)) {
    return "auth.validation.email";
  }
  if (!form.user.phone || form.user.phone.length < 6) {
    return "auth.validation.phone";
  }
  if (!form.user.password || form.user.password.length < 3) {
    return "auth.validation.password";
  }
  if (!form.user.repeatPassword || form.user.repeatPassword.length < 3) {
    return "auth.validation.repeatPassword";
  }
  if (form.user.password !== form.user.repeatPassword) {
    return "auth.validation.passwordsNotThatSame";
  }
  if (isBusiness) {
    if (form.businessName?.length < 3) {
      return "auth.validation.businessName";
    }
  }
  if (form.passwordScore < 2) {
    return "auth.validation.passwordToWeak";
  }
  if (form.user.password !== form.user.repeatPassword) {
    return "auth.validation.passwordsNotThatSame";
  }

  if (isBusiness) {
    const isBillingInfoValid = (
      form.billingInfo.line1?.length > 2 &&
      form.billingInfo.city?.length > 2 &&
      form.billingInfo.postalCode?.length > 2 &&
      form.billingInfo.countryCode?.length === 2 &&
      form.billingInfo.buyerName?.length > 2 &&
      form.billingInfo.vatID?.length > 2
    );

    if (!isBillingInfoValid) {
      return "auth.errors.billingInfo";
    }
  }

  if (preferredDelivery) {
    const isShippingAddressValid = (
      form.shippingAddress.line1?.length > 2 &&
      form.shippingAddress.city?.length > 2 &&
      form.shippingAddress.postalCode?.length > 2 &&
      form.shippingAddress.countryCode?.length === 2
    );

    if (!isShippingAddressValid) {
      return "auth.errors.shippingAddress";
    }
  }

  return "";
};

export default withRouter(Register);
