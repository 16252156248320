import React, { useState, useEffect } from "react";

import { Alert } from "reactstrap";

import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";

import apiDriver from "stores/api.driver";
import config from "config/global";
import { useTranslation } from "react-i18next";
import useUser from "hooks/useUser";

export default function RebateInfo(props) {
  const { t, i18n } = useTranslation();
  const { product } = props;
  const [rebate, setRebate] = useState(null);
  const user = useUser();

  useEffect(() => {
    apiDriver
      .get(`${config.api.orders}${i18n.resolvedLanguage}/Rebates`)
      .pipe(
        map((response) => {
          setRebate(
            response.response.find(
              (r) =>
                r.productId === product.id || (!r.productId && !r.categoryId),
            ) || null,
          );
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe(() => { });
  }, []);

  if (user == null) {
    return <React.Fragment />;
  }

  if (rebate) {
    return (
      <Alert color="success">
        <p>
          <b>{t("rebates.information.header")}</b>
        </p>
        <p>
          {t("rebates.information.content", {
            rate: rebate.rate,
            for: rebate.businessId
              ? t("rebates.information.business")
              : t("rebates.information.account"),
          })}
        </p>
        <p>{t("rebates.information.additional")}</p>
      </Alert>
    );
  } else {
    return <React.Fragment />;
  }
}
