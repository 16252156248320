import React, { useState } from "react";

import { Container, Row, Col, Button } from "reactstrap";
import RealizationTime from "./RealizationTime";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import RealizationTerm from "components/Cart/RealizationTerm";

function Summary(props) {
  const { t, i18n } = useTranslation();
  const { pricing, addToCart, addingToCart } = props;
  const [isDevOpen, setDevOpen] = useState(false);
  const user = useSelector(state => state.auth.user);
  const isAdmin = ['info@printweb.pl', 'dawid.martenczuk@h0pe.cloud'].includes(user?.email);
  return (
    <div className="position-fixed fixed-bottom bg-dark text-white">
      <Container fluid className="my-3">
        <Row>
          <Col xs={6} md={3} xl={2} className="offset-xl-1">
            <h6 className="text-white mb-0">
              {t("products.fields.realizationTerm")} *
            </h6>
            <RealizationTerm term={pricing.realizationTerm} />
            {" "}
            {pricing.realizationTime ? (
              <span>
                (<RealizationTime time={pricing.realizationTime} />)
              </span>
            ) : (
              ""
            )}
          </Col>
          <Col xs={6} md={2} xl={2}>
            <h6 className="text-white mb-0">{t("products.fields.amount")}</h6>
            {pricing.amount}
          </Col>
          <Col xs={6} md={2} xl={2}>
            <h6 className="text-white mb-0">{t("price.cost.net")}</h6>
            {pricing.costNet.toLocaleString(i18n.resolvedLanguage, {
              style: "currency",
              currency: "PLN",
            })}
          </Col>
          <Col xs={6} md={2} xl={2}>
            <h6 className="text-white mb-0">{t("products.fields.weight")}</h6>
            {(pricing.weight || 0).toLocaleString(i18n.resolvedLanguage, {
              minimumFractionDigits: 1,
            })}{" "}
            kg
          </Col>
          <Col xs={12} md={3} xl={2} className="text-right">
            <Button
              data-button="addToCart"
              color="primary"
              onClick={addToCart}
              disabled={addingToCart}
            >
              {t("products.actions.addToCart")}
            </Button>
            {isAdmin && pricing.feedback && (
              <Button
                data-button="addToCart"
                color="dark"
                onClick={() => setDevOpen(!isDevOpen)}
                disabled={addingToCart}
              >
                <i className="fas fa-cogs"></i>
              </Button>
            )}
          </Col>
        </Row>
        <div className={pricing.feedback && isDevOpen ? 'd-block' : 'd-none'}>
          <small>
            <small>
              {pricing.feedback.split("\n").map(function (item, idx) {
                return (
                  <span key={idx}>
                    {item}
                    <br />
                  </span>
                );
              })}
            </small>
          </small>
        </div>
        <small className="d-none">
          <small>* - {t("products.summaryInfo")} [[COUNTDOWN]]</small>
        </small>
      </Container>
    </div>
  );
}

export default Summary;
