import React, { useEffect, useState } from "react";

import { useLocation, Redirect } from "react-router-dom";

import {
  Card,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  Row,
  Col,
  Container,
} from "reactstrap";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import useUser from "hooks/useUser";
import TabData from "./Tabs/Data";
import TabSenderAddresses from "./Tabs/SenderAddresses";
import TabShippingAddresses from "./Tabs/ShippingAddresses";
import TabBillingInfos from "./Tabs/BillingInfos";
import TabDelete from "./Tabs/Delete";
import TabEmail from "./Tabs/Email";
import TabPassword from "./Tabs/Password";
import TabBusiness from "./Tabs/Business";
import TabCurrentOrders from "./Tabs/CurrentOrders";
import TabRealisedOrders from "./Tabs/RealisedOrders";
import TabPayments from "./Tabs/Payments";
import TabShippings from "./Tabs/Shippings";
import TabInvoices from "./Tabs/Invoices";

function Menu(props) {
  const { t } = useTranslation();
  const { activeTab, setActiveTab } = props;
  return (
    <Nav className="flex-column" role="tablist">
      <NavItem className="mt-5">
        <b>Orders</b>
      </NavItem>
      <hr className="line-primary my-3 p-0"></hr>
      <NavItem>
        <NavLink
          className={activeTab === "current" ? "active" : ""}
          href="#current"
          onClick={(e) => {
            e.preventDefault();
            setActiveTab("current");
          }}
        >
          <i className="fas fa-exclamation-circle"></i> &nbsp;
          {t("order.current")}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={activeTab === "realised" ? "active" : ""}
          href="#realised"
          onClick={(e) => {
            e.preventDefault();
            setActiveTab("realised");
          }}
        >
          <i className="fas fa-check-circle"></i> &nbsp;
          {t("order.realised")}
        </NavLink>
      </NavItem>
      <hr className="line-primary my-3 p-0"></hr>
      <NavItem>
        <NavLink
          className={activeTab === "customPricings" ? "active" : ""}
          href="#customPricings"
          onClick={(e) => {
            e.preventDefault();
            setActiveTab("customPricings");
          }}
        >
          <i className="fas fa-bars"></i> {t("customPricing.customPricings")}
        </NavLink>
      </NavItem>
      <NavItem className="mt-5">
        <b>{t('order.accountancyAndShipping')}</b>
      </NavItem>
      <hr className="line-primary my-3 p-0"></hr>
      <NavItem>
        <NavLink
          className={activeTab === "payments" ? "active" : ""}
          href="#payments"
          onClick={(e) => {
            e.preventDefault();
            setActiveTab("payments");
          }}
        >
          <i className="fas fa-credit-card"></i> {t("order.payment.payments")}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={activeTab === "invoices" ? "active" : ""}
          href="#invoices"
          onClick={(e) => {
            e.preventDefault();
            setActiveTab("invoices");
          }}
        >
          <i className="fas fa-file-invoice"></i> {t("invoices.invoices")}
        </NavLink>
      </NavItem>
      <hr className="line-primary my-3 p-0"></hr>
      <NavItem>
        <NavLink
          className={activeTab === "shippings" ? "active" : ""}
          href="#shippings"
          onClick={(e) => {
            e.preventDefault();
            setActiveTab("shippings");
          }}
        >
          <i className="fas fa-truck"></i> {t("cart.deliveryAndOrder")}
        </NavLink>
      </NavItem>
      <NavItem className="mt-5">
        <b>Address data</b>
      </NavItem>
      <hr className="line-primary my-3 p-0"></hr>
      <NavItem>
        <NavLink
          className={activeTab === "billingInfos" ? "active" : ""}
          href="#billingInfos"
          onClick={(e) => {
            e.preventDefault();
            setActiveTab("billingInfos");
          }}
        >
          <i className="fas fa-credit-card"></i>{" "}
          {t("customers.billingInfo.billingInfo")}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={activeTab === "shippingAddresses" ? "active" : ""}
          href="#shippingAddresses"
          onClick={(e) => {
            e.preventDefault();
            setActiveTab("shippingAddresses");
          }}
        >
          <i className="fas fa-truck"></i>{" "}
          {t("customers.shippingAddress.shippingAddresses")}
        </NavLink>
        <NavLink
          className={activeTab === "senderAddresses" ? "active" : ""}
          href="#senderAddresses"
          onClick={(e) => {
            e.preventDefault();
            setActiveTab("senderAddresses");
          }}
        >
          <i className="fas fa-truck"></i>{" "}
          {t("customers.senderAddress.senderAddresses")}
        </NavLink>
      </NavItem>
      <NavItem className="mt-5">
        <b>My account</b>
      </NavItem>
      <hr className="line-primary my-3 p-0"></hr>
      <NavItem>
        <NavLink
          className={activeTab === "data" ? "active" : ""}
          href="#data"
          onClick={(e) => {
            e.preventDefault();
            setActiveTab("data");
          }}
        >
          <i className="fas fa-address-book"></i> {t("users.myData")}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={activeTab === "business" ? "active" : ""}
          href="#business"
          onClick={(e) => {
            e.preventDefault();
            setActiveTab("business");
          }}
        >
          <i className="fas fa-building"></i> {t("business.business")}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={activeTab === "notifications" ? "active" : ""}
          href="#notifications"
          onClick={(e) => {
            e.preventDefault();
            setActiveTab("notifications");
          }}
        >
          <i className="fas fa-bell"></i> {t("notifications.notifications")}
        </NavLink>
      </NavItem>
      <hr className="line-primary my-3 p-0"></hr>
      <NavItem>
        <NavLink
          className={activeTab === "password" ? "active" : ""}
          href="#password"
          onClick={(e) => {
            e.preventDefault();
            setActiveTab("password");
          }}
        >
          <i className="fas fa-lock"></i> {t("users.password.change")}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={activeTab === "email" ? "active" : ""}
          href="#email"
          onClick={(e) => {
            e.preventDefault();
            setActiveTab("email");
          }}
        >
          <i className="fas fa-envelope"></i> {t("users.email.change")}
        </NavLink>
      </NavItem>
      <hr className="line-primary my-3 p-0"></hr>
      <NavItem>
        <NavLink
          className={
            activeTab === "delete" ? "active text-danger" : "text-danger"
          }
          href="#delete"
          onClick={(e) => {
            e.preventDefault();
            setActiveTab("delete");
          }}
        >
          <i className="fas fa-trash"></i> {t("users.actions.delete")}
        </NavLink>
      </NavItem>
    </Nav>
  );
}

function Panel() {
  const { t } = useTranslation();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    location.hash ? location.hash.substring(1) : "data",
  );
  const onChangeTab = (name) => {
    setActiveTab(name);
    window.location.hash = name;
  }
  const user = useUser();

  useEffect(() => {
    console.log(location.hash)
    if (location.hash) setActiveTab(location.hash.substring(1));
  }, [location.hash]);

  if (!user)
    return <Redirect to="/" />

  return (
    <>
      <Helmet>
        <title>{t("users.panel.panel")} - Printweb.pl</title>
      </Helmet>
      <Container fluid>
        <Card className="bg-white my-5">
          <Row>
            <Col md={4} lg={3} xl={2}>
              <div className="section">
                <section className="text-center">
                  <h3 className="title mt-4">
                    {user.givenName} {user.surname}
                  </h3>
                </section>
                <section>
                  <br></br>
                  <Menu activeTab={activeTab} setActiveTab={onChangeTab} />
                </section>
              </div>
            </Col>
            <Col className="ml-auto" >
              <div className="section pr-5">
                <TabContent activeTab={activeTab}>
                  {activeTab === 'data' && <TabData />}
                  {activeTab === 'business' && <TabBusiness />}
                  {activeTab === 'billingInfos' && <TabBillingInfos />}
                  {activeTab === 'shippingAddresses' && <TabShippingAddresses />}
                  {activeTab === 'senderAddresses' && <TabSenderAddresses />}
                  {activeTab === 'password' && <TabPassword />}
                  {activeTab === 'email' && <TabEmail />}
                  {activeTab === 'delete' && <TabDelete />}
                  {activeTab === 'current' && <TabCurrentOrders />}
                  {activeTab === 'realised' && <TabRealisedOrders />}
                  {activeTab === 'payments' && <TabPayments />}
                  {activeTab === 'shippings' && <TabShippings />}
                  {activeTab === 'invoices' && <TabInvoices />}
                </TabContent>
              </div>
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  );
}

export default Panel;
