import React, { useState } from "react";

import { Button } from "reactstrap";

import apiDriver from "stores/api.driver";
import config from "config/global";
import { useTranslation } from "react-i18next";

export default function DotPayButton(props) {
  const { t } = useTranslation();
  const { payment } = props;
  const [loading, setLoading] = useState();

  const onClickPay = () => {
    setLoading(true);
    apiDriver
      .get(
        config.api.dotpay + "Payments/" + payment.id,
        { "Content-Type": "text/plain; charset=utf-8" },
        "text/plain",
      )
      .subscribe({
        next: (response) => window.location.href = response.response,
        error: () => alert('error'),
        complete: () => setLoading(false)
      });
  };
  return (
    <Button type="button" color="primary" size="sm" className="mb-2" onClick={onClickPay} disabled={loading}>
      <i className="fas fa-credit-card"></i>
      {loading ? (
        <i className="fas fa-spin fa-circle-notch"></i>
      ) : (
        t("order.payment.pay")
      )}{" "}DotPay{" "}
      <img
        src="https://www.dotpay.pl/developer/graphics/img/dotpay-square_logo.png"
        alt="DotPay"
        height="14px"
        className="ml-3 mr-2"
      />
    </Button>
  );
}
