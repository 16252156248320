import StatusBadge from "components/Orders/StatusBadge";
import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Row, Col, Button } from "reactstrap";
import BillingInfoModal from "./BillingInfoModal";
import NotesModal from "./NotesModal";
import OrderProduct from "./OrderProduct";
import ShippingAddressModal from "./ShippingAddressModal";
import apiDriver from "stores/api.driver";
import config from "config/global";
import PaymentButton from "./Payments/PaymentButton";
import { useTranslation } from "react-i18next";
import SenderAddressModal from "./SenderAddressModal";
import OrderValue from "components/Orders/OrderValue";
import _ from 'lodash'

const OrderInvoice = (props) => {
  const { invoice } = props;
  const { t } = useTranslation();
  const onClick = () => {
    apiDriver
      .download(
        `${invoice.number}.pdf`,
        `${config.api.accountancy}pl/Invoices/${invoice.id}/Download`,
      )
      .subscribe(() => { });
  }
  return (
    <Button size="sm" color="default" className="mb-2" onClick={onClick}>
      <i className="fa fa-file-pdf"></i>&nbsp;
      {t(`invoices.types.${invoice.type}`)}
    </Button>
  );
}

const Order = (props) => {
  const { t, i18n } = useTranslation();
  const { order, business, reload } = props;

  const [notes, setNotes] = useState(undefined);
  const [payments, setPayments] = useState(undefined);
  const [shippings, setShippings] = useState(undefined);
  const [value, setValue] = useState(undefined);
  const [invoices, setInvoices] = useState(undefined);

  const onCreateNote = (note) => setNotes([...notes, note]);

  const fetchOrderNotes = () => {
    let attributes = apiDriver.buildIndexAttributes({
      filters: {
        orderId: { filterVal: order.id, comparator: "=", filterType: "Guid" },
      },
    });
    apiDriver
      .get(`${config.api.orders}${i18n.resolvedLanguage}/OrderNotes${attributes}`)
      .subscribe({
        next: onFetchOrderNotes,
        error: console.error
      });
  };
  const onFetchOrderNotes = (response) => {
    const result = response.response;
    setNotes(result);
  };

  const fetchOrderPayments = () => {
    const attributes = apiDriver.buildIndexAttributes({
      filters: {
        orderId: { filterVal: order.id, comparator: "=", filterType: "Guid" },
      },
    });
    apiDriver
      .get(`${config.api.orders}${i18n.resolvedLanguage}/Payments/${attributes}`)
      .subscribe({
        next: onFetchOrderPayments,
        error: console.error
      });
  };
  const onFetchOrderPayments = (response) => {
    const result = response.response;
    setPayments(result);
  };

  const fetchOrderShippings = () => {
    const attributes = apiDriver.buildIndexAttributes({
      filters: {
        orderId: { filterVal: order.id, comparator: "=", filterType: "Guid" },
      },
    });
    apiDriver
      .get(`${config.api.orders}${i18n.resolvedLanguage}/Shippings/${attributes}`)
      .subscribe({
        next: onFetchOrderShippings,
        error: console.error
      });
  };
  const onFetchOrderShippings = (response) => {
    const result = response.response;
    setShippings(result);
  };

  const fetchOrderValue = () => {
    apiDriver
      .get(`${config.api.orders}${i18n?.resolvedLanguage}/Orders/${order.id}/Value`)
      .subscribe({
        next: onFetchOrderValue
      });
  };
  const onFetchOrderValue = (response) => {
    const result = response.response;
    setValue(result);
  };

  const fetchOrderInvoices = () => {
    const attributes = apiDriver.buildIndexAttributes({
      filters: {
        externalTags: {
          filterVal: `OMS.OrderId=${order.id}`,
          filterType: "TEXT",
          comparator: "LIKE",
          caseSensitive: false,
          filterKey: "externalTags",
        },
      },
    });
    apiDriver
      .get(`${config.api.accountancy}${i18n?.resolvedLanguage}/Invoices/${attributes}`)
      .subscribe({
        next: onFetchOrderInvoices
      });
  };
  const onFetchOrderInvoices = (response) => {
    const result = response.response;
    setInvoices(result);
  };


  useEffect(() => {
    if (value === undefined) {
      fetchOrderValue();
    }
  }, [value]);

  useEffect(() => {
    if (notes === undefined) {
      fetchOrderNotes();
    }
  }, [notes]);

  useEffect(() => {
    if (payments === undefined) {
      fetchOrderPayments();
    }
  }, [payments]);

  useEffect(() => {
    if (shippings === undefined) {
      fetchOrderShippings();
    }
  }, [shippings]);

  useEffect(() => {
    if (invoices === undefined) {
      fetchOrderInvoices();
    }
  }, [invoices]);

  return (
    <Card>
      <CardHeader>
        <div className="clearfix">
          <div className="float-left" title={order.id}>
            <div>
              <h4>{t("order.order")} {order.number}{" "}<StatusBadge status={order.status} /></h4>
            </div>
            {business ? (
              <div>
                <small>
                  {order.user.givenName} {order.user.surname} ({order.user.username})
                </small>
              </div>
            ) : (
              <React.Fragment />
            )}
          </div>
          <div className="float-right">
            {notes !== undefined &&
              <NotesModal
                title={`${t("order.order")} ${order.number}`}
                notes={notes}
                buttonProps={{ size: "sm", color: "default" }}
                order={order}
                onCreateNote={onCreateNote}
              />}
          </div>
        </div>
      </CardHeader>
      <CardBody>
        {order?.products?.map((orderProduct) => (
          <OrderProduct
            key={orderProduct.id}
            orderProduct={orderProduct}
            order={order}
            reload={reload}
            onCreateNote={onCreateNote}
          />
        ))}
        <Row>
          <Col md="8" className="text-primary">
            {invoices?.sort((a, b) => a.type - b.type).slice(0, 1).map(invoice => <><OrderInvoice key={invoice.id} invoice={invoice} /></>)}
            {order.senderAddress && <><SenderAddressModal address={order.senderAddress} /></>}
            {order.shippingAddress && <><ShippingAddressModal address={order.shippingAddress} /></>}
            {order.billingInfo && <><BillingInfoModal billingInfo={order.billingInfo} /></>}
            {shippings?.filter(s => s.trackingNumber && s.trackingLink)?.map(s => <a key={s.trackingNumber} href={s.trackingLink} target="_blank" rel="noreferrer"><Button size="sm" type="button" color="primary" className="mr-3 mb-2"><i className="fa fa-truck"></i> {s.method.translations[0]?.title} - {s.trackingNumber}</Button></a>)}
            {payments?.filter(p => p.status.isDefault).map(payment => <PaymentButton payment={payment} />)}
          </Col>
          <Col md="4" className="text-primary text-right">
            <Row>
              <Col sm="6" className="text-muted">
                {t("order.delivery")} / {t("order.collect")}:
              </Col>
              <Col sm="6">
                {order?.shippingMethod?.translations[0]?.title}
              </Col>
            </Row>
            <Row>
              <Col sm="6" className="text-muted">
                {t("order.payment.payment")}:
              </Col>
              <Col sm="6">
                {order?.paymentMethod?.translations[0]?.title}
              </Col>
            </Row>
            <Row>
              <Col sm="6" className="text-muted">
                {t('products.fields.weight')}:
              </Col>
              <Col sm="6">
                {new Intl.NumberFormat(i18n.resolvedLanguage, {
                  style: "unit",
                  unit: "kilogram",
                }).format(_.sum(order.products?.map(p => p.weight)))}
              </Col>
            </Row>
            <OrderValue value={value} products={order.products} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default Order;
