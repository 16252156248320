import { createStore, compose, combineReducers, applyMiddleware } from "redux";
import { createEpicMiddleware } from "redux-observable";

import session from "stores/session/reducers";

import auth from "stores/auth/reducers";
import cart from "stores/cart/reducers";

import * as localStorageService from "services/local-storage/local-storage.service";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  session,
  auth,
  cart,
});

export const epicMiddleware = createEpicMiddleware();
const persistedState = localStorageService.loadState();
export const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancer(applyMiddleware(epicMiddleware)),
);

store.subscribe(() => {
  const state = store.getState();
  let stateToSave: any = { auth: state.auth };
  if (!stateToSave.auth.user) {
    stateToSave.cart = { id: state.cart.id };
  }
  localStorageService.saveState(stateToSave);
});
