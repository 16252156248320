const Checking = {
    None: 'None',
    Basic: 'Basic',
    Extended: 'Extended'
};

export const CheckingPrices = {
    None: 0,
    Basic: 15,
    Extended: 36
};

export default Checking;