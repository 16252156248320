import React from "react";

import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

function PaginationButton(props) {
  const { page, currentPage, onChange } = props;

  return (
    <PaginationItem className={page === currentPage ? "active" : ""}>
      <PaginationLink onClick={() => onChange(page)}>{page}</PaginationLink>
    </PaginationItem>
  );
}

function PaginationComponent(props) {
  const { page, pages, onChange } = props;
  const offset = props.offset || 1;
  const min = Math.max(1, page - offset);
  const max = Math.min(pages, page + offset);
  let pageIndices = [];
  for (var i = min; i <= max; i++) {
    pageIndices.push(i);
  }

  return (
    <Pagination>
      {pageIndices.map((i) => (
        <PaginationButton
          key={i}
          page={i}
          currentPage={page}
          onChange={onChange}
        />
      ))}
    </Pagination>
  );
}

export default PaginationComponent;
