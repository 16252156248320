import * as constants from "./constants";
import ICart from "./model";

export const get = () => ({
  type: constants.GET,
});
export const getSuccess = (cart: ICart) => ({
  type: constants.GET_SUCCESS,
  payload: cart,
});
export const getFailure = (error: any) => ({
  type: constants.GET_FAILURE,
  payload: error,
});

export const update = (cart: ICart) => ({
  type: constants.UPDATE,
  payload: cart,
});

export const updateSuccess = (cart: ICart) => ({
  type: constants.UPDATE_SUCCESS,
  payload: cart,
});

export const submit = (cart: ICart) => ({
  type: constants.SUBMIT,
  payload: cart,
});

export const submitSuccess = (cart: ICart) => ({
  type: constants.SUBMIT_SUCCESS,
  payload: cart,
});

export const putProduct = (product: any) => ({
  type: constants.PUT_PRODUCT,
  payload: product,
});

export const putProductSuccess = (cart: ICart) => ({
  type: constants.PUT_PRODUCT_SUCCESS,
  payload: cart,
});

export const removeProduct = (id: string) => ({
  type: constants.REMOVE_PRODUCT,
  payload: id,
});

export const removeProductSuccess = (cart: ICart) => ({
  type: constants.REMOVE_PRODUCT_SUCCESS,
  payload: cart,
});

export const remove = () => ({
  type: constants.REMOVE,
});

export const removeSuccess = () => ({
  type: constants.REMOVE_SUCCESS,
});

export const clear = () => ({
  type: constants.CLEAR,
});

export const clearSuccess = () => ({
  type: constants.CLEAR_SUCCESS,
});
