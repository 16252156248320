import config from 'config/global';
import React, { useState } from 'react';
import { Alert, Input, Row, Col, Form, Button, Spinner, FormGroup, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import apiDriver from 'stores/api.driver';
import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { useTranslation } from 'react-i18next';
import PasswordStrengthBar from 'react-password-strength-bar';

const TabPassword = () => {
  const { t } = useTranslation();
  const [statusCode, setStatusCode] = useState(0);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    repeatPassword: "",
  });
  const onChangeFormData = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const feedback = () => {
    if (loading) {
      return (
        <div className="text-center">
          <Spinner />
        </div>
      );
    }
    switch (statusCode) {
      case 200:
        return <Alert color="success">{t("users.password.success")}</Alert>;
      case 400:
        return (
          <Alert color="danger">
            {t("auth.validation.passwordsNotThatSame")}
          </Alert>
        );
      case 401:
        return (
          <Alert color="danger">{t("auth.errors.incorrectPassword")}</Alert>
        );
      case 500:
        return <Alert color="danger">{t("errors.common.title")}</Alert>;
      default:
        return <React.Fragment />;
    }
  };
  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    await apiDriver
      .post(config.api.iam + "ChangePassword", formData)
      .pipe(
        map(() => {
          setStatusCode(200);
        }),
        catchError((error) => {
          setStatusCode(error.status);
          return of(error);
        }),
      )
      .subscribe(() => {
        setLoading(false);
      });
  };

  const [passwordFocus, setPasswordFocus] = useState("");
  const [repeatPasswordFocus, setRepeatPasswordFocus] = useState("");

  return (
    <div tabId="password">
      <header>
        <h2 className="text-uppercase">{t("users.password.change")}</h2>
      </header>
      <hr className="line-primary"></hr>
      <br></br>
      <Form onSubmit={onSubmit}>
        <div>
          {feedback()}
          <Row>
            <Col className="align-self-center" md="3">
              <label className="labels" htmlFor="#name">
                {t("users.password.current")}
              </label>
            </Col>
            <Col className="align-self-center" md="9">
              <FormGroup>
                <Input
                  id="name"
                  name="oldPassword"
                  required="required"
                  placeholder={t("users.password.current")}
                  type="password"
                  onChange={onChangeFormData}
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="align-self-center" md="3">
              <label className="labels" htmlFor="#name">
                {t("auth.newPassword")}
              </label>
            </Col>
            <Col className="align-self-center" md="9">
              <FormGroup className={passwordFocus}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder={t("auth.newPassword")}
                    type="password"
                    name="newPassword"
                    value={formData.newPassword}
                    onFocus={() => setPasswordFocus("focused")}
                    onBlur={() => setPasswordFocus("")}
                    onChange={onChangeFormData}
                  ></Input>
                </InputGroup>
                <PasswordStrengthBar
                  password={formData.newPassword}
                  scoreWords={[
                    t("users.password.weak"),
                    t("users.password.weak"),
                    t("users.password.enough"),
                    t("users.password.strong"),
                    t("users.password.veryStrong"),
                  ]}
                  shortScoreWord={t("users.password.tooShort")}
                  minLength={8}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="align-self-center" md="3">
              <label className="labels" htmlFor="#name">
                {t("users.fields.repeatPassword")}
              </label>
            </Col>
            <Col className="align-self-center" md="9">
              <FormGroup
                className={`${repeatPasswordFocus} ${
                  formData.repeatPassword
                    ? formData.repeatPassword === formData.newPassword
                      ? "has-success"
                      : "has-danger"
                    : ""
                }`}
              >
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder={t("users.fields.repeatPassword")}
                    className={`${
                      formData.repeatPassword
                        ? formData.repeatPassword === formData.newPassword
                          ? "is-valid"
                          : "is-invalid"
                        : ""
                    }`}
                    type="password"
                    name="repeatPassword"
                    value={formData.repeatPassword}
                    onFocus={() => setRepeatPasswordFocus("focused")}
                    onBlur={() => setRepeatPasswordFocus("")}
                    onChange={onChangeFormData}
                  ></Input>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="text-right">
              <Button color="primary" type="submit" disabled={loading}>
                {t("users.actions.changePassword")}
              </Button>
              <Button color="primary" outline type="reset">
                {t("actions.cancel")}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
}

export default TabPassword;