import Country from "components/AddressForm/Country";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function SenderAddress(props) {
  const { address } = props;
  return (
    <>
      {address.person ? (
        <div>
          <b>{address.person}</b>
        </div>
      ) : (
        <React.Fragment />
      )}
      <div>{address.line1}</div>
      {address.line2 ? <div>{address.line2}</div> : <React.Fragment />}
      <div>
        {address.postalCode} {address.city}
      </div>
      <div><Country countryCode={address.countryCode} /></div>
      {address.phone ? (
        <div>
          <i className="fas fa-phone"></i> &nbsp; {address.phone}
        </div>
      ) : (
        <React.Fragment />
      )}
      {address.email ? (
        <div>
          <i className="fas fa-envelope"></i> &nbsp; {address.email}
        </div>
      ) : (
        <React.Fragment />
      )}
    </>
  );
}

function SenderAddressModal(props) {
  const { t } = useTranslation();
  const { address } = props;
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button
        type="button"
        color="light"
        size="sm"
        className="mb-2"
        onClick={() => setModalOpen(!modalOpen)}
      >
        <i className="fas fa-address-book"></i>{" "}
        {t("customers.senderAddress.senderAddress")}
      </Button>
      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
          {t("customers.senderAddress.senderAddress")}
        </ModalHeader>
        <ModalBody>
          <SenderAddress address={address} />
        </ModalBody>
        <ModalFooter>
          <Button
            className="ml-auto"
            color="link"
            onClick={() => setModalOpen(!modalOpen)}
            type="button"
          >
            {t("actions.close")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default SenderAddressModal;
