import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";

import { Alert, Spinner } from "reactstrap";

import PageTitle from "./PageTitle";
import PageContent from "./PageContent";
import config from "config/global";
import apiDriver from "stores/api.driver";
import { useTranslation } from "react-i18next";

export default function PageEmbed(props) {
  const { i18n } = useTranslation();
  const { id, url, title, titlePlain, content, helmet } = props;

  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false); // eslint-disable-line no-unused-vars

  const loadPage = () => {
    setLoading(true);
    setError(false);
    apiDriver
      .get(`${config.api.cms}${i18n.resolvedLanguage}/Pages/${id || url}`)
      .subscribe({
        next: (r) => setPage(r.response),
        error: () => setError(true),
        complete: () => setLoading(false),
      });
  };

  useEffect(() => {
    if (!i18n.resolvedLanguage) {
      return;
    }
    setError(false);
    loadPage();
  }, [id, url, i18n, i18n?.resolvedLanguage]);

  if (!url && !id) {
    return (
      <>
        <Alert color="danger">No id, url provided for PageEmbed</Alert>
      </>
    );
  }

  return (
    <>
      {!!helmet && (
        <Helmet>
          <title>{page?.translations[0]?.metaTitle}</title>
          <meta
            name="description"
            content={page?.translations[0]?.metaDescription}
          />
          <meta name="keywords" content={page?.translations[0]?.metaKeywords} />
        </Helmet>
      )}
      {!!loading && (
        <div className="text-center">
          <Spinner />
        </div>
      )}
      {!!title && <PageTitle page={page} />}
      {!!titlePlain && (page?.translations[0]?.title || page?.id)}
      {!!content && <PageContent page={page} />}
    </>
  );
}
