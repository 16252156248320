export const GET = "h0pe/CART/GET/PENDING";
export const GET_SUCCESS = "h0pe/CART/GET/SUCCESS";
export const GET_FAILURE = "h0pe/CART/GET/FAILURE";
export const UPDATE = "h0pe/CART/UPDATE/PENDING";
export const UPDATE_SUCCESS = "h0pe/CART/UPDATE/SUCCESS";
export const PUT_PRODUCT = "h0pe/CART/PUTPRODUCT/PENDING";
export const PUT_PRODUCT_SUCCESS = "h0pe/CART/PUTPRODUCT/SUCCESS";
export const SUBMIT = "h0pe/CART/SUBMIT/PENDING";
export const SUBMIT_SUCCESS = "h0pe/CART/SUBMIT/SUCCESS";
export const REMOVE = "h0pe/CART/REMOVE/PENDING";
export const REMOVE_SUCCESS = "h0pe/CART/REMOVE/SUCCESS";
export const REMOVE_PRODUCT = "h0pe/CART/REMOVEPRODUCT/PENDING";
export const REMOVE_PRODUCT_SUCCESS = "h0pe/CART/REMOVEPRODUCT/SUCCESS";
export const CLEAR = "h0pe/CART/CLEAR/PENDING";
export const CLEAR_SUCCESS = "h0pe/CART/CLEAR/SUCCESS";
export const FAILURE = "h0pe/CART/FAILURE";
