import React from "react";

import { Card, CardBody, Row, Col } from "reactstrap";
import RealizationTime from "./RealizationTime";
import { useTranslation } from "react-i18next";

function Project(props) {
  const { t, i18n } = useTranslation();
  const { configuration, pages, project, toggleProject } = props;

  let price = configuration.basePrice;
  if (pages && configuration.pricePerPage) {
    if (configuration.pricePerPage.inside && pages.inside) {
      price += configuration.pricePerPage.inside * pages.inside;
    }
    if (configuration.pricePerPage.outside && pages.outside) {
      price += configuration.pricePerPage.outside * pages.outside;
    }
  }

  const onClick = () =>
    toggleProject({
      target: { name: "project", value: true, checked: !project },
    });

  return (
    <Card
      className={project ? "border border-primary" : "border"}
      style={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <CardBody>
        <Row>
          <Col xs={2} className="text-center">
            <i
              className={
                project
                  ? "fa fa-check-circle text-primary fa-2x"
                  : "far fa-circle text-muted fa-2x"
              }
            ></i>
          </Col>
          <Col xs={10}>
            <h6 className="m-0">
              <span className={project ? "text-primary" : ""}>
                {t("products.project.order")}
              </span>
            </h6>
            <span className={project ? "" : "text-muted"}>
              <small>
                <div>
                  {t("products.project.price.base")}:{" "}
                  {configuration?.basePrice?.toLocaleString(
                    i18n.resolvedLanguage,
                    { style: "currency", currency: "PLN" },
                  )}
                </div>
                {configuration.pricePerPage &&
                configuration.pricePerPage.inside ? (
                  <div>
                    {t("products.project.price.pageInside")}:{" "}
                    {configuration.pricePerPage.inside.toLocaleString(
                      i18n.resolvedLanguage,
                      { style: "currency", currency: "PLN" },
                    )}
                  </div>
                ) : (
                  <React.Fragment />
                )}
                {configuration.pricePerPage &&
                configuration.pricePerPage.outside ? (
                  <div>
                    {t("products.project.price.pageOutside")}:{" "}
                    {configuration.pricePerPage.outside.toLocaleString(
                      i18n.resolvedLanguage,
                      { style: "currency", currency: "PLN" },
                    )}
                  </div>
                ) : (
                  <React.Fragment />
                )}
                <div>
                  {t("products.project.price.summary")}:{" "}
                  {price.toLocaleString(i18n.resolvedLanguage, {
                    style: "currency",
                    currency: "PLN",
                  })}
                </div>
                {configuration.realizationTime ? (
                  <div>
                    {t("products.fields.realizationTime")}:{" "}
                    <RealizationTime time={configuration.realizationTime} />
                  </div>
                ) : (
                  <React.Fragment />
                )}
              </small>
            </span>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default Project;
