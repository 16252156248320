import React, { useState, useEffect } from "react";

import { Badge, Row, Col, Progress, Button } from "reactstrap";
import apiDriver from "stores/api.driver";

import { map, catchError, merge } from "rxjs/operators";
import { of } from "rxjs";
import { useTranslation } from "react-i18next";

export function FileSize(props) {
  const { value } = props;
  if (value < 1024) {
    return value + " B";
  }
  if (value < 1024 * 1024) {
    return Math.round((value / 1024) * 100) / 100 + " KB";
  }
  if (value < 1024 * 1024 * 1024) {
    return Math.round((value / 1024 / 1024) * 100) / 100 + " MB";
  }
  if (value < 1024 * 1024 * 1024 * 1024) {
    return Math.round((value / 1024 / 1024 / 1024) * 100) / 100 + " GB";
  }
  if (value < 1024 * 1024 * 1024 * 1024 * 1024) {
    return Math.round((value / 1024 / 1024 / 1024 / 1024) * 100) / 100 + " TB";
  }
}

function Uploader(props) {
  const { t } = useTranslation();
  const {
    id,
    name,
    large,
    url,
    isPresigned,
    headers,
    onUploadComplete,
    onUploadProgress,
    onUploadError,
    onChangeFile,
  } = props;

  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [presignedUrl, setPresignedUrl] = useState(null);

  const clearFileToUpload = () => {
    setFile(null);
    setFilePreview(null);
    setStatus(null);
    setProgress(0);
  };

  const onChangeFileEvent = (event) => {
    const { files } = event.target;
    const cancel = !files.length;
    if (cancel) return;
    const file = files[0];

    setStatus(null);
    setProgress(0);

    if (file.size < 10 * 1000 * 1000) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setFile(file);
        setFilePreview(reader.result);

        if (onChangeFile) onChangeFile(file);
      };
      reader.readAsDataURL(file);
    } else {
      setFile(file);
      if (onChangeFile) onChangeFile(file);
    }
  };

  const onClickUpload = (event) => {
    if (isPresigned) {
      getPresignedUrl();
    } else {
      onStartUploadEvent(event);
    }
  };

  const getPresignedUrl = (event) => {
    const request = apiDriver.put(
      url.replace("FILENAME", file.name),
      {},
      headers,
    );

    request
      .pipe(
        map((response) => {
          onGetPresignedUrl(event, response);
          return response.response;
        }),
        catchError((error) => {
          onUploadErrorEvent(error);
          return of(error);
        }),
      )
      .subscribe(() => { });
  };

  const pingDoneUrl = () => {
    const request = apiDriver.put(
      url.replace("FILENAME", file.name) + "?Done=1",
      {},
      headers,
    );

    request
      .pipe(
        map((response) => {
          onUploadCompleteEvent(response);
          return response.response;
        }),
        catchError((error) => {
          onUploadErrorEvent(error);
          return of(error);
        }),
      )
      .subscribe(() => {
        clearFileToUpload();
      });
  };

  const onGetPresignedUrl = (_event, response) => {
    setPresignedUrl(response.response);
  };

  const onStartUploadEvent = () => {
    let request, progressSubscriber;
    if (presignedUrl) {
      [request, progressSubscriber] = apiDriver.uploadPresigned(
        presignedUrl,
        file,
      );
    } else {
      [request, progressSubscriber] = apiDriver.upload(
        url,
        file,
        file.name,
        headers,
      );
    }

    setStatus(null);
    setProgress(0);
    setIsUploading(true);

    progressSubscriber.pipe(merge(request)).subscribe(
      (data) => {
        if (data.type === "progress") {
          onUploadProgressEvent(
            data,
            data.loaded / (data.totalSize || data.total),
          );
        }
        if (data.hasOwnProperty("status")) { // eslint-disable-line no-prototype-builtins
          if (presignedUrl) {
            pingDoneUrl();
          } else {
            onUploadCompleteEvent(data);
          }
        }
      },
      (error) => {
        onUploadErrorEvent(error);
      },
      (complete) => {
        if (presignedUrl) {
          pingDoneUrl();
        } else {
          onUploadCompleteEvent(complete);
        }
      },
    );
  };

  const onUploadProgressEvent = (response, progress) => {
    setProgress(progress);
    if (onUploadProgress) onUploadProgress(response, progress);
  };

  const onUploadCompleteEvent = (response) => {
    setStatus(200);
    setIsUploading(false);
    if (onUploadComplete) onUploadComplete(response);
  };

  const onUploadErrorEvent = (error) => {
    setStatus(error.status);
    setIsUploading(false);
    if (onUploadError) onUploadError(error);
  };

  const feedback = () => {
    if (status === null) {
      if (progress === 1) {
        return t("uploader.finishing");
      } else if (progress > 0) {
        return t("uploader.progress", { progress: Math.ceil(progress * 100) });
      }
      if (presignedUrl) {
        return t("uploader.preparing");
      }
    } else {
      switch (status) {
        case 200:
          return (
            <span>
              <i className="fas fa-check-circle text-success"></i>{" "}
              {t("uploader.success")}
            </span>
          );
        default:
          return (
            <span>
              <i className="fas fa-times-circle text-danger"></i>{" "}
              {t("uploader.error")}
            </span>
          );
      }
    }
  };

  useEffect(() => { }, []);

  useEffect(() => {
    if (presignedUrl && !isUploading) {
      onStartUploadEvent({});
    }
  }, [presignedUrl]);

  useEffect(() => {
    if (file) {
      onClickUpload();
    }
  }, [file]);

  if (file) {
    return (
      <React.Fragment>
        <Badge className="d-block w-100">
          <Row>
            <Col md={large ? 12 : 2} className="text-center">
              {filePreview ? (
                <img
                  src={filePreview}
                  className="thumbnail"
                  style={{ maxHeight: large ? "192px" : "42px" }}
                  alt={file.name}
                />
              ) : (
                <i className="fas fa-file fa-4x"></i>
              )}
            </Col>
            {large ? (
              <Col md={3} className="text-left">
                <Button
                  type="button"
                  color="secondary"
                  onClick={clearFileToUpload}
                  block
                >
                  <i className="fas fa-times text-danger"></i>
                </Button>
              </Col>
            ) : (
              <React.Fragment />
            )}
            <Col md={large ? 6 : 7} className="text-left">
              <div className="pb-1">
                {file.name}
                {large ? (
                  <React.Fragment />
                ) : (
                  <span onClick={clearFileToUpload} className="m-2 text-danger">
                    <i className="fas fa-times"></i>
                  </span>
                )}
              </div>
              <div className="pb-1">
                <small className="text-muted">
                  {file.type}, <FileSize value={file.size} />
                </small>
              </div>
              {isUploading ? (
                <Progress value={Math.ceil(progress * 100)}></Progress>
              ) : (
                <React.Fragment />
              )}
              {feedback()}
            </Col>
            <Col md={3} className="text-left">
              <Button
                type="button"
                color="secondary"
                onClick={onClickUpload}
                block
              >
                <i className="fas fa-upload text-primary"></i>
              </Button>
            </Col>
          </Row>
        </Badge>
      </React.Fragment>
    );
  }

  if (large) {
    return (
      <div>
        <label
          htmlFor={`${id}`}
          className="text-muted text-center py-5 w-100 border"
          style={{ cursor: "pointer" }}
        >
          <i className="fas fa-file fa-4x mb-3"></i>
          <br />
          <p>{t("uploader.select")}</p>
          <input
            id={id}
            name={name}
            type="file"
            onChange={onChangeFileEvent}
            className="d-none"
          />
        </label>
      </div>
    );
  }

  return (
    <div className="custom-file">
      <input
        className="custom-file-input"
        id={id}
        name={name}
        type="file"
        onChange={onChangeFileEvent}
      />
      <label className="custom-file-label" htmlFor={`#${id}`}>
        {t("uploader.select")}
      </label>
    </div>
  );
}

export default Uploader;
