/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";

import {
  Row,
  Col,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Tooltip,
} from "reactstrap";
import Uploader, { FileSize } from "./Uploader";
import config from "config/global";
import apiDriver from "stores/api.driver";

import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import CardSelect from "views/CardSelect";
import ProductPersonalization from "components/product/ProductPersonalization";
import { useTranslation } from "react-i18next";
import Checking, { CheckingPrices } from "components/product/Checking";
import RealizationTerm from "components/Cart/RealizationTerm";

function CheckingHint() {
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!isTooltipOpen);
  const id = "checking";
  return (
    <React.Fragment>
      <small style={{ fontSize: "50%", position: "relative", top: "-3px" }}>
        <i
          className="text-primary fas fa-question-circle ml-1"
          id={`tooltip${id}`}
        ></i>
      </small>
      <Tooltip
        placement="right"
        isOpen={isTooltipOpen}
        target={`tooltip${id}`}
        toggle={toggleTooltip}
      >
        Parę słów o sprawdzaniu plików...
      </Tooltip>
    </React.Fragment>
  );
}

function FilesHint() {
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!isTooltipOpen);
  const id = "files";
  return (
    <React.Fragment>
      <small style={{ fontSize: "50%", position: "relative", top: "-3px" }}>
        <i
          className="text-primary fas fa-question-circle ml-1"
          id={`tooltip${id}`}
        ></i>
      </small>
      <Tooltip
        placement="right"
        isOpen={isTooltipOpen}
        target={`tooltip${id}`}
        toggle={toggleTooltip}
      >
        Parę słów o pracy z plikami...
      </Tooltip>
    </React.Fragment>
  );
}

function NoteModal(props) {
  const { t } = useTranslation();
  const { isOpen, toggle, onChange, onSubmit, note, isLoading } = props;

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="">
      <ModalHeader toggle={toggle}>{t("order.actions.editNote")}</ModalHeader>
      <ModalBody>
        {isLoading ? (
          <Spinner />
        ) : (
          <Input
            type="textarea"
            rows="5"
            name="content"
            onChange={onChange}
            value={note ? note.content : ""}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onSubmit}>
          {t("actions.save")}
        </Button>{" "}
        <Button color="secondary" onClick={toggle}>
          {t("actions.close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

function CartProductMaterials(props) {
  const { t } = useTranslation();
  const { product, onDelete } = props;

  if (!product.materials) {
    return <React.Fragment />;
  }

  return (
    <div className="mb-2">
      <h6 className="text-uppercase">
        <small>{t("order.product.materials")}</small>
      </h6>
      {product.materials.map((material) => (
        <CartProductMaterial
          key={material.objectName}
          material={material}
          product={product}
          onDelete={onDelete}
        />
      ))}
    </div>
  );
}

function CartProductProjects(props) {
  const { t } = useTranslation();
  const { product, onDelete } = props;

  if (!product.projects) {
    return <React.Fragment />;
  }
  return (
    <div className="mb-2">
      <h6 className="text-uppercase">
        <small>{t("order.product.pdf")}</small>
      </h6>
      {product.projects.map((project) => (
        <CartProductProject
          key={project.objectName}
          project={project}
          product={product}
          onDelete={onDelete}
        />
      ))}
    </div>
  );
}

function CartProductMaterial(props) {
  const { i18n } = useTranslation();
  const { product, material, onDelete } = props;

  let tmp = material.objectName.split("/");
  const name = tmp[tmp.length - 1];

  const onClickDownload = () => {
    const url = `${config.api.orders}${i18n.resolvedLanguage}/OrderProducts/${product.id}/OrderProductMaterials/${name}/Download`;

    const request = apiDriver.get(url);
    request
      .pipe(
        map((response) => {
          const link = document.createElement("a");
          link.href = response.response;
          link.setAttribute("download", name);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe(() => { });
  };

  const onClickDelete = () => {
    const url = `${config.api.orders}${i18n.resolvedLanguage}/OrderProducts/${product.id}/OrderProductMaterials/${name}`;

    const request = apiDriver.remove(url);
    request
      .pipe(
        map(() => {
          onDelete(product.id, name);
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe(() => { });
  };

  return (
    <div>
      <span className="d-block text-truncate">
        <small>{name}</small>
      </span>
      <Row>
        <Col md={9}>
          <small className="text-muted">
            <FileSize value={material.stat.size} /> -{" "}
            {material.stat.lastModified.substring(0, 10)}
          </small>
        </Col>
        <Col md={3} className="text-right">
          <small>
            <i
              onClick={onClickDownload}
              className="fas fa-download text-primary"
            ></i>
          </small>{" "}
          &nbsp;
          <small>
            <i onClick={onClickDelete} className="fas fa-trash text-danger"></i>
          </small>
        </Col>
      </Row>
      <hr className="p-0 my-1 w-100" />
    </div>
  );
}

export function CartProductProject(props) {
  const { i18n } = useTranslation();
  const { product, project, onDelete } = props;

  const onClickDownload = () => {
    const url = `${config.api.orders}${i18n.resolvedLanguage}/OrderProducts/${product.id}/OrderProductProjects/${project.objectName}/Download`;

    const request = apiDriver.get(url);
    request
      .pipe(
        map((response) => {
          const link = document.createElement("a");
          link.href = response.response;
          link.setAttribute("download", project.objectName);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe(() => { });
  };

  const onClickDelete = () => {
    const url = `${config.api.orders}${i18n.resolvedLanguage}/OrderProducts/${product.id}/OrderProductProjects/${project.objectName}`;

    const request = apiDriver.remove(url);
    request
      .pipe(
        map(() => {
          onDelete(product.id, project.objectName);
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe(() => { });
  };

  return (
    <div>
      <span className="d-block text-truncate">
        <small>{project.objectName}</small>
      </span>
      <Row>
        <Col md={9}>
          <small className="text-muted">
            <FileSize value={project.stat.size} /> -{" "}
            {project.stat.lastModified.substring(0, 10)}
          </small>
        </Col>
        <Col md={3} className="text-right">
          <small>
            <i
              onClick={onClickDownload}
              className="fas fa-download text-primary"
            ></i>
          </small>{" "}
          &nbsp;
          <small>
            <i onClick={onClickDelete} className="fas fa-trash text-danger"></i>
          </small>
        </Col>
      </Row>
      <hr className="p-0 my-1 w-100" />
    </div>
  );
}

function CartProduct(props) {
  const { t, i18n } = useTranslation();
  const { product, deleteOrderProduct, updateOrderProduct } = props;
  const currentTranslation = product?.product?.translations[0];

  const [isNoteModalOpen, setNodeModalOpen] = React.useState(false);
  const toggleNoteModal = () => setNodeModalOpen(!isNoteModalOpen);
  const [note, setNote] = React.useState(null);
  const [isNoteLoading, setNoteLoading] = useState(false);

  const onChangeNote = (e) => {
    if (note === null) {
      setNote({
        ...{
          orderId: product.orderId,
          productId: product.id,
          title: t("order.notes"),
          content: "",
        },
        [e.target.name]: e.target.value,
      });
    } else {
      setNote({ ...note, [e.target.name]: e.target.value });
    }
  };

  const loadNote = () => {
    setNoteLoading(true);
    let attributes = apiDriver.buildIndexAttributes({
      filters: {
        productId: {
          filterKey: "productId",
          filterVal: product.id,
          comparator: "=",
          filterType: "Guid",
          caseSensitive: false,
        },
      },
    });
    let request = apiDriver.get(
      `${config.api.orders}${i18n.resolvedLanguage}/OrderNotes/` + attributes,
    );
    request
      .pipe(
        map((response) => response.response),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => {
        if (data && data.length >= 1) {
          setNote(data[0]);
        }
        setNoteLoading(false);
      });
  };

  const updateNote = () => {
    if (!note || !note.content) {
      return false;
    }
    setNoteLoading(true);
    let request;
    note.productId = product.id;
    note.orderId = product.orderId;
    if (note.id) {
      request = apiDriver.patch(
        `${config.api.orders}${i18n.resolvedLanguage}/OrderNotes/${note.id}`,
        note,
      );
    } else {
      request = apiDriver.post(
        `${config.api.orders}${i18n.resolvedLanguage}/OrderNotes/`,
        note,
      );
    }
    request
      .pipe(
        map((response) => response.response),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => {
        if (data && data.length >= 1) {
          setNote(data[0]);
        }
        setNoteLoading(false);
        setNodeModalOpen(false);
      });
  };

  const onChangeChecking = (e) => {
    const { value } = e.target;
    const { priceObj, loaded, shippings, projects, materials, notes, previews, ...newProduct } = { ...product };
    const { additivesLoaded, optionsLoaded, ...newPersonalization } = { ...newProduct.personalization };
    delete newProduct.product;
    const changed = {
      ...newProduct,
      personalization: { ...newPersonalization, checking: value },
    };
    apiDriver
      .patch(
        `${config.api.orders}${i18n.resolvedLanguage}/OrderProducts/${newProduct.id}`,
        { ...changed },
      )
      .subscribe((response) => {
        delete response.response.materials;
        delete response.response.projects;
        delete response.response.previews;
        updateOrderProduct(product.id, { ...product, ...response.response });
      });
  };

  const removeProject = (orderProductId, key) => {
    updateOrderProduct(product.id, {
      ...product,
      projects: [
        ...product.projects.filter((m) => {
          const tmp = m.objectName.split("/");
          return tmp[tmp.length - 1] !== key;
        }),
      ],
    });
  };

  const addProject = (response) => {
    updateOrderProduct(product.id, {
      ...product,
      projects: [...product.projects, response.response],
    });
  };

  const removeMaterial = (orderProductId, key) => {
    updateOrderProduct(product.id, {
      ...product,
      materials: [
        ...product.materials.filter((m) => {
          const tmp = m.objectName.split("/");
          return tmp[tmp.length - 1] !== key;
        }),
      ],
    });
  };

  const addMaterial = (response) => {
    updateOrderProduct(product.id, {
      ...product,
      materials: [...product.materials, response.response],
    });
  };

  useEffect(() => {
    loadNote();
  }, []);

  const thicknessProperties = {
    pages: { inside: 0, outside: 0 },
    thickness: { inside: 0, outside: 0 },
    result: 0,
  };
  if (product?.personalization != null) {
    thicknessProperties.pages = product.personalization.pages;

    if (thicknessProperties.pages.outside > 0) {
      thicknessProperties.thickness = {
        inside:
          product.personalization?.optionsLoaded?.find(
            (o) =>
              o.defineInsideThickness && o.attribute.isAffectingOnThickness & 1,
          )?.defineInsideThickness || 0,
        outside:
          product.personalization?.optionsLoaded?.find(
            (o) =>
              o.defineOutsideThickness &&
              o.attribute.isAffectingOnThickness & 2,
          )?.defineOutsideThickness || 0,
      };
      thicknessProperties.result =
        (thicknessProperties.pages.inside / 2) *
        thicknessProperties.thickness.inside +
        (thicknessProperties.pages.outside / 2) *
        thicknessProperties.thickness.outside;
    }
  }

  return (
    <>
      <hr />
      <div className="clearfix">
        <div className="float-left">
          <h2 className="text-uppercase text-primary">
            {currentTranslation?.title || product.title}
          </h2>
        </div>
        <div className="float-right">
          <Button
            color="danger"
            onClick={() => deleteOrderProduct(product.id)}
            outline
            size="sm"
          >
            <i className="fas fa-times"></i>
          </Button>
        </div>
      </div>
      <Row>
        <Col md={4}>
          <Row>
            <Col md={3}>
              <div
                className="full-background h-100 w-100"
                style={{
                  minHeight: "96px",
                  backgroundImage: `url(${config.api.products}${i18n.resolvedLanguage}/Products/${product?.product?.id}/Photo)`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                }}
              ></div>
            </Col>
            <Col md={9}>
              <p>
                {product?.personalization && <ProductPersonalization product={product} />}
              </p>
              {thicknessProperties?.result !== 0 && (
                <p>
                  {t("products.spine.width")}{" "}
                  {new Intl.NumberFormat().format(
                    Math.ceil(thicknessProperties.result * 10) / 10,
                  )}{" "}
                  mm
                </p>
              )}
              {note ? (
                <React.Fragment>
                  <h6 className="text-uppercase">{t("order.notes")}:</h6>
                  <p>{note.content}</p>
                </React.Fragment>
              ) : (
                <React.Fragment />
              )}

              <div>
                <Button color="dark" outline note={note} onClick={toggleNoteModal} block>
                  {note ? t("order.actions.editNote") : t("order.actions.addNote")}
                </Button>
                <NoteModal
                  isLoading={isNoteLoading}
                  note={note}
                  onChange={onChangeNote}
                  onSubmit={updateNote}
                  isOpen={isNoteModalOpen}
                  toggle={toggleNoteModal}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={3}>
          <div className="mb-3">
            <h6 className="text-uppercase">
              {t("order.actions.selectChecking")}
              <CheckingHint />
            </h6>
            <CardSelect
              id="checking"
              options={[
                {
                  id: Checking.None,
                  title: t("order.checking.none.title"),
                  description: `${t("order.checking.none.description")} - ${t('price.free')}`,
                },
                {
                  id: Checking.Basic,
                  title: t("order.checking.basic.title"),
                  description:
                    `${t("order.checking.basic.description")} - ${new Intl.NumberFormat(i18n.resolvedLanguage, {
                      style: "currency",
                      currency: "PLN"
                    }).format(CheckingPrices.Basic)}`,
                },
                {
                  id: Checking.Extended,
                  title: t("order.checking.extended.title"),
                  description:
                    `${t("order.checking.extended.description")} - ${new Intl.NumberFormat(i18n.resolvedLanguage, {
                      style: "currency",
                      currency: "PLN"
                    }).format(CheckingPrices.Extended)}`,
                },
              ]}
              value={product?.personalization?.checking}
              onChange={onChangeChecking}
              vertical={true}
            />
          </div>
        </Col>
        <Col md={3}>
          <h6 className="text-uppercase">
            {t("order.workWithFiles")}
            <FilesHint />
          </h6>
          {product?.personalization?.project ? (
            <>
              <CartProductMaterials product={product} onDelete={removeMaterial} />
              <Uploader
                id={`material${product.id}`}
                url={`${config.api.orders}${i18n.resolvedLanguage}/OrderProducts/${product.id}/OrderProductMaterials/FILENAME`}
                isPresigned={true}
                onUploadComplete={addMaterial}
                large={true}
              />
            </>
          ) : (
            <>
              <CartProductProjects product={product} onDelete={removeProject} />
              <Uploader
                id={`project${product.id}`}
                url={`${config.api.orders}${i18n.resolvedLanguage}/OrderProducts/${product.id}/OrderProductProjects/FILENAME`}
                isPresigned={true}
                onUploadComplete={addProject}
                large={true}
              />
            </>
          )}
        </Col>
        <Col md={2} className="text-right">
          <h6 className="text-uppercase">{t("products.fields.amount")}</h6>
          <h5 className="text-primary mb-4">
            {new Intl.NumberFormat(i18n.resolvedLanguage, {
              maximumFractionDigits: 0,
            }).format(product?.personalization?.amount)}{" "}
            {t('price.piece')}
          </h5>
          <h6 className="text-uppercase">
            {t("products.fields.realizationTerm")}
          </h6>
          <h5 className="text-primary mb-4"><RealizationTerm term={product.realizationTerm} /></h5>
          <h6 className="text-uppercase">
            {t("price.price.net")}
          </h6>
          <h5 className="text-primary mb-4">
            {new Intl.NumberFormat(i18n.resolvedLanguage, {
              style: "currency",
              currency: "PLN",
            }).format(product.priceObj.netCost)}
          </h5>
          <h6 className="text-uppercase">
            {t("price.price.gross")}
          </h6>
          <h5 className="text-primary mb-4">
            {new Intl.NumberFormat(i18n.resolvedLanguage, {
              style: "currency",
              currency: "PLN",
            }).format(product.priceObj.grossCost)}
          </h5>
        </Col>
      </Row>
    </>
  );
}

export default CartProduct;
