import Country from "components/AddressForm/Country";
import React from "react";
import { Row, Col, Input } from "reactstrap";

function BillingInfo(props) {
  const { info, input } = props;
  return (
    <Row>
      {input ? (
        <Col sm="2" md="1" xl="1">
          <Input type="radio" />
        </Col>
      ) : (
        <React.Fragment />
      )}
      <Col sm={input ? 10 : 12} md={input ? 11 : 12}>
        <div>
          <b>{info.buyerName}</b>
        </div>
        <div>vatID: {info.vatID}</div>
        <div>{info.line1}</div>
        <div>{info.line2}</div>
        <div>
          {info.postalCode} {info.city}
        </div>
        <div><Country countryCode={info.countryCode} /></div>
        <div>{info.email}</div>
      </Col>
    </Row>
  );
}

export default BillingInfo;
