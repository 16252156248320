import React from "react";

export default function PageTitle(props) {
  const { page } = props;

  return (
    <>
      <h3 className="display-3">{page?.translations[0]?.title || page?.id}</h3>
    </>
  );
}
