import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import * as authActions from "stores/auth/actions";

import {
  Container,
  Collapse,
  NavItem,
  NavbarBrand,
  Navbar,
  Nav,
  Button,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  NavbarToggler,
} from "reactstrap";
import Cart from "./Cart";
import PageLink from "components/CMS/PageLink";
import PageEmbed from "components/CMS/PageEmbed";
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";
import config from "config/global";
import useUser from "hooks/useUser";
import LocalizedLink from "components/LocalizedLink";
import CultureFlag from "./CultureFlag";

function NavbarContent(props) {
  const { t } = useTranslation();
  return (
    <>
      <Nav className="mr-auto" navbar>
        <NavItem className="d-md-none text-right">
          <div className="clearfix">
            <img
              src={`/images/logo-colour.png`}
              className="float-left w-50"
              alt={t("common.logo")}
            />
            <div className="float-right">
              <NavbarToggler onClick={() => props.toggleCollapse(false)} />
            </div>
          </div>
        </NavItem>
        <NavItem className="d-md-none d-xl-inline">
          <LocalizedLink className="nav-link" to="/">
            {t("home")}
          </LocalizedLink>
        </NavItem>
        <NavItem>
          <PageLink
            id="4b2f03f4-5bb8-4922-a1c3-3302c71abb0a"
            linkProps={{ className: "nav-link" }}
          />
        </NavItem>
        <NavItem>
          <LocalizedLink className="nav-link" to="/contact">
            <PageEmbed
              id="48f7e478-6f53-4caa-a35a-f6c792bc914b"
              titlePlain
            />
          </LocalizedLink>
        </NavItem>
        <NavItem>
          <PageLink
            id="e802dc32-286c-4406-936e-40a07b794a45"
            linkProps={{ className: "nav-link" }}
          />
        </NavItem>
      </Nav>
      {props.isLogged ? (
        <>
          <Nav className="ml-auto border-right" navbar>
            <NavItem>
              <LocalizedLink className="nav-link" to="/panel#current">
                {t("order.orders")}
              </LocalizedLink>
            </NavItem>
            <NavItem>
              <LocalizedLink className="nav-link" to="/panel#current">
                {t("order.realised")}
              </LocalizedLink>
            </NavItem>
            <NavItem className="d-none d-xl-inline">
              <LocalizedLink className="nav-link" to="/panel#customPricings">
                {t("customPricing.customPricings")}
              </LocalizedLink>
            </NavItem>
            <NavItem className="d-none d-xl-inline">
              <LocalizedLink className="nav-link" to="/panel#payments">
                {t("order.payment.payments")}
              </LocalizedLink>
            </NavItem>
            <NavItem>
              <LocalizedLink className="nav-link" to="/panel#invoices">
                {t("invoices.invoices")}
              </LocalizedLink>
            </NavItem>
            <NavItem>
              <LocalizedLink className="nav-link" to="/panel#shippings">
                {t("cart.deliveryAndOrder")}
              </LocalizedLink>
            </NavItem>
            <NavItem className="d-lg-none">
              <LocalizedLink className="nav-link" to="/panel">
                {t("users.panel.panel")}
              </LocalizedLink>
            </NavItem>
            <NavItem className="d-lg-none" onClick={props.onClickLogout}>
              <span className="nav-link">
                {t("auth.signOut.do")}
              </span>
            </NavItem>
          </Nav>
          <Nav className="border-right d-none d-lg-inline" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <i className="fas fa-user"></i>{" "}
                <span className="d-md-none d-xl-inline">
                  {props.user.givenName}
                </span>
              </DropdownToggle>
              <DropdownMenu right>
                <LocalizedLink to="/panel">
                  <DropdownItem>
                    <i className="fas fa-wrench"></i>{" "}
                    {t("users.panel.panel")}
                  </DropdownItem>
                </LocalizedLink>
                <DropdownItem divider />
                <DropdownItem onClick={props.onClickLogout}>
                  <i className="fas fa-lock"></i> {t("auth.signOut.do")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </>
      ) : (
        <Nav className="ml-auto" navbar>
          <NavItem>
            <LocalizedLink to="/login">
              <Button color="primary" outline className="mt-1">
                {t("auth.login.do")}
              </Button>
            </LocalizedLink>
          </NavItem>
          <NavItem>
            <LocalizedLink to="/register">
              <Button color="dark" outline className="mt-1">
                {t("auth.register.registerTitle")}
              </Button>
            </LocalizedLink>
          </NavItem>
        </Nav>
      )}
      <Nav>
        <Cart toggleCollapse={props.toggleCollapse} />
      </Nav>
      <Nav className="ml-md-5" navbar>
        <NavItem style={{ cursor: 'pointer' }} onClick={() => props.setLanguageSelectorOpen(true)}>
          <CultureFlag code={props.currentLocale?.code} className="mr-3 mr-md-0" />
          <span className="d-inline d-md-none">{t("locale.locale")}: {props.currentLocale?.native}</span>
        </NavItem>
      </Nav>
    </>
  );
}

function MainNavbar() {
  const { t, i18n } = useTranslation();
  const [collapseOpen, toggleCollapse] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const [error, setError] = useState(false);
  const [refreshTokenTimer, setRefreshTokenTimer] = useState(null);
  const [isLanguageSelectorOpen, setLanguageSelectorOpen] = useState(false);
  const toggleLanguageSelector = () =>
    setLanguageSelectorOpen(!isLanguageSelectorOpen);
  const currentLocale = config.cultures.find(
    (c) => c.code === i18n.resolvedLanguage,
  );

  const user = useUser();
  const dispatch = useDispatch();
  const isLogged = user ? true : false;

  const onClickLogout = () => {
    dispatch(authActions.logout());
  };

  const refreshToken = () => {
    console.log("Refreshing JWT token");
    dispatch(authActions.refresh());
    setRefreshTokenTimer(setTimeout(refreshToken, 10 * 60 * 1000));
  };

  const initiateRefreshTokenTimer = () => {
    refreshToken();
  };

  useEffect(() => {
    console.log(window.innerWidth);
    setMobile(window.innerWidth < 768);
  }, []);

  useEffect(() => {
    if (user && !refreshTokenTimer) {
      initiateRefreshTokenTimer();
    }
  }, [user]);

  return (
    <>
      <div className="sticky-top">
        <Container fluid>
          <Navbar color="white" light expand="md">
            <NavbarBrand tag="div">
              <LocalizedLink to="/">
                <img
                  src={`/images/logo-colour.png`}
                  alt={t("common.logo")}
                />
              </LocalizedLink>
            </NavbarBrand>
            {isMobile ?
              <>
                <Cart toggleCollapse={toggleCollapse} withoutTitle={true} />
                <NavbarToggler onClick={() => toggleCollapse(true)} />
                <Collapse isOpen={collapseOpen} onClick={() => toggleCollapse(false)} navbar>
                  <NavbarContent error={error} setError={setError} currentLocale={currentLocale} isLogged={isLogged} user={user} collapseOpen={collapseOpen} toggleCollapse={toggleCollapse} onClickLogout={onClickLogout} setLanguageSelectorOpen={setLanguageSelectorOpen} isLanguageSelectorOpen={isLanguageSelectorOpen} />
                </Collapse>
              </> :
              <NavbarContent error={error} setError={setError} currentLocale={currentLocale} isLogged={isLogged} user={user} collapseOpen={collapseOpen} toggleCollapse={toggleCollapse} onClickLogout={onClickLogout} setLanguageSelectorOpen={setLanguageSelectorOpen} isLanguageSelectorOpen={isLanguageSelectorOpen} />
            }

          </Navbar>
        </Container>
      </div>
      <div className="text-center">
        <img src="/images/efs.jpg" alt="Europejski Fundusz Społeczny" className="w-50" />
      </div>
      <LanguageSelector
        isOpen={isLanguageSelectorOpen}
        toggle={toggleLanguageSelector}
      />
    </>
  );
}

MainNavbar.defaultProps = {
  type: "transparent",
};

MainNavbar.propTypes = {
  type: PropTypes.oneOf(["dark", "transparent", "primary", "white"]),
};

export default MainNavbar;
